<template>
  <div>
    <ContentPanel
      :title="
        rescheduleMode ? 'Zmiana terminu wizyty' : 'Dodawanie nowej wizyty'
      "
      icon="calendar"
      :paddingless="true"
      :marginless="true"
    >
      <template slot="addon">
        <b-button
          @click="closeAddAppointment()"
          icon-left="close"
          size="is-small"
          type="is-danger"
        >Zamknij</b-button>
      </template>
      <!--------------------------------------------------------->
      <!--******************* RESCHEDULE MODE ******************* -->
      <!--------------------------------------------------------->

      <!--
      Used in:
      * Reschedule from ScheduleModalAppointmentDetails component
      * AnteroomSchedule comp - reschedule for POZ / Specialist / Cytology / Nurse / Midwife
      * MobiVehicles vehicle reservation change ?

      Purpose:
      * Change visit time / service props
      -->
      <div
        class="modal-card"
        v-if="rescheduleMode"
        style="overflow: visible; width: 100% !important;max-height:88vh;min-height:80vh;"
      >
        <section class="modal-card-body" v-if="appointment" style="min-height: 18rem">
          <div class="columns">
            <div class="column">
              <table class="table is-bordered w-100 mb-3" v-if="block.worktimeArea">
                <tr>
                  <th>Placówka</th>
                  <td>{{ mGetFacilityFromRoom(block.worktimeArea.officeRoomId) }}</td>
                </tr>
                <tr>
                  <th>Gabinet</th>
                  <td>{{ mGetOffice(block.worktimeArea.officeId) }}</td>
                </tr>
                <tr>
                  <th>Pomieszczenie</th>
                  <td>{{ mGetRoom(block.worktimeArea.officeRoomId) }}</td>
                </tr>
                <tr>
                  <th>Poradnia</th>
                  <td>{{ mGetClinic(block.worktimeArea.clinic) }}</td>
                </tr>
                <tr v-if="focusAppointment">
                  <th>Typ wizyty</th>
                  <td>
                    <b-icon size="is-small" :icon="mGetAppIcon(focusAppointment.type)"></b-icon>
                    {{ mGetAppType(focusAppointment.type) }}
                    <div v-if="focusAppointment.subtype > -1">
                      <b-icon
                        :icon="mGetAppIcon(focusAppointment.type)"
                        size="is-small"
                        class="mb-1 mr-2"
                      ></b-icon>
                      {{ mGetAppSubtype(focusAppointment.type, focusAppointment.subtype) }}
                    </div>
                  </td>
                </tr>
                <tr v-if="focusAppointment">
                  <th>Strefa</th>
                  <td>
                    <b-icon size="is-small" :icon="mGetAreaIcon(focusAppointment.area)"></b-icon>
                    {{ mGetAreaType(block.worktimeArea.area) }}
                  </td>
                </tr>
                <tr>
                  <th>Specjalizacja</th>
                  <td>{{ mGetSpecialization(block.worktimeArea.spec) }}</td>
                </tr>
              </table>
              <!-- <pre>appStartDate</pre>
              <pre>{{appStartDate}}</pre>
              <pre>rescheduleMode</pre>
              <pre>{{rescheduleMode}}</pre>
              <pre>focusAppointment</pre>
              <pre>{{focusAppointment}}</pre>
              <pre>appointmentObject</pre>
              <pre>{{appointmentObject}}</pre>-->
            </div>
            <div class="column">
              <p class="has-text-danger pb-3" v-if="timelessOnlyMode">
                Wizyta zostanie zapisana jako
                <b>"Bez godziny"</b>
              </p>
              <p v-if="block.worktimeArea === null" class="subtitle has-text-danger">
                <b-icon type="is-danger" class="mr-2" icon="alert-circle"></b-icon>Wizyta umawiana poza strefą terminarza!
              </p>

              <!-- VISIT LENGTH ALERT -->
              <!--------------------------------------------------------->
              <div
                v-if="
                  appointmentObject &&
                  !appointment.isTimeless &&
                  (
                    (visitLength < minDurationReschedule) ||
                    (visitLength < minDurationRescheduleInfo)
                  )
                "
                class="pb-3"
              >
                <b-notification type="is-danger" v-if="visitLength < minDurationReschedule">
                  <span>Uwaga! Wizyta nie może trwać krócej niż {{minDurationReschedule}} minut.</span>
                </b-notification>
                <b-notification type="is-warning" v-if="visitLength < minDurationRescheduleInfo">
                  <span>Uwaga! Czas wizyty jest niższy niż {{minDurationRescheduleInfo}} minut.</span>
                </b-notification>
              </div>

              <b-field grouped>
                <b-field class="mr-0" label="Data" label-position="on-border">
                  <b-button
                    type="is-static"
                    style="text-decoration: none !important"
                  >{{ mDate(date) }}</b-button>
                </b-field>
                <b-field
                  :label="
                    appointment.isTimeless || timelessOnlyMode
                      ? 'Godzina ORIENTACYJNA'
                      : 'Godzina wizyty'
                  "
                  label-position="on-border"
                  class="ml-0 mr-0"
                >
                  <p class="control">
                    <vue-timepicker
                      v-model="timeObject"
                      :minute-interval="5"
                      hide-disabled-items
                      hide-clear-button
                      :hour-range="enabledHours"
                      :minute-range="enabledMinutes"
                      :key="trigger"
                    ></vue-timepicker>
                  </p>
                </b-field>
                <!-- VISIT LENGTH INPUT -->
                <!--------------------------------------------------------->
                <b-field
                  grouped
                  label="Czas wizyty"
                  label-position="on-border"
                  v-if="!timelessOnlyMode"
                  class="ml-0 mr-0"
                >
                  <b-field class="ml-0 mr-0">
                    <b-numberinput
                      :disabled="appointment.isTimeless"
                      :controls="!appointment.isTimeless"
                      :editable="false"
                      v-model="visitLength"
                      min="5"
                      :max="maxDuration"
                      step="5"
                      type="is-xxx"
                      controls-position="compact"
                      controls-alignment="right"
                    ></b-numberinput>
                  </b-field>
                  <b-button
                    class="ml-0"
                    type="is-text"
                    @click="showMaxTimeAlert"
                    style="text-decoration: none !important"
                  >max {{ maxDuration }} minut</b-button>
                </b-field>
              </b-field>

              <!-- SERVICE TYPE -->
              <b-field expanded v-if="focusAppointment" label="Miejsce" label-position="on-border">
                <b-select expanded v-model="service">
                  <option
                    v-for="wayItem in getAppWays(focusAppointment.type)"
                    :key="wayItem.id"
                    :value="wayItem.id"
                  >
                    <b-icon :icon="wayItem.icon" class="mr-2"></b-icon>
                    {{ wayItem.name }}
                  </option>
                  <!-- <option :value="0">Przychodnia</option>
            <option :value="1">Teleporada</option>
                  <option :value="2">Dom pacjenta</option>-->
                  <!-- <option :value="0">Wizyta w przychodni</option>
            <option :value="1">Teleporada</option>
                  <option :value="2">Wizyta domowa</option>-->
                </b-select>
                <!-- <p class="control">
            <b-checkbox-button
              :native-value="true"
              type="is-danger"
              v-model="appointment.isUrgent"
              >Pilne</b-checkbox-button
            >
                </p>-->
              </b-field>
            </div>
          </div>

          <!-- MAIN TYPE -->
          <!-- <b-field expanded label="Typ wizyty" label-position="on-border">
          <b-select
            expanded
            placeholder="Wybierz"
            @input="onTypeChanged"
            v-model="appointmentObject"
          >
            <option
              v-for="item in filteredAppTypes"
              :key="item.id"
              :value="item"
            >
              {{ item.name }}
            </option>
          </b-select>
        </b-field>
        <p v-if="filteredAppTypes.length == 0" class="pb-3 has-text-danger">
          Brak typów wizyt dla wybranego pracownika
          </p>-->

          <!-- SUBTYPE -->
          <!-- <b-field v-if="subcategories" label="Podtyp" label-position="on-border">
          <b-select
            placeholder="Wybierz"
            v-model="appointment.subtype"
            @input="onSubTypeChanged"
          >
            <option
              v-for="item in subcategories"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-select>
          </b-field>-->

          <!-- TIME -->
        </section>
        <footer class="modal-card-foot">
          <b-button
            :loading="requestInProgress"
            :disabled="
              appointmentObject === null ||
              (
                appointmentObject != null &&
                !appointment.isTimeless &&
                visitLength < minDurationReschedule
              )
            "
            type="is-primary"
            @click="reschedule"
            icon-left="check"
          >Potwierdź zmianę terminu</b-button>
        </footer>
      </div>
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->

      <!--------------------------------------------------------->
      <!--******************* RIS WORKSHOP  *******************-->
      <!--------------------------------------------------------->

      <!--
      Used in:
      * TicketMaster component for creating visit from ticket (TicketMaster in RISWorkshopAddVisitNewDeviceVer2 component)
      * For ticket type 7230
      * RIS only ?

      Purpose:
      * Used to pick different time for appointment start - than the one suggested in TicketMaster comp
      * Doesn't execute schedule methods, only emits event with appointment start date
      -->
      <div
        class="modal-card w-100"
        v-else-if="timeOnlyMode && !timelessOnlyMode"
        style="overflow: visible; width: 100% !important"
      >
        <section
          class="modal-card-body w-100"
          v-if="appointment"
          style="min-height: 16rem; overflow: visible"
        >
          <div class="columns is-centered">
            <div class="column is-6">
              <table class="table is-narrow is-bordered w-100">
                <tr>
                  <th>Pacjent</th>
                  <th>
                    <p
                      class="title is-5 has-text-primary"
                      v-if="patient"
                    >
                      <span class="has-text-primary">
                        {{ patient.lastName }} {{ patient.firstName }}
                      </span>
                      <span class="ml-3" v-if="patient.pesel">
                        PESEL: {{ patient.pesel }}
                      </span>
                      <span class="ml-3" v-else>
                        <span v-if="patient.identityDocument === 0"
                          >Dowód osobisty,
                        </span>
                        <span v-if="patient.identityDocument === 1">Paszport, </span>
                        <span v-if="patient.identityDocumentNumber">
                          Numer dokumentu: {{ patient.identityDocumentNumber }}
                        </span>
                      </span>
                    </p>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Wiek pacjenta</th>
                  <th>
                    {{ patient.age }}
                    <b-tag
                      :type="ageRestriction ? 'is-danger' : 'is-secondary'"
                      class="is-pulled-right has-text-semibold"
                      v-if="appointmentObject && appointmentObject.hasAgeRestriction"
                    >
                      Wybrany typ wizyty wymaga wieku od
                      <b>{{ ageFrom }}</b> do
                      <b>{{ageTo}}</b> lat
                    </b-tag>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Adres</th>
                  <th>
                    {{ patient.addressStreet ? patient.addressStreet : "-" }}
                    {{ patient.addressHouseNumber ? patient.addressHouseNumber : "-" }}
                    {{ patient.addressApartmentNumber ? `/ ${patient.addressApartmentNumber},` : "," }}
                    {{ patient.addressZipCode ? patient.addressZipCode : "-" }}
                    {{ patient.addressCity ? patient.addressCity : "-" }}
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Numer telefonu</th>
                  <th>
                    <b-tooltip
                      position="is-right"
                      label="Upewnij się że wpisany numer jest poprawny"
                      type="is-primary"
                      v-if="phoneNumberWarning"
                    >
                      <b-icon icon="information-outline" style="color: orange"></b-icon>
                    </b-tooltip>
                    <b-tooltip
                      position="is-right"
                      label="Upewnij się że numer kierunkowy do Polski nie został zdublowany"
                      type="is-primary"
                      v-if="phoneNumberPrefixWarning"
                    >
                      <b-icon icon="information-outline" type="is-warning"></b-icon>
                    </b-tooltip>
                    <span
                      v-if="patient.teleAddressTelephoneNumber"
                    >{{patient.teleAddressTelephoneNumber}}</span>
                    <span v-else class="has-text-danger has-text-weight-bold">Brak numeru telefonu</span>
                  </th>
                </tr>
                <tr v-if="patient && patient.teleAddressTelephoneNumber">
                  <th>Właściciel numeru</th>
                  <th>
                    {{patient.phoneNumberOwner ? patient.phoneNumberOwner : "brak informacji"}}
                    <span
                      v-if="patient.phoneNumberOtherOwner"
                    >{{patient.phoneNumberOtherOwner}}</span>
                  </th>
                </tr>
                <tr>
                  <td>Data</td>
                  <td>{{ mDate(date) }}</td>
                </tr>
                <tr>
                  <td>Godzina wizyty</td>
                  <td>
                    <b-field>
                      <p class="control">
                        <vue-timepicker
                          :disabled="appointment.isTimeless || timelessOnlyMode"
                          v-model="timeObject"
                          :minute-interval="5"
                          hide-disabled-items
                          hide-clear-button
                          :hour-range="enabledHours"
                          :minute-range="enabledMinutes"
                          :key="trigger"
                        ></vue-timepicker>
                      </p>
                    </b-field>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot p-2">
          <b-button
            :loading="requestInProgress"
            :disabled="appointmentObject === null"
            type="is-secondary"
            @click="emitTime"
            icon-left="check"
          >Wybierz godzinę {{ timeObject.HH }}:{{ timeObject.mm }}</b-button>
          <b-button type="is-danger" @click="closeAddAppointment()" icon-left="close">Anuluj</b-button>
        </footer>
      </div>

      <!--------------------------------------------------------->
      <!--------------------------------------------------------->

      <!--------------------------------------------------------->
      <!--******************* VEHICLE RESERVATION  *******************-->
      <!--------------------------------------------------------->

      <!--
      Used in:
      * MobiReservation comp

      Purpose:
      * Create reservation for mobi vehicles:
        * type, driver, facility, destination place, departure and arrival hours and additional details
      -->
      <div v-else-if="block.worktimeArea && block.worktimeArea.vehicle > 0">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <div class="modal-card" style="overflow: visible">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Rezerwacja pojazdu</p>
              <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body" v-if="appointment">
              <b-field expanded>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    expanded
                    label="Typ rezerwacji"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-select v-model="appointment.type" expanded>
                      <option :value="-1">Brak</option>
                      <option
                        v-for="item in vehicleApps"
                        :key="item.id"
                        :value="item.id"
                      >{{ item.name }}</option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <b-field expanded>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    expanded
                    label="Deklarowany kierowca"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-autocomplete
                      :data="elmpoyeeFiltered"
                      v-model="employeeFilter"
                      placeholder="Wybierz"
                      open-on-focus
                      :custom-formatter="
                        (object) => {
                          return `${object.lastName} ${object.firstName}`;
                        }
                      "
                      keep-first
                      expanded
                      @select="
                        (option) =>
                          (vehicleReservation.driver = option ? option.id : -1)
                      "
                      :loading="requestInProgress"
                      clearable
                    >
                      <template slot-scope="props">
                        <p
                          :class="{
                            'has-text-success has-text-weight-bold':
                              vehicleReservation.driver === props.option.id,
                          }"
                        >
                          {{ props.option.lastName }}
                          {{ props.option.firstName }}
                        </p>
                      </template>
                    </b-autocomplete>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <div>
                <p class="mb-2">
                  Placówka:
                  <b-switch size="is-small" v-model="vehicleReservation.internalClinic">
                    {{
                    vehicleReservation.internalClinic == true
                    ? "Placówka CMD"
                    : "Placówka inna"
                    }}
                  </b-switch>
                </p>
              </div>

              <b-field expanded v-if="vehicleReservation.internalClinic">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    label="Miejsce"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-autocomplete
                      :data="facilitiesFiltered"
                      v-model="facilityFilter"
                      clearable
                      open-on-focus
                      field="name"
                      placeholder="Wybierz"
                      keep-first
                      @select="
                        (option) =>
                          (vehicleReservation.destinationClinic = option
                            ? option.internalId
                            : -1)
                      "
                    >
                      <template slot-scope="props">
                        <p
                          :class="{
                            'has-text-success has-text-weight-bold':
                              vehicleReservation.destinationClinic ===
                              props.option.internalId,
                          }"
                        >{{ props.option.name }}</p>
                        <p>
                          {{ props.option.address.street }}
                          {{ props.option.address.buildingNumber }}
                          {{ props.option.address.apartmentNumber ? "/ " : "" }}
                          {{ props.option.address.apartmentNumber }}
                        </p>
                        <p>
                          {{ props.option.address.city }}
                          {{ props.option.address.zipCode ? ", " : "" }}
                          {{ props.option.address.zipCode }}
                        </p>
                      </template>
                    </b-autocomplete>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field expanded v-else>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    label="Miejsce"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="vehicleReservation.destinationText"
                      placeholder="Nazwa placówki"
                    />
                  </b-field>
                </ValidationProvider>
              </b-field>

              <div>
                <p class="mb-2">Data i godzina wyjazdu:</p>
              </div>

              <b-field grouped label-position="on-border">
                <b-field expanded>
                  <b-datepicker
                    disabled
                    v-model="vehicleReservation.departure"
                    :month-names="monthNames"
                    :day-names="dayNamesShort"
                    trap-focus
                    icon="calendar"
                  ></b-datepicker>
                </b-field>
                <b-field expanded>
                  <ValidationProvider rules="required" v-slot="{ errors, valid }">
                    <b-field
                      :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                      :message="t(errors[0])"
                    >
                      <vue-timepicker
                        :disabled="appointment.isTimeless"
                        v-model="vehicleDepartureTime"
                        :minute-interval="5"
                        hide-disabled-items
                        hide-clear-button
                        :hour-range="enabledHours"
                        :minute-range="enabledMinutes"
                        :key="trigger"
                      ></vue-timepicker>
                    </b-field>
                  </ValidationProvider>
                </b-field>
              </b-field>
              <div>
                <p class="mb-2">Data i godzina powrotu:</p>
              </div>

              <b-field grouped label-position="on-border">
                <b-field expanded>
                  <b-datepicker
                    disabled
                    v-model="vehicleReservation.arrival"
                    :month-names="monthNames"
                    :day-names="dayNamesShort"
                    trap-focus
                    icon="calendar"
                  ></b-datepicker>
                </b-field>
                <b-field expanded>
                  <ValidationProvider rules="required" v-slot="{ errors, valid }">
                    <b-field
                      :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                      :message="t(errors[0])"
                    >
                      <vue-timepicker
                        :disabled="appointment.isTimeless"
                        v-model="vehicleArrivalTime"
                        :minute-interval="5"
                        hide-disabled-items
                        hide-clear-button
                        :hour-range="enabledHours"
                        :minute-range="enabledMinutes"
                        :key="trigger"
                      ></vue-timepicker>
                    </b-field>
                  </ValidationProvider>
                </b-field>
              </b-field>

              <b-field label="Liczba osób" label-position="on-border">
                <b-input type="number" v-model="vehicleReservation.peopleNumber" />
              </b-field>

              <!-- <b-field expanded label="Domyślny kierowca" label-position="on-border">
              <b-select v-model="vehicleReservation.driver" expanded>
                <option :value="-1">Brak</option>
                <option
                  v-for="item in drivers"
                  :key="item.id"
                  :value="item.id"
                >{{ item.lastName }} {{ item.firstName }}</option>
              </b-select>
              </b-field>-->

              <b-field>
                <b-checkbox
                  v-model="vehicleReservation.isAdditionalSpace"
                >Potrzebna dodatkowa przestrzeń ładunkowa</b-checkbox>
              </b-field>

              <b-field
                v-if="vehicleReservation.isAdditionalSpace"
                label="Co chcesz przewieźć?"
                label-position="on-border"
              >
                <b-input v-model="vehicleReservation.additionalSpace" />
              </b-field>

              <b-field label="Uwagi" label-position="on-border">
                <b-input type="textarea" v-model="vehicleReservation.notes" />
              </b-field>
            </section>
            <footer class="modal-card-foot p-2">
              <b-button
                :loading="requestInProgress"
                type="is-primary"
                @click="reserveVehicle"
                icon-left="check"
                :disabled="
                  invalid ||
                  vehicleDepartureTime == null ||
                  vehicleArrivalTime == null
                "
              >Rezerwuj</b-button>
            </footer>
          </div>
        </ValidationObserver>
      </div>
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->

      <!--------------------------------------------------------->
      <!--******************* COLLECTION POINT RESERVATION  *******************-->
      <!--------------------------------------------------------->

      <!--
      Purpose:
      * Create visit for collection point based on ticket
      -->

      <div class="modal-card" style="width: 100% !important" v-else-if="isCollectionPointOffice">
        <section class="modal-card-body w-100 pt-3 pl-3 pr-3 pb-5" v-if="appointment">
          <div class="columns">
            <div class="column is-6">
              <!-- WORKTIME AREA DATA - GENERAL -->
              <!--------------------------------------------------------->
              <table class="table is-narrow is-bordered w-100 mb-3" v-if="block.worktimeArea">
                <tr>
                  <th style="width: 10rem">Data</th>
                  <th>{{ mDate(date) }}</th>
                </tr>
                <tr v-if="patient">
                  <th>Pacjent</th>
                  <th>
                      <span class="has-text-primary">
                        {{ patient.lastName }} {{ patient.firstName }}
                      </span>
                      <span class="ml-3" v-if="patient.pesel">
                        PESEL: {{ patient.pesel }}
                      </span>
                      <span class="ml-3" v-else>
                        <span v-if="patient.identityDocument === 0"
                          >Dowód osobisty,
                        </span>
                        <span v-if="patient.identityDocument === 1">Paszport, </span>
                        <span v-if="patient.identityDocumentNumber">
                          Numer dokumentu: {{ patient.identityDocumentNumber }}
                        </span>
                      </span>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Wiek pacjenta</th>
                  <th>
                    {{ patient.age }}
                    <b-tag
                      :type="ageRestriction ? 'is-danger' : 'is-secondary'"
                      class="is-pulled-right has-text-semibold"
                      v-if="appointmentObject && appointmentObject.hasAgeRestriction"
                    >
                      Wybrany typ wizyty wymaga wieku od
                      <b>{{ ageFrom }}</b> do
                      <b>{{ageTo}}</b> lat
                    </b-tag>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Adres</th>
                  <th>
                    {{ patient.addressStreet ? patient.addressStreet : "-" }}
                    {{ patient.addressHouseNumber ? patient.addressHouseNumber : "-" }}
                    {{ patient.addressApartmentNumber ? `/ ${patient.addressApartmentNumber},` : "," }}
                    {{ patient.addressZipCode ? patient.addressZipCode : "-" }}
                    {{ patient.addressCity ? patient.addressCity : "-" }}
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Numer telefonu</th>
                  <th>
                    <b-tooltip
                      position="is-right"
                      label="Upewnij się że wpisany numer jest poprawny"
                      type="is-primary"
                      v-if="phoneNumberWarning"
                    >
                      <b-icon icon="information-outline" style="color: orange"></b-icon>
                    </b-tooltip>
                    <b-tooltip
                      position="is-right"
                      label="Upewnij się że numer kierunkowy do Polski nie został zdublowany"
                      type="is-primary"
                      v-if="phoneNumberPrefixWarning"
                    >
                      <b-icon icon="information-outline" type="is-warning"></b-icon>
                    </b-tooltip>
                    <span
                      v-if="patient.teleAddressTelephoneNumber"
                    >{{patient.teleAddressTelephoneNumber}}</span>
                    <span v-else class="has-text-danger has-text-weight-bold">Brak numeru telefonu</span>
                  </th>
                </tr>
                <tr v-if="patient && patient.teleAddressTelephoneNumber">
                  <th>Właściciel numeru</th>
                  <th>
                    {{patient.phoneNumberOwner ? patient.phoneNumberOwner : "brak informacji"}}
                    <span
                      v-if="patient.phoneNumberOtherOwner"
                    >{{patient.phoneNumberOtherOwner}}</span>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>EWUŚ</th>
                  <th>
                    <div class="has-text-centered" v-if="!ewus && ewusGuid">
                      <b-progress
                        type="is-primary"
                        style="margin: 0.25rem 5%;"
                        size="is-small"
                        :rounded="false"
                      ></b-progress>
                    </div>
                    <div v-else-if="ewus">
                      <b-tooltip
                        :label="
                            'Data aktualizacji: ' +
                            mDate(ewus.dateExp)
                          "
                        type="is-dark"
                        position="is-right"
                      >
                        <b-tag
                          :type="getEwusType(ewus.ewusStatus)"
                          class="vam"
                        >{{ getEwusStatus(ewus.ewusStatus) }}</b-tag>

                        <b-tag v-if="ewus.ewusDN === 1" type="is-dark" class="vam">DN</b-tag>
                      </b-tooltip>
                      <b-tooltip
                        label="
                            Pobierz ponownie informacje z systemu eWUŚ
                          "
                        type="is-dark"
                        position="is-left"
                        class="is-pulled-right"
                      >
                        <b-tag style="cursor: pointer" class="ml-2 vam" @click.native="resetEwus">
                          <b-icon class="vam" icon="refresh" type="is-dark"></b-icon>
                          <span class="vam">Odśwież</span>
                        </b-tag>
                      </b-tooltip>
                    </div>
                    <div v-else>Brak danych
                      <b-tooltip
                        label="
                            Pobierz ponownie informacje z systemu eWUŚ
                          "
                        type="is-dark"
                        position="is-left"
                        class="is-pulled-right"
                      >
                        <b-tag style="cursor: pointer" class="ml-2 vam" @click.native="resetEwus">
                          <b-icon class="vam" icon="refresh" type="is-dark"></b-icon>
                          <span class="vam">Odśwież</span>
                        </b-tag>
                      </b-tooltip>
                    </div>
                  </th>
                </tr>
                <tr v-if="patient && showIdentificationInfo && lastIdentificationInfo">
                  <th>Ubezpieczenie</th>
                  <th>
                    <span class="has-text-primary">
                    {{ mGetIdentification(lastIdentificationInfo.identification) }},
                    </span>
                    <span
                      v-if="
                        lastIdentificationInfo.entitlementDocument == -1
                      "
                      class="has-text-success"
                    >
                      Potwierdzone przez system eWUŚ
                    </span>
                    <span
                      v-else-if="
                        lastIdentificationInfo.entitlementDocument != 99
                      "
                      class="has-text-success"
                    >
                      {{ mGetEntitlementDocument(lastIdentificationInfo.entitlementDocument) }}
                    </span>
                    <span v-else class="has-text-danger">
                      {{ mGetEntitlementDocument(lastIdentificationInfo.entitlementDocument) }}
                    </span>
                  </th>
                </tr>
                <tr v-if="block.worktimeArea.device">
                  <th>Urządzenie</th>
                  <td v-if="areaDevice">
                    <span class="has-text-weight-semibold">
                      {{
                      areaDevice.name
                      }}
                    </span>
                    ({{ areaDevice.shortName }}),
                    {{ getFacility(areaDevice.facility) }}
                  </td>
                  <td v-else>
                    <b-skeleton expanded></b-skeleton>
                  </td>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.officeId">
                  <th>Gabinet</th>
                  <td>{{ getOffice(block.worktimeArea.officeId) }}</td>
                </tr>
                <tr v-if="block.worktimeArea.employee > -1">
                  <th>Pracownik</th>
                  <td v-if="employee">{{ employee.lastName }} {{ employee.firstName }}</td>
                  <td v-else>
                    <b-skeleton class="w-100"></b-skeleton>
                  </td>
                </tr>
                <tr>
                  <th>Strefa</th>
                  <td>{{ getArea(block.worktimeArea.area) }}</td>
                </tr>
                <tr v-if="block.worktimeArea.clinic">
                  <th>Poradnia</th>
                  <td>{{ mGetClinic(block.worktimeArea.clinic) }}</td>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.officeId">
                  <th>Placówka</th>
                  <td>{{ getClinic(block.worktimeArea.facility) }}</td>
                </tr>
                <tr v-if="nfzService && appointment && appointment.payerType == 1">
                  <th>Świadczenie</th>
                  <td>{{ nfzService.display }}</td>
                </tr>
                <tr v-if="appointmentObject.alert">
                  <th>Uwagi</th>
                  <td>
                    <b>{{ appointmentObject.alert }}</b>
                  </td>
                </tr>
              </table>

              <table
                class="table is-narrow is-bordered w-100 mb-4"
                v-if="patient && appointment && appointment.payerType == 1"
              >
                <tr>
                  <th colspan="2">Informacja NFZ</th>
                </tr>
                <tr>
                  <td>Oddział NFZ pacjenta</td>
                  <td>{{mGetBranchNfzNameParsed(patient.nfzDepartment)}}</td>
                </tr>
                <tr v-if="currentOffice">
                  <td>Oddział NFZ gabinetu</td>
                  <td>
                    <span>{{mGetBranchNfzNameParsed(currentOffice.nfzCode)}}</span>
                  </td>
                </tr>
                <tr>
                  <td>Deklaracje</td>
                  <td>
                    <p v-html="declarationsText"></p>
                  </td>
                </tr>
              </table>
              <!--------------------------------------------------------->
              <!--******************* ALERTS / NOTIFICATIONS *******************-->
              <!--------------------------------------------------------->

              <!-- EMPTY WORKTIME AREA ALERT -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-danger"
                class="mb-1"
                v-if="block.worktimeArea === null"
              >Wizyta umawiana poza strefą terminarza!</b-notification>

              <!-- INSUFFICIENT PATIENT DATA ALERT -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-orange"
                class="mb-1"
                v-if="
                  loadingPatientIdentification == false &&
                  insufficientPatientData
                "
              >
                <p>
                  <b>Niewystarczające dane pacjenta do umówienia wizyty</b>
                </p>
                <p>{{ insufficientPatientDataText }}</p>
              </b-notification>

              <!-- APPOINTMENT ALERTS -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-secondary"
                class="mb-1"
                v-if="
                  appointmentObject &&
                  appointmentObject.alerts &&
                  appointmentObject.alerts.length > 0
                "
              >
                <ul style="list-style-type: square">
                  <li v-for="(item, index) in appointmentObject.alerts" :key="index">{{ item }}</li>
                </ul>
              </b-notification>

              <b-message
                type="is-danger"
                animation="false"
                class="mb-2 has-text-weight-semibold"
                :closable="false"
                icon="calendar-alert"
                v-if="
                  (
                  collectionPointDisplay.length < 1 ||
                  collectionPointDurationSum > maxDuration
                  ) &&
                  !is40Plus
                "
                has-icon
              >
                <div
                  v-if="collectionPointDisplay.length < 1"
                >Rejestracja do punktu pobrań wymaga wskazania minimum jednego biletu w celu umówienia wizyty. Dla każdego biletu zostanie utworzona oddzielna wizyta.</div>

                <div
                  v-if="collectionPointDurationSum > maxDuration"
                >Łączny czas trwania wizyt przekracza wolny czas w strefie, zmniejsz czas trwania wizyty lub odznacz jeden z biletów.</div>
              </b-message>
              <b-message
                type="is-success"
                animation="false"
                class="mb-2 has-text-weight-semibold"
                :closable="false"
                icon="calendar-alert"
                v-else-if="
                  collectionPointDisplay.length > 1
                "
                has-icon
              >
                Zostaną utworzone wizyty o następujących płatnikach oraz datach:
                <b-field class="mt-3 ml-5">
                  <ul>
                    <li class="pb-1" v-for="ticket in collectionPointDisplay" :key="ticket.id">
                      <b-icon :icon="ticket.payerTypeIcon" size="is-small"></b-icon>
                      {{ ticket.payerTypeName }},
                      {{ mTime(ticket.appointmentStart) }} - {{ mTime(ticket.appointmentStop) }}
                    </li>
                  </ul>
                </b-field>
              </b-message>
            </div>
            <div class="column is-6">
              <!--------------------------------------------------------->
              <!--******************* VISIT TYPE *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT MAIN TYPE -->
              <!--------------------------------------------------------->
              <b-field grouped class="mt-3">
                <b-field expanded label="Typ wizyty" label-position="on-border">
                  <b-select
                    expanded
                    placeholder="Brak typów wizyt dla wybranego pracownika"
                    @input="onTypeChanged"
                    v-model="appointmentObjectTmp"
                  >
                    <option v-for="item in filteredAppTypes" :key="item.id" :value="item.id">
                      {{ item.name }}
                      <span v-if="item.apkolce">[AP-KOLCE]</span>
                    </option>
                  </b-select>
                </b-field>
                <b-checkbox-button
                  v-if="
                    (block.worktimeArea &&
                      block.worktimeArea.officeId != null) ||
                    (block.worktimeArea === null && filters.office)
                  "
                  :native-value="true"
                  v-model="showAllAppTypes"
                >
                  <div v-if="showAllAppTypes">
                    <b-icon icon="account-circle"></b-icon>
                  </div>
                  <div v-else>
                    <b-icon icon="home"></b-icon>
                  </div>
                </b-checkbox-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- VISIT SUBTYPE -->
              <!--------------------------------------------------------->
              <b-field
                v-if="subcategories && subcategories.length > 0"
                label="Podtyp"
                label-position="on-border"
              >
                <b-select
                  placeholder="Wybierz"
                  v-model="appointment.subtype"
                  @input="onSubTypeChanged"
                  expanded
                >
                  <option
                    v-for="item in subcategories"
                    :key="item.id"
                    :value="item.id"
                    v-show="!item.isDisabled"
                  >
                    {{ item.name }}
                    <span v-if="item.apkolce">[AP-KOLCE]</span>
                  </option>
                </b-select>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* VISIT TIME *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT TIME -->
              <b-field
                grouped
                label-position="on-border"
                :label="appointment.isTimeless || timelessOnlyMode ? 'Godzina ORIENTACYJNA' : 'Godzina wizyty'"
              >
                <b-field>
                  <p class="control">
                    <b-button type="is-static">Rozpoczęcie</b-button>
                  </p>
                  <p class="control">
                    <vue-timepicker
                      v-model="timeObject"
                      :minute-interval="5"
                      hide-disabled-items
                      hide-clear-button
                      :hour-range="enabledHours"
                      :minute-range="enabledMinutes"
                      :key="trigger"
                    ></vue-timepicker>
                  </p>

                  <!-- VISIT TIMELESS -->
                  <!--------------------------------------------------------->
                  <p class="control">
                    <b-checkbox-button
                      :native-value="true"
                      type="is-primary"
                      :disabled="timelessOnlyMode || timeOnlyMode"
                      v-model="appointment.isTimeless"
                    >
                      <span v-if="timelessOnlyMode" class="has-text-light">
                        <b>Bez godziny</b>
                      </span>
                      <span v-else>Bez godziny</span>
                    </b-checkbox-button>
                  </p>
                  <!--------------------------------------------------------->
                  <!--------------------------------------------------------->
                </b-field>

                <!-- VISIT IS URGENT -->
                <!--------------------------------------------------------->
                <b-button
                  type="is-danger"
                  expanded
                  v-if="appointment.isUrgent"
                  @click="appointment.isUrgent = false"
                  icon-left="clock-alert-outline"
                >Wizyta pilna</b-button>
                <b-button
                  v-else
                  expanded
                  @click="appointment.isUrgent = true"
                  icon-left="clock-alert-outline"
                >Oznacz jako pilne</b-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* VISIT LENGTH *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT LENGTH ALERT -->
              <!--------------------------------------------------------->
              <div
                v-if="
                  lengthCalculated &&
                  appointmentObject &&
                  !appointment.isTimeless &&
                  (
                    (appointmentObject.blockTime && visitLength < appointmentObject.minTime) ||
                    (visitLength < appointmentObject.time)
                  )
                "
                class="pb-3"
              >
                <b-notification
                  type="is-danger"
                  v-if="appointmentObject.blockTime && visitLength < appointmentObject.minTime"
                >
                  <span>
                    Uwaga! Wizyta nie może trwać krócej niż
                    {{ appointmentObject.minTime }} minut.
                  </span>
                </b-notification>
                <b-notification type="is-warning" v-else-if="visitLength < appointmentObject.time">
                  <span>
                    Uwaga! Czas wizyty jest niższy niż sugerowane
                    {{ appointmentObject.time }} minut.
                  </span>
                </b-notification>
              </div>

              <!-- VISIT LENGTH INPUT -->
              <!--------------------------------------------------------->
              <b-field
                grouped
                label="Czas wizyty"
                label-position="on-border"
                v-if="!timelessOnlyMode"
              >
                <b-field class="mr-0">
                  <b-numberinput
                    :disabled="appointment.isTimeless"
                    :controls="!appointment.isTimeless"
                    :editable="false"
                    v-model="visitLength"
                    min="5"
                    :max="maxDuration"
                    step="5"
                    type="is-xxx"
                    controls-position="compact"
                    controls-alignment="right"
                  ></b-numberinput>
                </b-field>
                <b-button
                  class="ml-0"
                  type="is-text"
                  @click="showMaxTimeAlert"
                  style="text-decoration: none !important"
                  v-if="appointmentObject"
                >
                  od {{ appointmentObject.minTime }} do
                  {{ maxDuration }} minut
                </b-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* APPOINTMENT - NOT RIS - PAYER TYPE *******************-->
              <!--------------------------------------------------------->

              <!-- <b-field
                v-if="appointment"
                grouped
                label="Płatnik wizyty"
                expanded
                label-position="on-border"
                class="card white-label p-3"
              >
                <b-radio-button
                  type="is-primary"
                  v-for="payerItem in getAppPayers(appointment.type)"
                  :key="payerItem.id"
                  :native-value="payerItem.id"
                  v-model="appointment.payerType"
                  @input="checkCollectionPointTicketPayer()"
                >
                  <b-icon class="mr-2" :icon="payerItem.icon"></b-icon>
                  {{ payerItem.name }}
                </b-radio-button>
                <b-checkbox
                  class="mr-2"
                  :native-value="true"
                  type="is-secondary"
                  v-if="appointment.isPrivate"
                  v-model="appointment.isPaid"
                >Opłacona</b-checkbox>
                <b-field
                  v-if="appointment.isPrivate && appointment.isPaid"
                  label="Identyfikator płatności"
                  expanded
                  label-position="on-border"
                >
                  <b-input
                    expanded
                    v-model="appointment.paymentId"
                    maxlength="128"
                    :has-counter="false"
                  ></b-input>
                </b-field>
              </b-field>-->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <ScheduleModalAddReferralCollectionPoint
                v-if="(!reservationMode || (reservationMode && !ticketToAdd)) && is40Plus"
                :patient="patient"
                :ticketEdit="null"
                :clinicType="block.worktimeArea.clinic"
                :officeId="block.worktimeArea.officeId"
                :appointmentType="appointmentObject"
                :appointmentPayer="appointment.payerType"
                @created="referralGeneratedTicket"
                @awaiting-create="referralPendingTickets"
              />

              <b-field
                label="Wybrany bilet pacjenta"
                expanded
                label-position="on-border"
                v-if="reservationMode && ticketsCollectionPointSelected.length > 0 && !is40Plus"
              >
                <b-table
                  narrowed
                  hoverable
                  bordered
                  :data="ticketsCollectionPointSelected"
                  pagination-simple
                  pagination-size="is-small"
                  class="pt-3"
                  style="font-size: 0.75rem; width: 99%;"
                >
                  <b-table-column
                    centered
                    v-slot="props"
                    label="Utworzony przez"
                    width="13%"
                  >{{ getWorker(props.row.createdBy) }}</b-table-column>
                  <b-table-column
                    centered
                    v-slot="props"
                    label="Data utworzenia"
                    width="13%"
                  >{{ mDate(props.row.created) }}</b-table-column>
                  <b-table-column
                    centered
                    v-slot="props"
                    label="Płatnik"
                    width="13%"
                  >{{ getPayerTypeName(props.row.payerType) }}</b-table-column>
                  <b-table-column v-slot="props" label="Procedury" width="61%">
                    <ul>
                      <li
                        v-for="proc in props.row.procedures"
                        :key="proc.index"
                      >{{ proc.icd9 }} - {{ proc.description }}</li>
                    </ul>
                  </b-table-column>
                  <template slot="empty">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad-outline" size="is-large"></b-icon>
                      </p>
                      <p>Brak biletu do punktu pobrań.</p>
                    </div>
                  </template>
                </b-table>
              </b-field>

              <b-message
                type="is-success"
                animation="false"
                class="mb-2"
                :closable="false"
                icon="calendar-alert"
                v-if="reservationMode && is40Plus && ticketToAdd && ticketToAdd.referralResultsObject"
                has-icon
              >
                <span
                  class="has-text-weight-semibold"
                >Zostanie utworzona wizyta o następujących danych skierowania:</span>
                <b-button
                  class="mt-3"
                  size="is-small"
                  icon-left="magnify"
                  inverted
                  rounded
                  @click="referralView = ticketToAdd.referralResultsObject.fileHtml; referralOpen = true;"
                >Podgląd e-skierowania</b-button>
                <div class="mt-3 ml-3">
                  <p class="pb-1">
                    <span class="has-text-weight-semibold">Data wystawienia:</span>
                    {{mDate(ticketToAdd.referralResultsObject.issueDate)}}
                  </p>
                  <p class="pb-1">
                    <span class="has-text-weight-semibold">Rozpoznanie:</span>
                    {{ticketToAdd.referralResultsObject.icd10.description}} ({{ticketToAdd.referralResultsObject.icd10.code}})
                  </p>
                  <p class="pb-1">
                    <span class="has-text-weight-semibold">Badania:</span>
                  </p>
                  <ul class="ml-3">
                    <li
                      v-for="proc in ticketToAdd.procedures"
                      :key="proc.index"
                    >{{ proc.icd9 }} - {{ proc.description }}</li>
                  </ul>
                </div>
              </b-message>

              <div
                v-if="(!reservationMode || (reservationMode && ticketsCollectionPointSelected.length < 1)) && !is40Plus"
              >
                <b-field label="Rodzaj biletów" expanded label-position="on-border">
                  <p class="control pt-2">
                    <b-radio-button
                      v-model="ticketsCollectionPointType"
                      :native-value="1"
                      type="is-primary"
                    >
                      <b-icon icon="account"></b-icon>Bilety
                    </b-radio-button>
                  </p>
                  <p class="control pt-2">
                    <b-radio-button
                      v-model="ticketsCollectionPointType"
                      :native-value="2"
                      type="is-primary"
                    >
                      <b-icon icon="ticket"></b-icon>Zlecenia stałe
                    </b-radio-button>
                  </p>
                </b-field>

                <div v-if="ticketsCollectionPointType === 1">
                  <b-table
                    narrowed
                    hoverable
                    bordered
                    :paginated="true"
                    :per-page="5"
                    :data="tickets"
                    pagination-simple
                    pagination-size="is-small"
                    :row-class="getRowClassCollectionPoint"
                    style="font-size: 0.75rem; width: 99%;"
                  >
                    <b-table-column
                      centered
                      v-slot="props"
                      label="Utworzony przez"
                      width="13%"
                    >{{ getWorker(props.row.createdBy) }}</b-table-column>
                    <b-table-column
                      centered
                      v-slot="props"
                      label="Data utworzenia"
                      width="13%"
                    >{{ mDate(props.row.created) }}</b-table-column>
                    <b-table-column centered v-slot="props" label="Płatnik" width="13%">
                      <b-icon :icon="getPayerTypeIcon(props.row.payerType)" size="is-small" />
                      {{ getPayerTypeName(props.row.payerType) }}
                    </b-table-column>
                    <b-table-column v-slot="props" label="Procedury" width="60%">
                      <ul>
                        <li
                          v-for="proc in props.row.procedures"
                          :key="proc.index"
                        >{{ proc.icd9 }} - {{ proc.description }}</li>
                      </ul>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Akcje" width="1%">
                      <b-tooltip
                        label="Wybierz bilet"
                        position="is-left"
                        v-show="!ticketsCollectionPointSelected.includes(props.row)"
                      >
                        <b-button
                          size="is-small"
                          icon-left="check"
                          @click="selectCollectionPointTicket(props.row)"
                        ></b-button>
                      </b-tooltip>
                      <b-tooltip
                        label="Odznacz bilet"
                        position="is-left"
                        v-show="ticketsCollectionPointSelected.includes(props.row)"
                      >
                        <b-button
                          size="is-small"
                          type="is-danger"
                          icon-left="close"
                          @click="deleteCollectionPointTicket(props.row)"
                        ></b-button>
                      </b-tooltip>
                    </b-table-column>
                    <template slot="empty">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad-outline" size="is-large"></b-icon>
                        </p>
                        <p>Brak biletów do punktu pobrań.</p>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div v-if="ticketsCollectionPointType === 2">
                  <b-table
                    narrowed
                    hoverable
                    bordered
                    :paginated="true"
                    :per-page="5"
                    :data="ticketsPassCollectionPoint"
                    pagination-simple
                    pagination-size="is-small"
                    :row-class="getRowClassCollectionPointPass"
                    style="font-size: 0.75rem; width: 99%;"
                  >
                    <b-table-column
                      centered
                      v-slot="props"
                      label="Utworzone przez"
                      width="13%"
                    >{{ getWorker(props.row.createdBy) }}</b-table-column>
                    <b-table-column centered v-slot="props" label="Ważne od-do" width="13%">
                      <p>{{ mDate(props.row.startDate) }}</p>-
                      <p>{{ mDate(props.row.endDate) }}</p>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Płatnik" width="13%">
                      <div v-for="ticket in props.row.tickets" :key="ticket.id">
                        <b-icon :icon="getPayerTypeIcon(ticket.payerType)" size="is-small" />
                        {{ getPayerTypeName(ticket.payerType) }}
                      </div>
                    </b-table-column>
                    <b-table-column v-slot="props" label="Procedury" width="60%">
                      <div v-for="(ticket, index) in props.row.tickets" :key="ticket.id">
                        <span class="has-text-weight-semibold mb-5">Bilet - #{{index + 1}}</span>
                        <ul>
                          <li
                            v-for="proc in ticket.procedures"
                            :key="proc.index"
                          >{{ proc.icd9 }} - {{ proc.description }}</li>
                        </ul>
                      </div>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Akcje" width="1%">
                      <b-tooltip
                        label="Wybierz zlecenie"
                        position="is-left"
                        v-show="!ticketsPassCollectionPointSelected.includes(props.row)"
                      >
                        <b-button
                          size="is-small"
                          icon-left="check"
                          @click="selectCollectionPointTicketPass(props.row)"
                        ></b-button>
                      </b-tooltip>
                      <b-tooltip
                        label="Odznacz zlecenie"
                        position="is-left"
                        v-show="ticketsPassCollectionPointSelected.includes(props.row)"
                      >
                        <b-button
                          size="is-small"
                          type="is-danger"
                          icon-left="close"
                          @click="deleteCollectionPointTicketPass(props.row)"
                        ></b-button>
                      </b-tooltip>
                    </b-table-column>
                    <template slot="empty">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad-outline" size="is-large"></b-icon>
                        </p>
                        <p>Brak zleceń stałych do punktu pobrań.</p>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer v-if="!is40Plus" class="modal-card-foot p-2">
          <b-button
            :loading="requestInProgress"
            :disabled="
              insufficientPatientData ||
              collectionPointDisplay.length < 1 ||
              collectionPointDurationSum > maxDuration ||
              appointmentObject === null ||
              (appointmentObject != null &&
                !appointment.isTimeless &&
                visitLength < appointmentObject.minTime &&
                appointmentObject.blockTime)
            "
            type="is-secondary"
            @click="scheduleCollectionPointDispatch(false)"
            icon-left="calendar-check"
          >Umów wizytę z biletem</b-button>
          <b-button
            type="is-danger"
            :loading="requestInProgress"
            @click="closeAddAppointment()"
            icon-left="close"
          >Anuluj</b-button>
          <b-button
            :loading="requestInProgress"
            :disabled="reservationFinalizeDisabled || collectionPointDisplay.length < 1 || collectionPointDurationSum > maxDuration"
            @click="sendScheduleCollectionPointReservationDialog()"
            icon-left="calendar-clock"
            class="is-pulled-right"
          >Zapisz jako wstępną rezerwację z biletem</b-button>
        </footer>

        <!-- DLA PROGRAMU 40++++ - WAŻNE !!!!!  -->
        <footer v-if="is40Plus" class="modal-card-foot p-2">
          <b-button
            :loading="requestInProgress"
            :disabled="
              (!ticketReferral && !ticketToAdd) ||
              insufficientPatientData ||
              appointmentObject === null ||
              (appointmentObject != null &&
                !appointment.isTimeless &&
                visitLength < appointmentObject.minTime &&
                appointmentObject.blockTime)
            "
            type="is-secondary"
            icon-left="calendar-check"
            @click="schedule40PlusDispatch(false)"
          >Umów wizytę</b-button>
          <b-button
            type="is-danger"
            :loading="requestInProgress"
            @click="closeAddAppointment()"
            icon-left="close"
          >Anuluj</b-button>
          <b-button
            :loading="requestInProgress"
            :disabled="reservationFinalizeDisabled"
            @click="schedule40PlusDispatch(true)"
            icon-left="calendar-clock"
            class="is-pulled-right"
          >Zapisz jako wstępną rezerwację</b-button>
        </footer>
      </div>
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->

      <!--------------------------------------------------------->
      <!--------- NEW VISIT && RESERVATION COMPLETION ----------->
      <!--------------------------------------------------------->
      <div class="modal-card" style="width: 100% !important" v-else>
        <section
          class="modal-card-body w-100 pt-3 pl-3 pr-3 pb-5"
          style="min-height:72vh;max-heght:88vh"
          v-if="appointment"
        >
          <div class="columns">
            <div class="column is-6">
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- WORKTIME AREA DATA - GENERAL -->
              <!--------------------------------------------------------->
              <table class="table is-narrow is-bordered w-100 mb-3">
                <tr>
                  <th style="width: 10rem">Data</th>
                  <th>{{ mDate(date) }}</th>
                </tr>
                <tr v-if="patient">
                  <th>Pacjent</th>
                  <th>
                      <span class="has-text-primary">
                        {{ patient.lastName }} {{ patient.firstName }}
                      </span>
                      <span class="ml-3" v-if="patient.pesel">
                        PESEL: {{ patient.pesel }}
                      </span>
                      <span class="ml-3" v-else>
                        <span v-if="patient.identityDocument === 0"
                          >Dowód osobisty,
                        </span>
                        <span v-if="patient.identityDocument === 1">Paszport, </span>
                        <span v-if="patient.identityDocumentNumber">
                          Numer dokumentu: {{ patient.identityDocumentNumber }}
                        </span>
                      </span>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Wiek pacjenta</th>
                  <th>
                    {{ patient.age }}
                    <b-tag
                      :type="ageRestriction ? 'is-danger' : 'is-secondary'"
                      class="is-pulled-right has-text-semibold"
                      v-if="appointmentObject && appointmentObject.hasAgeRestriction"
                    >
                      Wybrany typ wizyty wymaga wieku od
                      <b>{{ ageFrom }}</b> do
                      <b>{{ageTo}}</b> lat
                    </b-tag>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Adres</th>
                  <th>
                    {{ patient.addressStreet ? patient.addressStreet : "-" }}
                    {{ patient.addressHouseNumber ? patient.addressHouseNumber : "-" }}
                    {{ patient.addressApartmentNumber ? `/ ${patient.addressApartmentNumber},` : "," }}
                    {{ patient.addressZipCode ? patient.addressZipCode : "-" }}
                    {{ patient.addressCity ? patient.addressCity : "-" }}
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>Numer telefonu</th>
                  <th>
                    <b-tooltip
                      position="is-right"
                      label="Upewnij się że wpisany numer jest poprawny"
                      type="is-primary"
                      v-if="phoneNumberWarning"
                    >
                      <b-icon icon="information-outline" style="color: orange"></b-icon>
                    </b-tooltip>
                    <b-tooltip
                      position="is-right"
                      label="Upewnij się że numer kierunkowy do Polski nie został zdublowany"
                      type="is-primary"
                      v-if="phoneNumberPrefixWarning"
                    >
                      <b-icon icon="information-outline" type="is-warning"></b-icon>
                    </b-tooltip>
                    <span
                      v-if="patient.teleAddressTelephoneNumber"
                    >{{patient.teleAddressTelephoneNumber}}</span>
                    <span v-else class="has-text-danger has-text-weight-bold">Brak numeru telefonu</span>
                  </th>
                </tr>
                <tr v-if="patient && patient.teleAddressTelephoneNumber">
                  <th>Właściciel numeru</th>
                  <th>
                    {{patient.phoneNumberOwner ? patient.phoneNumberOwner : "brak informacji"}}
                    <span
                      v-if="patient.phoneNumberOtherOwner"
                    >{{patient.phoneNumberOtherOwner}}</span>
                  </th>
                </tr>
                <tr v-if="patient">
                  <th>EWUŚ</th>
                  <th>
                    <div class="has-text-centered" v-if="!ewus && ewusGuid">
                      <b-progress
                        type="is-primary"
                        style="margin: 0.25rem 5%;"
                        size="is-small"
                        :rounded="false"
                      ></b-progress>
                    </div>
                    <div v-else-if="ewus">
                      <b-tooltip
                        :label="
                            'Data aktualizacji: ' +
                            mDate(ewus.dateExp)
                          "
                        type="is-dark"
                        position="is-right"
                      >
                        <b-tag
                          :type="getEwusType(ewus.ewusStatus)"
                          class="vam"
                        >{{ getEwusStatus(ewus.ewusStatus) }}</b-tag>

                        <b-tag v-if="ewus.ewusDN === 1" type="is-dark" class="vam">DN</b-tag>
                      </b-tooltip>
                      <b-tooltip
                        label="
                              Pobierz ponownie informacje z systemu eWUŚ
                            "
                        type="is-dark"
                        position="is-left"
                        class="is-pulled-right"
                      >
                        <b-tag style="cursor: pointer" class="ml-2 vam" @click.native="resetEwus">
                          <b-icon class="vam" icon="refresh" type="is-dark"></b-icon>
                          <span class="vam">Odśwież</span>
                        </b-tag>
                      </b-tooltip>
                    </div>
                    <div v-else>Brak danych
                      <b-tooltip
                        label="
                            Pobierz ponownie informacje z systemu eWUŚ
                          "
                        type="is-dark"
                        position="is-left"
                        class="is-pulled-right"
                      >
                        <b-tag style="cursor: pointer" class="ml-2 vam" @click.native="resetEwus">
                          <b-icon class="vam" icon="refresh" type="is-dark"></b-icon>
                          <span class="vam">Odśwież</span>
                        </b-tag>
                      </b-tooltip>
                    </div>
                  </th>
                </tr>
                <tr v-if="patient && showIdentificationInfo && lastIdentificationInfo">
                  <th>Ubezpieczenie</th>
                  <th>
                    <span class="has-text-primary">
                    {{ mGetIdentification(lastIdentificationInfo.identification) }},
                    </span>
                    <span
                      v-if="
                        lastIdentificationInfo.entitlementDocument == -1
                      "
                      class="has-text-success"
                    >
                      Potwierdzone przez system eWUŚ
                    </span>
                    <span
                      v-else-if="
                        lastIdentificationInfo.entitlementDocument != 99
                      "
                      class="has-text-success"
                    >
                      {{ mGetEntitlementDocument(lastIdentificationInfo.entitlementDocument) }}
                    </span>
                    <span v-else class="has-text-danger">
                      {{ mGetEntitlementDocument(lastIdentificationInfo.entitlementDocument) }}
                    </span>
                  </th>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.device">
                  <th>Urządzenie</th>
                  <td v-if="areaDevice">
                    <span class="has-text-weight-semibold">
                      {{
                      areaDevice.name
                      }}
                    </span>
                    ({{ areaDevice.shortName }}),
                    {{ getFacility(areaDevice.facility) }}
                  </td>
                  <td v-else>
                    <b-skeleton expanded></b-skeleton>
                  </td>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.officeId">
                  <th>Gabinet</th>
                  <td>{{ getOffice(block.worktimeArea.officeId) }}</td>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.employee > -1">
                  <th>Pracownik</th>
                  <td v-if="employee">{{ employee.lastName }} {{ employee.firstName }}</td>
                  <td v-else>
                    <b-skeleton class="w-100"></b-skeleton>
                  </td>
                </tr>
                <tr v-if="block.worktimeArea">
                  <th>Strefa</th>
                  <td>{{ getArea(block.worktimeArea.area) }}</td>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.clinic">
                  <th>Poradnia</th>
                  <td>{{ mGetClinic(block.worktimeArea.clinic) }}</td>
                </tr>
                <tr v-if="block.worktimeArea && block.worktimeArea.facility">
                  <th>Placówka</th>
                  <td>{{ getClinic(block.worktimeArea.facility) }}</td>
                </tr>
                <tr v-if="nfzService && appointment && appointment.payerType == 1">
                  <th>Świadczenie</th>
                  <td>{{ nfzService.display }}</td>
                </tr>
                <tr v-if="appointmentObject && appointmentObject.alert">
                  <th>Uwagi</th>
                  <td>
                    <b>{{ appointmentObject.alert }}</b>
                  </td>
                </tr>
              </table>

              <table
                class="table is-narrow is-bordered w-100 mb-4"
                v-if="patient && appointment && appointment.payerType == 1"
              >
                <tr>
                  <th colspan="2">Informacja NFZ</th>
                </tr>
                <tr>
                  <td>Oddział NFZ pacjenta</td>
                  <td>{{mGetBranchNfzNameParsed(patient.nfzDepartment)}}</td>
                </tr>
                <tr v-if="currentOffice">
                  <td>Oddział NFZ gabinetu</td>
                  <td>
                    <span>{{mGetBranchNfzNameParsed(currentOffice.nfzCode)}}</span>
                  </td>
                </tr>
                <tr>
                  <td>Deklaracje</td>
                  <td>
                    <p v-html="declarationsText"></p>
                  </td>
                </tr>
              </table>

              <!--------------------------------------------------------->
              <!--******************* ALERTS / NOTIFICATIONS *******************-->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <b-notification
                type="is-danger"
                class="mb-1"
                has-icon
                v-if="appointment.payerType === 1 && hasPozAppToday"
              >
                <b>Pacjent ma już inną wizytę NFZ tego dnia.</b> Rozliczenie kolejnej wizyty może nie być możliwe.
              </b-notification>
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <b-notification type="is-danger" class="mb-1" v-if="ageRestriction && patient">
                Wybrany typ wizyty posiada
                <b>ograniczenie wiekowe</b> i wymaga pacjenta w wieku
                <b>{{ageFrom}} - {{ageTo}}</b> lat.
              </b-notification>
              <!--------------------------------------------------------->

              <!-- EMPTY WORKTIME AREA ALERT -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-danger"
                class="mb-1"
                v-if="block.worktimeArea === null"
              >Wizyta umawiana poza strefą terminarza!</b-notification>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- TIMELESS VISITS LIMITS ALERT - permission needed to exceed max timeless limit for office type -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-warning"
                class="mb-1"
                v-if="ignoreTimelessLimit && timelessLimitReached"
              >
                Uwaga, liczba wizyt bez godziny dla tego gabinetu przekracza
                ustalony limit ({{
                thisOfficeType.maxTimeless
                }}).
              </b-notification>
              <b-notification
                type="is-danger"
                has-icon
                class="mb-1"
                v-else-if="timelessLimitReached && !noQueuePrivilage"
              >
                Osiągnięto limit wizyt bez godziny dla tego gabinetu ({{
                thisOfficeType.maxTimeless
                }}). Rejestracja nie jest możliwa.
              </b-notification>
              <b-notification
                type="is-success"
                class="mb-1"
                v-if="noQueuePrivilage"
                has-icon
              >Ze względu na przywileje pacjenta, możliwy jest zapis wizyt bez kolejki (bez godziny), nawet jeśli gabinet na to nie pozwala</b-notification>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- APPOINTMENT APKOLCE -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-orange"
                class="mb-1"
                v-if="appointmentObject && appointmentObject.apkolce"
              >
                <b>
                  Wizyta NFZ będzie rejestrowana w AP-KOLCE. Termin zostanie
                  przekazany do zatwierdzenia.
                </b>
              </b-notification>
              <template v-if="!isCheckApkolceNotRequired">
              <b-notification
                type="is-danger"
                class="mb-1"
                v-if="appointmentObject && appointmentObject.apkolce  && !canApkolceAddedVisit && !isCheckPassword"
              >
                <b>
                  Wizyta NFZ zgłaszana do AP-KOLCE. Brak lub błąd konfiguracji pracownika AP-KOLCE.
                  Przejdź do "Moje konto" i wprowadź poprawną konfiguracje
                </b>
                <div v-if="apkolceLoginError != null" style="font-size:0.8rem"
              v-html="apkolceLoginError"></div>
                <MyAccountApKolceButton />
              </b-notification>

              <b-notification
                type="is-info"
                class="mb-1"
                v-if="appointmentObject && appointmentObject.apkolce  && !canApkolceAddedVisit && isCheckPassword"
              >
                <b>
                  Sprawdzanie konfiguracji AP-KOLCE użytkownika...
                </b>
                <!-- <div v-if="apkolceLoginError != null" style="font-size:0.8rem"
              v-html="apkolceLoginError"></div>
                <MyAccountApKolceButton /> -->
              </b-notification>

              <b-notification
                type="is-success"
                class="mb-1"
                v-if="appointmentObject && appointmentObject.apkolce && canApkolceAddedVisit"
              >
                <b>
                  Wizyta NFZ zgłaszana do AP-KOLCE. Poprawna konfiguracji pracownika AP-KOLCE.
                </b>
                <MyAccountApKolceButton />
              </b-notification>
              </template>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- INSUFFICIENT PATIENT DATA ALERT -->
              <!--------------------------------------------------------->
              <b-notification
                type="is-orange"
                siz="is-small"
                class="mb-1"
                v-if="
                  loadingPatientIdentification == false &&
                  insufficientPatientData
                "
              >
                <p>
                  <b>Niewystarczające dane pacjenta do umówienia wizyty</b>
                </p>
                <p>{{ insufficientPatientDataText }}</p>
              </b-notification>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- APPOINTMENT ALERTS -->
              <!--------------------------------------------------------->
              <b-notification
                siz="is-small"
                type="is-secondary"
                class="mb-1"
                v-if="
                  appointmentObject &&
                  appointmentObject.alerts &&
                  appointmentObject.alerts.length > 0
                "
              >
                <ul style="list-style-type: square">
                  <li v-for="(item, index) in appointmentObject.alerts" :key="index">{{ item }}</li>
                </ul>
              </b-notification>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* GENERAL APPOINTMENT DATA *******************-->
              <!--------------------------------------------------------->

              <!-- SPECIALIST OFFICE -->
              <!--------------------------------------------------------->

              <!--
              * Specialist office requirement - officeType id - 200001
              * visitTypeSpecialist prop -  choice between first time visit / continuation of visit

              * for first time visit:
                * add referral if payer type is NFZ
                * optional referral if payer type if commercial

              * for visit continuation:
                * check if patient has free (state prop in ticket, value: 1) tickets for clinic
                * if there are tickets - choose one
                * if there are no tickets but patient has already had visits for clinic
                  - either choose ticket from visits archive or add referral data
                * both no tickets and no visits for clinic - add referral data
              -->

              <!-- SPECIALIST VISIT TYPE - FIRST TIME / CONTINUATION -->
              <!--------------------------------------------------------->
              <b-message
                :type="visitTypeSpecialist != 1 && visitTypeSpecialist != 2 ? 'is-danger':'is-info'"
                animation="false"
                class="mb-2 has-text-weight-semibold"
                :closable="false"
                icon="calendar-alert"
                v-if="
                  isReferralRequired &&
                  isSpecialistOffice &&
                  !isOnlyPayerCommercial &&
                  !patientPopulationVisitTypeSelected &&
                  (!reservationMode || (reservationMode && ticketToAdd === null))
                "
                has-icon
              >
                Rejestracja do gabinetu specjalisty wymaga wskazania, czy jest
                to wstępna wizyta pacjenta lub kontynuacja leczenia:
                <b-field class="mt-3 is-pulled-right">
                  <b-radio-button
                    :native-value="1"
                    type="is-secondary"
                    v-model="visitTypeSpecialist"
                  >
                    <b-icon icon="calendar-end" class="mr-2"></b-icon>
                    <span>Wstępna</span>
                  </b-radio-button>
                  <b-radio-button
                    :native-value="2"
                    type="is-secondary"
                    v-model="visitTypeSpecialist"
                  >
                    <b-icon icon="calendar-expand-horizontal" class="mr-2"></b-icon>
                    <span>Kontynuacja</span>
                  </b-radio-button>
                </b-field>
              </b-message>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- SPECIALIST VISIT CONTINUATION -->
              <!--------------------------------------------------------->
              <div
                v-if="
                  isReferralRequired &&
                  isSpecialistOffice &&
                  !isOnlyPayerCommercial &&
                  !patientPopulationVisitTypeSelected &&
                  visitTypeSpecialist === 2
                "
              >
                <!-- CLINIC TICKET SELECT -->
                <!--------------------------------------------------------->
                <div v-if="tickets.length > 0">
                  <b-notification
                    animation="false"
                    class="mb-2"
                    style="font-size: 0.9rem !important"
                    :closable="false"
                    v-if="ticketToAdd == null"
                    has-icon
                    icon-size="is-small"
                  >
                    Kontynuacja leczenia wymaga wyboru biletu pacjenta do
                    określonej poradni specjalistycznej
                    <b-autocomplete
                      :data="tickets"
                      clearable
                      open-on-focus
                      placeholder="Wybierz bilet"
                      keep-first
                      @select="
                        (option) => (ticketToAdd = option ? option : null)
                      "
                      class="mt-3 mb-3"
                    >
                      <template slot-scope="props">
                        <p>Data utworzenia: {{ mDateTime(props.option.created) }}</p>
                        <p>Ważny od: {{ mDateTime(props.option.startDate) }}</p>
                        <p>Ważny do: {{ mDateTime(props.option.expiryDate) }}</p>
                        <p>
                          Utworzony przez:
                          {{ getWorker(props.option.createdBy) }}
                        </p>
                        <p>Typ: {{ getTicketType(props.option.ticketType) }}</p>
                        <p>
                          Poradnia wystawiająca:
                          {{
                          props.option.facilityParams
                          ? props.option.facilityParams.clinicTypeName
                          : "Brak"
                          }}
                        </p>
                      </template>
                    </b-autocomplete>
                  </b-notification>
                  <!-- TICKET DATA -->
                  <!--------------------------------------------------------->
                  <b-notification
                    animation="false"
                    type="is-success"
                    class="mb-2"
                    style="font-size: 0.9rem !important"
                    :closable="false"
                    has-icon
                    icon-size="is-small"
                    icon="plus-thick"
                    v-else-if="ticketToAdd !== null"
                  >
                    <b>Dodano bilet</b>

                    <div class="columns mb-0">
                      <div class="column is-6">
                        <p>Data utworzenia: {{ mDateTime(ticketToAdd.created) }}</p>
                        <p>Ważny od: {{ mDateTime(ticketToAdd.startDate) }}</p>
                        <p>Ważny do: {{ mDateTime(ticketToAdd.expiryDate) }}</p>
                      </div>
                      <div class="column is-6">
                        <p>
                          Utworzony przez:
                          {{ getWorker(ticketToAdd.createdBy) }}
                        </p>
                        <p>Typ: {{ getTicketType(ticketToAdd.ticketType) }}</p>
                        <p>
                          Poradnia wystawiająca:
                          {{
                          ticketToAdd.facilityParams
                          ? ticketToAdd.facilityParams.clinicTypeName
                          : "Brak"
                          }}
                        </p>
                      </div>
                    </div>

                    <b-button
                      size="is-small"
                      icon-left="close"
                      @click="cancelTicket"
                      class="is-pulled-right"
                    >Anuluj</b-button>
                  </b-notification>
                </div>
                <!--------------------------------------------------------->
                <!--------------------------------------------------------->

                <!-- CLINIC VISIT HISTORY TICKET SELECT -->
                <!--------------------------------------------------------->
                <div
                  v-else-if="
                    tickets.length < 1 &&
                    visitTypeSpecialistPatientHistory.length > 0
                  "
                >
                  <b-message
                    animation="false"
                    type="is-orange"
                    class="mb-2"
                    has-icon
                    icon="calendar-remove"
                    :closable="false"
                    v-if="ticketToAdd == null"
                  >
                    Brak biletów dla pacjenta do określonej poradni
                    specjalistycznej. Znajdź bilet w archiwum pacjenta dla
                    poradni lub dodaj dane skierowania (dla wizyty NFZ).
                    <b-button
                      @click="visitTypeSpecialistHistoryModalActive = true"
                      class="mt-3 ml-3 is-pulled-right"
                      icon-left="calendar-search"
                    >Przeglądaj wizyty historyczne</b-button>
                  </b-message>
                  <!-- TICKET DATA -->
                  <!--------------------------------------------------------->
                  <b-notification
                    animation="false"
                    type="is-success"
                    class="mb-2"
                    style="font-size: 0.9rem !important"
                    :closable="false"
                    has-icon
                    icon-size="is-small"
                    icon="plus-thick"
                    v-else-if="ticketReferral !== null"
                  >
                    <b>Dodano bilet</b>

                    <div class="columns mb-0">
                      <div class="column is-6">
                        <p>
                          Data utworzenia:
                          {{ mDateTime(ticketReferral.created) }}
                        </p>
                        <p>Ważny od: {{ mDateTime(ticketReferral.startDate) }}</p>
                        <p>Ważny do: {{ mDateTime(ticketReferral.expiryDate) }}</p>
                      </div>
                      <div class="column is-6">
                        <p>Typ: {{ getTicketType(ticketReferral.ticketType) }}</p>
                        <p>
                          Poradnia wystawiająca:
                          {{
                          ticketReferral.facilityParams
                          ? ticketReferral.facilityParams.clinicTypeName
                          : "Brak"
                          }}
                        </p>
                      </div>
                    </div>

                    <b-button
                      size="is-small"
                      icon-left="close"
                      @click="cancelTicket"
                      class="is-pulled-right"
                    >Anuluj</b-button>
                  </b-notification>
                </div>
                <!--------------------------------------------------------->
                <!--------------------------------------------------------->

                <!-- NO TICKET / CLINIC HISTORY - REQUIRED REFERRAL ALERT -->
                <!--------------------------------------------------------->
                <div
                  v-else-if="
                    tickets.length < 1 &&
                    visitTypeSpecialistPatientHistory.length < 1
                  "
                >
                  <b-notification
                    animation="false"
                    type="is-orange"
                    class="mb-2"
                    style="font-size: 0.9rem !important"
                    has-icon
                    icon="plus-thick"
                    :closable="false"
                  >
                    Brak wolnych biletów oraz archiwum dla pacjenta do
                    określonej poradni specjalistycznej. Wymagane dodanie
                    skierowania (dla wizyty NFZ).
                  </b-notification>
                </div>
              </div>

              <div
                v-if="
                  patientPopulationVisitTypeSelected &&
                  (!reservationMode ||
                    (reservationMode && !block.appointment.ticketInfo))
                "
              >
                <b-notification
                  animation="false"
                  :type="
                    patientPopulationAppointmentTicket
                      ? 'is-orange'
                      : 'is-danger'
                  "
                  has-icon
                  icon="plus-thick"
                  class="mb-2"
                  :closable="false"
                >
                  <div v-if="patientPopulationAppointmentTicket">
                    Znaleziono wolny bilet pacjenta dla typu wizyty w ramach
                    programu populacyjnego
                    <b-button
                      @click="patientPopulationAddTicket"
                      class="mt-3 ml-3 is-pulled-right"
                      type="is-secondary"
                    >
                      <span>Podepnij bilet do wizyty</span>
                    </b-button>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        !patientPopulationProgramsQualified ||
                        patientPopulationProgramsQualified.length <
                          1
                      "
                    >
                      Pacjent nie został zakwalifikowany do programu
                      populacyjnego
                    </div>
                    <div
                      v-else-if="
                        !patientPopulationHasQualificationVisit ||
                        patientPopulationHasQualificationVisit.length <
                          1
                      "
                    >
                      Pacjent nie odbył wizyty kwalifikacyjnej w ramach programu
                      populacyjnego
                    </div>
                    <div
                      v-else-if="
                        !patientPopulationAppointmentTicket ||
                        patientPopulationAppointmentTicket.length <
                          1
                      "
                    >
                      Pacjent nie posiada uwolnionego biletu lub bilet na
                      wybrany typ wizyty został już zrealizowany
                    </div>
                  </div>
                </b-notification>

                <b-notification
                  animation="false"
                  type="is-success"
                  class="mb-2"
                  :closable="false"
                  has-icon
                  icon="plus-thick"
                  v-if="ticketToAdd !== null"
                >
                  <b>Dodano bilet</b>

                  <div class="columns mb-0">
                    <div class="column is-6">
                      <p>Data utworzenia: {{ mDateTime(ticketToAdd.created) }}</p>
                      <p>Ważny od: {{ mDateTime(ticketToAdd.startDate) }}</p>
                      <p>Ważny do: {{ mDateTime(ticketToAdd.expiryDate) }}</p>
                    </div>
                    <div class="column is-6">
                      <p>Utworzony przez: {{ getWorker(ticketToAdd.createdBy) }}</p>
                      <p>Typ: {{ getTicketType(ticketToAdd.ticketType) }}</p>
                      <p>
                        Poradnia wystawiająca:
                        {{
                        ticketToAdd.facilityParams
                        ? ticketToAdd.facilityParams.clinicTypeName
                        : "Brak"
                        }}
                      </p>
                    </div>
                  </div>
                </b-notification>
              </div>

              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <div
                v-if="
                  reservationMode &&
                  isRISOffice &&
                  block.appointment.state == 100 &&
                  block.appointment.ticketInfo
                "
                class="mb-1"
              >
                <SchedulePatientChangeOrder
                  :examination="block.appointment"
                  :ticket="block.appointment.ticketInfo"
                ></SchedulePatientChangeOrder>
              </div>

              <div
                v-if="
                  reservationMode &&
                  isRISOffice &&
                  block.appointment.state == 100 && 
                  block.appointment.ticketInfo
                "
                class="mb-1"
              >
                <SchedulePatientChangeIKZ :examination="block.appointment"></SchedulePatientChangeIKZ>
              </div>

              <div
                v-if="
                  reservationMode &&
                  isRISOffice &&
                  block.appointment.state == 100 &&
                  block.appointment.ticketInfo &&
                  block.appointment.ticketInfo.apkolce
                "
                class="mb-1"
              >
                <RISPatientChangeAPKolce :ticket="block.appointment.ticketInfo"></RISPatientChangeAPKolce>
              </div>

              <div
                v-if="
                  reservationMode &&
                  isRISOffice &&
                  block.appointment.state == 100 &&
                  block.appointment.ticketInfo &&
                  block.appointment.ticketInfo.referral &&
                  block.appointment.ticketInfo.referral.apkolce
                "
                class="mb-1"
              >
                <RISPatientChangeAPKolceReferral :ticket="block.appointment.ticketInfo"></RISPatientChangeAPKolceReferral>
              </div>

              <div
                v-if="
                  reservationMode &&
                  block.appointment.state == 100 &&
                  (block.appointment.type === 1000 ||
                    block.appointment.type === 1012 ||
                    block.appointment.type === 1023 ||
                    block.appointment.type === 10000 ||
                    block.appointment.type === 10001)
                "
              >
                <RISPatientChangeDILO :ticket="block.appointment.ticketInfo"></RISPatientChangeDILO>
              </div>

              <b-field
                label="Wybrany bilet pacjenta"
                expanded
                label-position="on-border"
                v-if="reservationMode && ticketToAdd && isNurseTreatmentsOffice"
                class="mt-3"
              >
                <b-table
                  narrowed
                  hoverable
                  bordered
                  :data="[ticketToAdd]"
                  pagination-simple
                  pagination-size="is-small"
                  class="pt-3"
                  style="font-size: 0.75rem; width: 99%;"
                >
                  <b-table-column
                    centered
                    v-slot="props"
                    label="Utworzony przez"
                    width="13%"
                  >{{ getWorker(props.row.createdBy) }}</b-table-column>
                  <b-table-column
                    centered
                    v-slot="props"
                    label="Data utworzenia"
                    width="13%"
                  >{{ mDate(props.row.created) }}</b-table-column>
                  <b-table-column centered v-slot="props" label="Wizyta" width="13%">
                    <b-icon :icon="getAppointmentTypeIcon(props.row.ticketType)" size="is-small" />
                    {{ mGetAppType(props.row.ticketType) }}
                    <span
                      v-if="props.row.ticketSubType > 0"
                    >{{ mGetAppSubtype(props.row.ticketType, props.row.ticketSubType)}}</span>
                  </b-table-column>
                  <b-table-column centered v-slot="props" label="Płatnik" width="13%">
                    <b-icon :icon="getPayerTypeIcon(props.row.payerType)" size="is-small" />
                    {{ getPayerTypeName(props.row.payerType) }}
                  </b-table-column>
                  <b-table-column v-slot="props" label="Procedury" width="48%">
                    <ul>
                      <li
                        v-for="proc in props.row.procedures"
                        :key="proc.index"
                      >{{ proc.icd9 }} - {{ proc.description }}</li>
                    </ul>
                    <OrdersDetails :order="props.row" />
                  </b-table-column>
                  <template slot="empty">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad-outline" size="is-large"></b-icon>
                      </p>
                      <p>Brak biletu do punktu pobrań.</p>
                    </div>
                  </template>
                </b-table>
              </b-field>

              <div
                v-if="isNurseTreatmentsOffice &&
                  (!reservationMode || (reservationMode && !ticketToAdd))"
              >
                <b-field
                  label="Bilety na zlecenia zabiegowe"
                  expanded
                  label-position="on-border"
                  class="mt-3"
                >
                  <p class="control pt-2">
                    <b-radio-button v-model="ticketsNurseType" :native-value="1" type="is-primary">
                      <b-icon icon="account"></b-icon>Bilety
                    </b-radio-button>
                  </p>
                  <p class="control pt-2">
                    <b-radio-button v-model="ticketsNurseType" :native-value="2" type="is-primary">
                      <b-icon icon="ticket"></b-icon>Zlecenia stałe
                    </b-radio-button>
                  </p>
                </b-field>

                <div v-if="ticketsNurseType === 1">
                  <b-table
                    narrowed
                    hoverable
                    bordered
                    :paginated="true"
                    :per-page="5"
                    :data="tickets"
                    pagination-simple
                    pagination-size="is-small"
                    :row-class="getRowClassNurseTicket"
                    style="font-size: 0.75rem; width: 99%;"
                  >
                    <b-table-column
                      centered
                      v-slot="props"
                      label="Utworzony przez"
                      width="13%"
                    >{{ getWorker(props.row.createdBy) }}</b-table-column>
                    <b-table-column
                      centered
                      v-slot="props"
                      label="Data utworzenia"
                      width="13%"
                    >{{ mDate(props.row.created) }}</b-table-column>
                    <b-table-column centered v-slot="props" label="Wizyta" width="13%">
                      <b-icon :icon="getAppointmentTypeIcon(props.row.ticketType)" size="is-small" />
                      {{ mGetAppType(props.row.ticketType) }}
                      <span
                        v-if="props.row.ticketSubType > 0"
                      >{{ mGetAppSubtype(props.row.ticketType, props.row.ticketSubType)}}</span>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Płatnik" width="13%">
                      <b-icon :icon="getPayerTypeIcon(props.row.payerType)" size="is-small" />
                      {{ getPayerTypeName(props.row.payerType) }}
                    </b-table-column>
                    <b-table-column v-slot="props" label="Procedury" width="47%">
                      <ul>
                        <li
                          v-for="proc in props.row.procedures"
                          :key="proc.index"
                        >{{ proc.icd9 }} - {{ proc.description }}</li>
                      </ul>
                      <OrdersDetails :order="props.row" />
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Akcje" width="1%">
                      <b-tooltip
                        label="Wybierz bilet"
                        position="is-left"
                        v-show="ticketToAdd !== props.row"
                      >
                        <b-button
                          size="is-small"
                          icon-left="check"
                          @click="selectNurseTicket(props.row)"
                        ></b-button>
                      </b-tooltip>
                      <b-tooltip
                        label="Odznacz bilet"
                        position="is-left"
                        v-show="ticketToAdd === props.row"
                      >
                        <b-button
                          size="is-small"
                          type="is-danger"
                          icon-left="close"
                          @click="ticketToAdd = null"
                        ></b-button>
                      </b-tooltip>
                    </b-table-column>
                    <template slot="empty">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad-outline" size="is-large"></b-icon>
                        </p>
                        <p>Brak biletów na zabiegi pielęgniarskie.</p>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div v-if="ticketsNurseType === 2">
                  <b-table
                    narrowed
                    hoverable
                    bordered
                    :paginated="true"
                    :per-page="5"
                    :data="ticketsPassNurse"
                    pagination-simple
                    pagination-size="is-small"
                    :row-class="getRowClassTicketPassNurse"
                    style="font-size: 0.75rem; width: 99%;"
                  >
                    <b-table-column
                      centered
                      v-slot="props"
                      label="Utworzone przez"
                      width="13%"
                    >{{ getWorker(props.row.createdBy) }}</b-table-column>
                    <b-table-column centered v-slot="props" label="Ważne od-do" width="13%">
                      <p>{{ mDate(props.row.startDate) }}</p>-
                      <p>{{ mDate(props.row.endDate) }}</p>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Wizyta" width="13%">
                      <div v-for="ticket in props.row.tickets" :key="ticket.id">
                        <b-icon :icon="getAppointmentTypeIcon(ticket.ticketType)" size="is-small" />
                        {{ mGetAppType(ticket.ticketType) }}
                        <span
                          v-if="ticket.ticketSubType > 0"
                        >{{ mGetAppSubtype(ticket.ticketType, ticket.ticketSubType)}}</span>
                      </div>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Płatnik" width="13%">
                      <div v-for="ticket in props.row.tickets" :key="ticket.id">
                        <b-icon :icon="getPayerTypeIcon(ticket.payerType)" size="is-small" />
                        {{ getPayerTypeName(ticket.payerType) }}
                      </div>
                    </b-table-column>
                    <b-table-column v-slot="props" label="Procedury" width="47%">
                      <div v-for="ticket in props.row.tickets" :key="ticket.id">
                        <ul>
                          <li
                            v-for="proc in ticket.procedures"
                            :key="proc.index"
                          >{{ proc.icd9 }} - {{ proc.description }}</li>
                        </ul>
                        <OrdersDetails :order="ticket" />
                      </div>
                    </b-table-column>
                    <b-table-column centered v-slot="props" label="Akcje" width="1%">
                      <b-tooltip
                        label="Wybierz zlecenie"
                        position="is-left"
                        v-show="ticketsPassNurseSelected !== props.row"
                      >
                        <b-button
                          size="is-small"
                          icon-left="check"
                          @click="selectNurseTicketPass(props.row)"
                        ></b-button>
                      </b-tooltip>
                      <b-tooltip
                        label="Odznacz zlecenie"
                        position="is-left"
                        v-show="ticketsPassNurseSelected === props.row"
                      >
                        <b-button
                          size="is-small"
                          type="is-danger"
                          icon-left="close"
                          @click="ticketsPassNurseSelected = null"
                        ></b-button>
                      </b-tooltip>
                    </b-table-column>
                    <template slot="empty">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad-outline" size="is-large"></b-icon>
                        </p>
                        <p>Brak zleceń stałych na zabiegi pielęgniarskie.</p>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>

              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
            </div>
            <div class="column is-6">
              <b-tag
                v-if="block.worktimeArea && block.worktimeArea.isEr"
                class="mb-3"
                type="is-info"
                size="is-medium"
              >Uwaga! Wizyta synchronizowana z E-Rejestracją.</b-tag>
              <!--------------------------------------------------------->
              <!--******************* VISIT TYPE *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT MAIN TYPE -->
              <!--------------------------------------------------------->
              <b-field grouped class="mt-3">
                <b-field expanded label="Typ wizyty" label-position="on-border">
                  <b-select
                    expanded
                    placeholder="Brak typów wizyt dla wybranego pracownika"
                    @input="onTypeChanged"
                    v-model="appointmentObjectTmp"
                  >
                    <option v-for="item in filteredAppTypes" :key="item.id" :value="item.id">
                      {{ item.name }}
                      <span v-if="item.apkolce">[AP-KOLCE]</span>
                    </option>
                  </b-select>
                </b-field>
                <b-checkbox-button
                  v-if="
                    (block.worktimeArea &&
                      block.worktimeArea.officeId != null) ||
                    (block.worktimeArea === null && filters.office)
                  "
                  :native-value="true"
                  v-model="showAllAppTypes"
                >
                  <div v-if="showAllAppTypes">
                    <b-icon icon="account-circle"></b-icon>
                  </div>
                  <div v-else>
                    <b-icon icon="home"></b-icon>
                  </div>
                </b-checkbox-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- <p
          v-if="filteredAppTypes.length == 0"
          class="pb-3 has-text-danger"
              >Brak typów wizyt dla wybranego pracownika</p>-->

              <!-- VISIT SUBTYPE -->
              <!--------------------------------------------------------->
              <b-field
                v-if="subcategories && subcategories.length > 0"
                label="Podtyp"
                label-position="on-border"
              >
                <b-select
                  placeholder="Wybierz"
                  v-model="appointment.subtype"
                  @input="onSubTypeChanged"
                  expanded
                >
                  <option
                    v-for="item in subcategories"
                    :key="item.id"
                    :value="item.id"
                    v-show="!item.isDisabled"
                  >
                    {{ item.name }}
                    <span v-if="item.apkolce">[AP-KOLCE]</span>
                  </option>
                </b-select>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <b-field
                grouped
                label="Usługa/świadczenie"
                label-position="on-border"
                v-if="registrationNfzService && appointment && appointment.payerType == 1"
              >
                <!-- <b-field label="Umowa" label-position="on-border">
                  <b-select
                    v-model="contract"
                    style="min-width:12rem;"
                    :loading="nfzLoading"
                    expanded
                    @input="resetNfzService"
                  >
                    <option
                      v-for="contr in contracts"
                      :key="contr.contractId"
                      :value="contr"
                    >{{contr.contractNumber}}</option>
                  </b-select>
                </b-field>-->
                <b-autocomplete
                  clearable
                  :loading="nfzLoading"
                  expanded
                  ref="nfzSelector"
                  :data="filteredServices"
                  v-model="filter"
                  open-on-focus
                  keep-first
                  :placeholder="/*items.length > 0 ? 'Aby wybrać inne świadczenie usuń aktualnie dodane': */'Wyszukaj'"
                  field="display"
                  @select="onItemSelected"
                ></b-autocomplete>
                <!-- <b-button
                  v-if="allowLimiting&& limitingMode"
                  type="is-primary"
                  @click="limitingMode = false"
                >Pokaż wszystkie</b-button>
                <b-button
                  v-else-if="allowLimiting"
                  type="is-secondary"
                  @click="limitingMode = true"
                >Pokaż sugerowane</b-button>-->
              </b-field>

              <!--------------------------------------------------------->
              <!--******************* CYTOLOGY FIELDS *******************-->
              <!--------------------------------------------------------->

              <!-- CYTOLOGY EXAMINATION -->
              <!--------------------------------------------------------->
              <div v-if="appointmentObject && appointmentObject.id === 111" class="mb-3">
                <hr />
                <b-field
                  v-for="(exam, exam_index) in cytologyExaminations"
                  :key="exam_index"
                  grouped
                >
                  <b-field
                    label="Rodzaj badania cytologicznego"
                    label-position="on-border"
                    expanded
                  >
                    <b-select v-model="exam.type" placeholder="Wybierz" expanded>
                      <option :value="0">Badanie konwencjonalne</option>
                      <option :value="1">Badanie LBC</option>
                      <option :value="2">Badanie HPV</option>
                    </b-select>
                  </b-field>
                  <b-field
                    v-if="exam.type == 0 || exam.type == 1"
                    label="Typ badania"
                    label-position="on-border"
                    style="width: 40%"
                  >
                    <b-select v-model="exam.subtype" placeholder="Wybierz" expanded>
                      <option :value="0">W2</option>
                      <option :value="1">SIMP</option>
                      <option :value="2">Komercyjne</option>
                    </b-select>
                  </b-field>
                  <b-field>
                    <b-field v-if="exam_index + 1 === cytologyExaminations.length" class="mr-1">
                      <b-tooltip label="Dodaj badanie" type="is-primary" position="is-left">
                        <b-button @click="addCytologyExamination">
                          <b-icon
                            type="is-primary"
                            icon="plus-circle"
                            style="vertical-align: middle"
                          ></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                    <b-field>
                      <b-tooltip label="Usuń badanie" type="is-danger" position="is-left">
                        <b-button
                          @click="removeCytologyExamination(exam)"
                          :disabled="cytologyExaminations.length === 1"
                        >
                          <b-icon
                            type="is-danger"
                            icon="close-circle-outline"
                            style="vertical-align: middle"
                          ></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </b-field>
                </b-field>
                <hr />
              </div>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* VISIT REASON *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT REASON -->
              <!-- <b-field
                v-if="block.worktimeArea && block.worktimeArea.area != 100700"
                label="Powód wizyty (widoczny tylko dla lekarza)"
                label-position="on-border"
              >
                <b-input v-model="appointment.reason"></b-input>
              </b-field>-->

              <!--------------------------------------------------------->
              <!--******************* VISIT TIME *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT TIME -->
              <b-field
                grouped
                label-position="on-border"
                :label="appointment.isTimeless || timelessOnlyMode ? 'Godzina ORIENTACYJNA' : 'Godzina wizyty'"
              >
                <b-field>
                  <p class="control">
                    <b-button type="is-static">Rozpoczęcie</b-button>
                  </p>
                  <p class="control">
                    <vue-timepicker
                      v-model="timeObject"
                      :minute-interval="5"
                      hide-disabled-items
                      hide-clear-button
                      :hour-range="enabledHours"
                      :minute-range="enabledMinutes"
                      :key="trigger"
                    ></vue-timepicker>
                  </p>

                  <!-- VISIT TIMELESS -->
                  <!--------------------------------------------------------->
                  <p class="control">
                    <b-checkbox-button
                      :native-value="true"
                      type="is-primary"
                      :disabled="timelessOnlyMode || timeOnlyMode"
                      v-model="appointment.isTimeless"
                    >
                      <span v-if="timelessOnlyMode" class="has-text-light">
                        <b>Bez godziny</b>
                      </span>
                      <span v-else>Bez godziny</span>
                    </b-checkbox-button>
                  </p>
                  <!--------------------------------------------------------->
                  <!--------------------------------------------------------->
                </b-field>

                <!-- VISIT IS URGENT -->
                <!--------------------------------------------------------->
                <b-button
                  type="is-danger"
                  expanded
                  v-if="appointment.isUrgent"
                  @click="appointment.isUrgent = false"
                  icon-left="clock-alert-outline"
                >Wizyta pilna</b-button>
                <b-button
                  v-else
                  expanded
                  @click="appointment.isUrgent = true"
                  icon-left="clock-alert-outline"
                >Oznacz jako pilne</b-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* VISIT LENGTH *******************-->
              <!--------------------------------------------------------->

              <!-- VISIT LENGTH ALERT -->
              <!--------------------------------------------------------->
              <div
                v-if="
                  lengthCalculated &&
                  appointmentObject &&
                  !appointment.isTimeless &&
                  (
                    (appointmentObject.blockTime && visitLength < appointmentObject.minTime) ||
                    (visitLength < appointmentObject.time)
                  )
                "
                class="pb-3"
              >
                <b-notification
                  type="is-danger"
                  v-if="appointmentObject.blockTime && visitLength < appointmentObject.minTime"
                >
                  <span>
                    Uwaga! Wizyta nie może trwać krócej niż
                    {{ appointmentObject.minTime }} minut.
                  </span>
                </b-notification>
                <b-notification type="is-warning" v-else-if="visitLength < appointmentObject.time">
                  <span>
                    Uwaga! Czas wizyty jest niższy niż sugerowane
                    {{ appointmentObject.time }} minut.
                  </span>
                </b-notification>
              </div>

              <!-- VISIT LENGTH INPUT -->
              <!--------------------------------------------------------->
              <b-field
                grouped
                label="Czas wizyty"
                label-position="on-border"
                v-if="!timelessOnlyMode"
              >
                <b-field class="mr-0">
                  <b-numberinput
                    :disabled="appointment.isTimeless"
                    :controls="!appointment.isTimeless"
                    :editable="false"
                    v-model="visitLength"
                    min="5"
                    :max="maxDuration"
                    step="5"
                    type="is-xxx"
                    controls-position="compact"
                    controls-alignment="right"
                  ></b-numberinput>
                </b-field>
                <b-button
                  class="ml-0"
                  type="is-text"
                  @click="showMaxTimeAlert"
                  style="text-decoration: none !important"
                  v-if="appointmentObject"
                >
                  od {{ appointmentObject.minTime }} do
                  {{ maxDuration }} minut
                </b-button>

                <!--------------------------------------------------------->
                <!--******************* GROUP VISIT CHECKBOX *******************-->
                <!--------------------------------------------------------->

                <b-checkbox
                  type="is-danger"
                  class="ml-5"
                  v-if="appointmentObject && appointmentObject.isGroupAllowed"
                  v-model="appointment.isGroup"
                  :native-value="true"
                >Wizyta grupowa</b-checkbox>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* APPOINTMENT SERVICE TYPE *******************-->
              <!--------------------------------------------------------->

              <b-field
                label="Sposób wykonania"
                expanded
                v-if="appointment"
                label-position="on-border"
                class="card white-label p-3"
              >
                <!-- SERVICE TYPE -->
                <b-radio-button
                  v-for="wayItem in getAppWays(appointment.type)"
                  :key="wayItem.id"
                  v-model="appointment.service"
                  :native-value="wayItem.id"
                >
                  <b-icon :icon="wayItem.icon" class="mr-2"></b-icon>
                  {{ wayItem.name }}
                </b-radio-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* APPOINTMENT REFERRAL *******************-->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* SPECIALIST REFERRAL *******************-->
              <!--------------------------------------------------------->
              <b-field
                v-if="
                  !patientPopulationVisitTypeSelected &&
                  (!reservationMode ||
                    (reservationMode && ticketToAdd === null)) &&
                  !isRISOffice &&
                  isSpecialistOffice &&
                  !isOnlyPayerCommercial &&
                  isReferralRequired &&
                  (visitTypeSpecialist === 1 || visitTypeSpecialist === 2) &&
                  ticketToAdd === null
                "
                class="mb-4"
              >
                <ScheduleModalAddReferral
                  :patient="patient"
                  :ticketEdit="null"
                  :clinicType="block.worktimeArea.clinic"
                  :officeId="block.worktimeArea.officeId"
                  @created="referralGeneratedTicket"
                  @awaiting-create="referralPendingTickets"
                  :appointmentType="appointmentObject"
                  :appointmentPayer="appointment.payerType"
                  :isSpecialistContinutation="visitTypeSpecialist === 2"
                ></ScheduleModalAddReferral>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* RIS REFERRAL *******************-->
              <!--------------------------------------------------------->

              <!-- RIS -->
              <b-field
                v-if="
                  !patientPopulationVisitTypeSelected &&
                  (!reservationMode ||
                    (reservationMode && !block.appointment.ticketInfo)) &&
                  isRISOffice &&
                  block.worktimeArea &&
                  block.worktimeArea.clinic
                "
              >
                <RISAddExamination
                  :disabled="insufficientPatientData"
                  :ticketEdit="ticketRiS"
                  :clinicType="block.worktimeArea.clinic"
                  :officeId="block.worktimeArea.officeId"
                  @created="risGeneratedTicket"
                  @awaiting-create="risPendingTickets"
                  :deviceType="
                    appointmentObject &&
                    appointmentObject.isDeviceRequired == true
                      ? appointmentObject.defaultDeviceType
                      : -1
                  "
                  :appointmentType="appointmentObject"
                  :appointmentPayer="appointment.payerType"
                  :officeType="thisOfficeType"
                  :patient="patient"
                ></RISAddExamination>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* RIS DENS EXAMS *******************-->
              <!--------------------------------------------------------->

              <b-field
                v-if="
                  !patientPopulationVisitTypeSelected &&
                  (!reservationMode ||
                    (reservationMode && !block.appointment.ticketInfo)) &&
                  isRISOffice &&
                  isRISDens &&
                  appointment.payerType == 0
                "
              >
                <RISAddDensExamination
                  :ticketEdit="ticketRiS"
                  :clinicType="block.worktimeArea.clinic"
                  :officeId="block.worktimeArea.officeId"
                  @created="risGeneratedTicket"
                  @awaiting-create="risPendingTickets"
                  :deviceType="
                    appointmentObject &&
                    appointmentObject.isDeviceRequired == true
                      ? appointmentObject.defaultDeviceType
                      : -1
                  "
                  :appointmentType="appointmentObject"
                  :appointmentPayer="appointment.payerType"
                  :officeType="thisOfficeType"
                  :patient="patient"
                ></RISAddDensExamination>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* DEVICE CHOICE - NOT RIS OFFICE *******************-->
              <!--------------------------------------------------------->

              <!-- DEVICE SELECT -->
              <b-field
                v-if="
                  !isRISOffice &&
                  risTickets.length < 1 &&
                  appointment.isDeviceRequired
                "
                expanded
                label="Urządzenie"
                label-position="on-border"
              >
                <b-select expanded v-model="device">
                  <option v-for="item in devices" :key="item.id" :value="item.id">
                    {{ item.name }}
                    ({{ item.shortName }}),
                    {{ getFacility(item.facility) }}
                  </option>
                </b-select>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* RIS TICKETS *******************-->
              <!--------------------------------------------------------->

              <div v-if="risTickets.length > 1" class="mb-3">
                <p class="has-text-danger has-text-weight-bold">
                  <b-tag type="is-danger" class="mr-1">UWAGA</b-tag>
                  Zostaną umówione
                  {{ risTickets.length }} wizyty
                </p>
              </div>
              <div v-if="risTickets.length == 1" class="mb-3">
                <p class="has-text-success has-text-weight-bold">Zostanie umówiona 1 wizyta</p>
              </div>

              <!--------------------------------------------------------->
              <!--******************* RIS TICKETS - DEVICE *******************-->
              <!--------------------------------------------------------->

              <!-- DEVICE -->
              <b-field
                expanded
                v-for="tick in risTickets"
                :key="tick.index"
                label-position="on-border"
                :label="`Urządzenie - ${tick.procedures[0].description}`"
              >
                <b-select v-if="devices && devices.length > 1" expanded v-model="tick.device">
                  <option v-for="item in devices" :key="item.id" :value="item.id">
                    {{ item.name }}
                    ({{ item.shortName }}),
                    {{ getFacility(item.facility) }}
                  </option>
                </b-select>
                <b-button v-if="devices && devices.length == 1" expanded class="is-static">
                  <span>
                    {{ devices[0].name }}
                    ({{ devices[0].shortName }}),
                    {{ getFacility(devices[0].facility) }}
                  </span>
                </b-button>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* APPOINTMENT - NOT RIS - PAYER TYPE *******************-->
              <!--------------------------------------------------------->

              <b-field
                v-if="!isRISOffice && appointment"
                grouped
                label="Płatnik wizyty"
                expanded
                label-position="on-border"
                class="card white-label p-3"
              >
                <b-radio-button
                  type="is-primary"
                  v-for="payerItem in getAppPayers(appointment.type)"
                  :key="payerItem.id"
                  :native-value="payerItem.id"
                  v-model="appointment.payerType"
                >
                  <b-icon class="mr-2" :icon="payerItem.icon"></b-icon>
                  {{ payerItem.name }}
                </b-radio-button>
                <b-checkbox
                  class="mr-2"
                  :native-value="true"
                  type="is-secondary"
                  v-if="appointment.isPrivate"
                  v-model="appointment.isPaid"
                >Opłacona</b-checkbox>
                <b-field
                  v-if="appointment.isPrivate && appointment.isPaid"
                  label="Identyfikator płatności"
                  expanded
                  label-position="on-border"
                >
                  <b-input
                    expanded
                    v-model="appointment.paymentId"
                    maxlength="128"
                    :has-counter="false"
                  ></b-input>
                </b-field>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!--------------------------------------------------------->
              <!--******************* RIS TICKETS - PAYER TYPE *******************-->
              <!--------------------------------------------------------->

              <b-field
                grouped
                v-for="tick in risTickets"
                :key="tick.index"
                :label="`Płatnik wizyty - ${tick.procedures[0].description}`"
                expanded
                label-position="on-border"
                class="card white-label pt-3 pl-2 pr-2"
              >
                <b-field class="mr-0">
                  <b-radio-button
                    class="mb-2"
                    type="is-primary"
                    v-for="payerItem in getTicketPayer(tick)"
                    :key="payerItem.id"
                    :native-value="payerItem.id"
                    v-model="tick.payerType"
                  >
                    <b-icon class="mr-2" :icon="payerItem.icon"></b-icon>
                    {{ payerItem.name }}
                  </b-radio-button>
                </b-field>
                <b-field></b-field>
                <b-checkbox-button
                  class="mb-2 mr-1"
                  :native-value="true"
                  type="is-secondary"
                  v-if="tick.isPrivate"
                  v-model="tick.isPaid"
                >Opłacona</b-checkbox-button>
                <b-field
                  class="mb-2"
                  v-if="tick.isPrivate && tick.isPaid"
                  label="Identyfikator płatności"
                  expanded
                  label-position="on-border"
                >
                  <b-input expanded v-model="tick.paymentId" maxlength="128" :has-counter="false"></b-input>
                </b-field>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->

              <!-- OPTIONS -->
              <!-- <b-field>
          <p class="control">
            <b-checkbox-button
              :native-value="false"
              type="is-success"
              v-model="appointment.isPrivate"
              @input="appointment.isPaid = false"
            >Wizyta NFZ</b-checkbox-button>
          </p>
          <p class="control">
            <b-checkbox-button
              :native-value="true"
              type="is-success"
              v-model="appointment.isPrivate"
              @input="appointment.isPaid = false"
            >Wizyta prywatna</b-checkbox-button>
          </p>
              </b-field>-->

              <!--------------------------------------------------------->
              <!--******************* APPOINTMENT DESCRIPTION *******************-->
              <!--------------------------------------------------------->

              <!-- DESCRIPTION -->
              <b-field label="Dodatkowe uwagi" label-position="on-border">
                <b-input
                  v-model="appointment.description"
                  type="textarea"
                  maxlength="1000"
                  rows="3"
                  :has-counter="false"
                  placeholder="Maksymalnie 1000 znaków"
                ></b-input>
              </b-field>
              <!--------------------------------------------------------->
              <!--------------------------------------------------------->
            </div>
          </div>

          <!--------------------------------------------------------->
          <!--------------------------------------------------------->
        </section>
        <footer class="modal-card-foot p-2">
          <b-button
            :loading="requestInProgress"
            :disabled="newAppointmentFinalizeDisabled"
            type="is-secondary"
            @click="sendSchedule(false)"
            icon-left="calendar-check"
          >Umów wizytę</b-button>
          <b-button type="is-danger" @click="closeAddAppointment()" icon-left="close">Anuluj</b-button>
          <b-button
            :loading="requestInProgress"
            :disabled="reservationFinalizeDisabled"
            @click="sendSchedule(true)"
            icon-left="calendar-clock"
            class="is-pulled-right"
          >Zapisz jako wstępną rezerwację</b-button>
        </footer>
      </div>

      <AppointmentCancelReferralP1
        @eReferral-canceled="closeModal"
        :trigger="triggerCancelReferral"
        :ticket="ticketHasEReferral"
        :patientProp="patient"
      />

      <b-modal
        :active.sync="visitTypeSpecialistHistoryModalActive"
        :destroy-on-hide="true"
        scroll="keep"
        has-modal-card
        full-screen
        :can-cancel="false"
        style="z-index: 100"
      >
        <div class="modal-card has-background-kin" style="overflow: visible">
          <header class="modal-card-head has-background-kin">
            <p class="subtitle has-text-primelight">Historia biletów pacjenta dla poradni</p>
          </header>

          <section class="modal-card-body">
            <PatientTicketsClinicSchedule
              :visits="visitTypeSpecialistPatientHistory"
              :tickets="visitTypeSpecialistPatientTickets"
              :patient="patient"
              @add-ticket="addTicketClinics"
            />
          </section>
          <footer class="modal-card-foot buttons is-right">
            <b-button type="button" @click="visitTypeSpecialistHistoryModalActive = false">Zamknij</b-button>
          </footer>
        </div>
      </b-modal>
    </ContentPanel>

    <b-modal v-model="referralOpen" :destroy-on-hide="false">
      <ContentPanel title="E-skierowanie" icon="magnify" :paddingless="true" :marginless="true">
        <section class="modal-card-body">
          <iframe v-if="referralView" :srcdoc="referralView" style="width:100%;height:24rem;"></iframe>
        </section>
        <footer class="modal-card-foot buttons is-right">
          <div>
            <b-button
              icon-left="close"
              size="is-small"
              @click="referralOpen = false; referralView = null;"
            >Zamknij</b-button>
          </div>
        </footer>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons"
import DateHelpers from "@/mixins/date_helpers"
import AppMixin from "@/mixins/appointments"
import PersonnelMixin from "@/mixins/personnel"
import ClinicsMixins from "@/mixins/clinics"
import moment from "moment"
import { Action } from "@/store/config_actions"
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"
import { Mutation } from "@/store/config_mutations"
import AppointmentMixins from "@/mixins/appointments"
import OrdersMixins from "@/mixins/orders/poz_orders"
import RisMixins from "@/mixins/ris"
import NfzContracts from "@/mixins/finance/nfzContracts";
import MobiMixins from "@/mixins/mobi"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import IkzRegistrationMixins from '@/mixins/finance/ikz_registration'
import ValidatorMixins from "@/mixins/dataValidators"
import PatientMixins from "@/mixins/patient/patient"
import EwusMixin from '@/mixins/ewus'
import RegistrationMixins from "@/mixins/registration/registration"

import RISAddExamination from "@/components/ris/ris-workshops/RISWorkshopAddVisitScheduleVer2"
import RISAddDensExamination from "@/components/ris/ris-workshops/RISWorkshopAddVisitScheduleExaminations"
import ScheduleModalAddReferral from "../schedule/ScheduleModalAddReferral"
import ScheduleModalAddReferralCollectionPoint from "../schedule/ScheduleModalAddReferralCollectionPoint"
import AppointmentCancelReferralP1 from "@/components/appointments/AppointmentCancelReferralP1"
import PatientTicketsClinicSchedule from "@/components/appointments/PatientTicketsClinicSchedule"

import SchedulePatientChangeOrder from '@/components/ris/ris-workshops/patients/SchedulePatientChangeOrderVer2'
import SchedulePatientChangeIKZ from '@/components/ris/ris-workshops/patients/SchedulePatientChangeIKZ'
import RISPatientChangeAPKolce from '@/components/ris/ris-workshops/patients/RISPatientChangeAPKolce'
import RISPatientChangeAPKolceReferral from '@/components/ris/ris-workshops/patients/RISPatientChangeAPKolceReferral'
import RISPatientChangeDILO from '@/components/ris/ris-workshops/patients/RISPatientChangeDILO'

import OrdersDetails from "@/components/POZ/appointment/POZAppointmentSummaryComponents/Orders/OrdersDetails"
import MyAccountApKolceButton from "@/components/my-account/MyAccountApKolceButton"

export default {
  name: "Schedule2NewApp",
  mixins: [
    CommonMixins,
    DateHelpers,
    AppMixin,
    PersonnelMixin,
    ClinicsMixins,
    NfzContracts,
    AppointmentMixins,
    OrdersMixins,
    RisMixins,
    MobiMixins,
    IkzRegistrationMixins,
    ValidatorMixins,
    PatientMixins,
    EwusMixin,
    RegistrationMixins,
  ],

  components: {
    VueTimepicker,
    RISAddExamination,
    RISAddDensExamination,
    ScheduleModalAddReferral,
    ScheduleModalAddReferralCollectionPoint,
    ValidationProvider, ValidationObserver,
    AppointmentCancelReferralP1,
    //  ContentPanel,
    SchedulePatientChangeOrder,
    SchedulePatientChangeIKZ,
    RISPatientChangeAPKolce,
    RISPatientChangeAPKolceReferral,
    RISPatientChangeDILO,

    PatientTicketsClinicSchedule,
    OrdersDetails,
    MyAccountApKolceButton
  },

  props: {
    day: { type: Object, required: false },
    date: { type: Object, required: true },
    block: { type: Object, required: true },
    focusReservation: { type: Object, required: false },
    patient: { type: Object, required: false },
    rescheduleMode: { type: Boolean, required: false },
    timelessOnlyMode: { type: Boolean, required: false },
    timeOnlyMode: { type: Boolean, required: false },
    boundlessBlock: { type: Object, required: false },
    filters: { type: Object, required: false },
  },

  data: function () {
    return {

      /*
        APPOINTMENT DATA FIELDS
        * appointment - !!! IMPORTANT !!! - new appointment object
        * appointmentObject - !!! IMPORTANT !!! - config appointment object
        * appointmentObjectTmp - !!! IMPORTANT !!! - config appointment id
        * subcategories - !!! IMPORTANT !!! - array of subtypes for chosen appoointment type
        *
        * appStartDate - !!! IMPORTANT !!! - helper prop for setting appointment.start, appointment.stop props
        *
        * minTime - unused, obsolete?
        * maxTime - unused, obsolete?
        * unselectable - array needed to filter non modulo 5 minutes (i.e. 09:17, 09:18) in hour for timepicker
        *
        * visitLength - !!! IMPORTANT !!! - visit length input prop
        *
        * requestInProgress - loading prop for visit schedule / reschedule / ticket create / ticket set reserved methods
        * filteredAppTypes - !!! IMPORTANT !!! - filtered possible appointment types - set in  - myAppointmentTypes - method,
        *
        * timeObject - !!! IMPORTANT !!! - input object prop for visit hour / minutes time
        * enabledHours - possible to select hours for visit start time, filtered by worktime area block start / end times
        * enabledMinutes - possible to select minutes for visit start time, filtered by worktime area block start / end times
        *
        * minMoment - block start time wrapped in moment object
        * maxMoment - block end time wrapped in moment object
        * maxMomentOption - helper prop for unselectable dates in visit time
        *
        * trigger - trigger for ???
        * maxDuration - helper prop for checking max possible visit length compared to block end
        * showAllAppTypes - helper prop for showing employee (not office) appointment types
        * focusOffice - office prop set if filters prop and filters.office are present ????
        *
        * employee - employee object for worktime area employee
        * ^ Should be changed to employee object being selected from store, not from getEmployee method?
        *
        * lengthCalculated - helper prop used to signal that visitLength prop was changed after either input change or appointment type change

      */
      appointment: null,
      nfzService: null,
      appointmentObject: null,
      currentOffice: null,
      appointmentObjectTmp: -1,
      subcategories: null,
      appStartDate: moment(this.block.start).toDate(),
      minTime: moment(this.block.start).toDate(),
      maxTime: moment(this.block.end).subtract(5, "minutes").toDate(),
      unselectable: [],
      visitLength: 5,
      requestInProgress: false,
      filteredAppTypes: [],
      timeObject: { HH: "12", mm: "00" },
      enabledHours: [],
      enabledMinutes: [],
      contracts: [],
      contract: null,
      minMoment: moment(this.block.start),
      maxMoment: moment(this.block.end),
      maxMomentOption: moment(this.block.end).subtract(3, "minutes"),
      trigger: 0,
      maxDuration: 5,
      filter: "",
      showAllAppTypes: false,
      employee: null,
      focusOffice: null,
      lengthCalculated: false,
      // ---------------------------------------------------------
      // ---------------------------------------------------------



      /*
        APPOINTMENT DATA DEVICES FIELDS
        * devices - devices data downloaded for worktime area
        * device  - selected device object
        * areaDevice - worktime area devices
      */
      devices: [],
      device: null,
      areaDevice: null,
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      /*
        RESCHEDULE FIELDS
        * service - service type select prop for reschedule
        * subjectDownloaded - OBSOLETE PROP for employee
      */
      service: null,
      subjectDownloaded: null,// JSON.parse(JSON.stringify(this.subject)),
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      /*
        CANCEL REFERRAL FIELDS
      */
      triggerCancelReferral: 0,
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      /*
        PATIENT IDENTIFICATION FIELDS
      */
      identificationInfo: [],
      identificationInfoReversed: [],
      loadingPatientIdentification: false,
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      //
      /*
        CYTOLOGY FIELDS
      */
      cytoExaminationType: null,
      cytoExaminationSubtype: null,

      newCytologyExamination: {
        type: null,
        subtype: null,
      },

      cytologyExaminations: [
        {
          type: null,
          subtype: null,
        },
      ],
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      /*
        SPECIALIST REFERRAL FIELDS
      */
      ticketReferral: null,
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      /*
        SELECTED TICKET FIELDS - SPECIALIST / COLLECTION POINT
      */
      ticketToAdd: null,

      /*
        ONLY COLLECTION POINT FIELDS
      */
      ticketsCollectionPointType: 1,
      ticketsCollectionPointSelected: [],
      ticketsPassCollectionPoint: [],
      ticketsPassCollectionPointSelected: [],
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      ticketsNurseType: 1,
      ticketsPassNurse: [],
      ticketsPassNurseSelected: null,

      /*
        RIS REFERRAL FIELDS
      */
      ticketRiS: null,
      risTickets: [],
      // ---------------------------------------------------------
      // ---------------------------------------------------------


      /*
        VEHICLE FIELDS
      */
      vehicleReservation: {
        area: -1,
        type: -1,
        destinationClinic: -1,
        internalClinic: true,
        destinationText: "",
        departure: this.block.worktimeArea ? new Date(this.block.worktimeArea.start) : moment().toDate(),
        arrival: this.block.worktimeArea ? new Date(this.block.worktimeArea.start) : moment().toDate(),
        peopleNumber: 0,
        driver: -1,
        isAdditionalSpace: false,
        additionalSpace: "",
        notes: ""
      },
      vehicleArrivalTime: null,
      vehicleDepartureTime: null,
      facilityFilter: "",
      employeeFilter: '',
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      /*
        VISIT SPECIALIST TYPE FIELDS
        * visitTypeSpecialist - type select prop
        * visitTypeSpecialistPatientHistory - array for history for specialist clinic
        * visitTypeSpecialistPatientTickets - tickets for visitTypeSpecialistPatientHistory prop visits
        * visitTypeSpecialistHistoryModalActive - modal prop for viewing visitTypeSpecialistPatientHistory visits details
      */
      visitTypeSpecialist: null,
      visitTypeSpecialistPatientHistory: [],
      visitTypeSpecialistPatientTickets: [],
      visitTypeSpecialistHistoryModalActive: false,
      // ---------------------------------------------------------
      // ---------------------------------------------------------

      patientPopulationPrograms: [],

      limitingMode: false,
      allowLimiting: false,
      nfzLoading: false,

      referralOpen: false,
      referralView: null,
    }
  },

  watch: {
    patient() {
      if (this.patient) {
        this.getIdentificationInfo(this.patient.id)
        this.patient.age = moment().diff(moment(this.patient.dateOfBirth), "years");
      }
    },

    // appointmentObject() {
    //   if (this.appointment && this.appointmentObject) {
    //     this.appointment.type = this.appointmentObject.id
    //     this.appointment.subtype = this.subcategories && this.subcategories.length > 0 ? this.subcategories[0].id : -1
    //     this.maxDuration = this.getMaxDuration()
    //   }
    // },

    appStartDate() {
      this.appointment.start = moment(this.appStartDate).format(
        "YYYY-MM-DDTHH:mm:00"
      )
      if (this.visitLength > this.maxDuration) {
        this.visitLength = this.maxDuration
      }
      this.appointment.stop = moment(this.appStartDate)
        .add(this.visitLength, "minutes")
        .format("YYYY-MM-DDTHH:mm:00")
      this.maxDuration = this.getMaxDuration()
    },

    visitLength(val) {
      this.appointment.stop = moment(this.appStartDate)
        .add(val, "minutes")
        .format("YYYY-MM-DDTHH:mm:00")
      this.maxDuration = this.getMaxDuration()
      this.lengthCalculated = true
    },

    "timeObject.HH": function () {
      this.updateTimesInControl()
    },

    "timeObject.mm": function () {
      this.setupStartDate()
    },

    showAllAppTypes() {
      this.myAppointmentTypes()
    },

    "appointment.payerType": function () {
      if (this.appointment.payerType === 0) {
        this.appointment.isPrivate = true
      } else {
        this.appointment.isPrivate = false
        this.appointment.isPaid = false
        this.appointment.paymentId = null
      }

      /**
       * IMPORTANT!!!!
       * AP-KOLCE PROP CHECK FOR SPECIALIST OFFICES WITH CLINIC TYPES SET TO AP-KOLCE REPORT
       * CHECK AFTER PAYER TYPE IS CHANGED - IF PAYER TYPE - 1 - NFZ SET TRUE, ELSE FALSE
       */
      if (this.isSpecialistOffice && this.isClinicApkolce) {
        if (this.appointment.payerType === 1) {
          this.appointmentObject.apkolce = true
        } else {
          this.appointmentObject.apkolce = false
        }
      }
    },

    "appointment.service": function () {
      this.reselctNfzService()
    },

    visitTypeSpecialist() {
      this.ticketToAdd = null
      this.ticketReferral = null
    },

    ticketsCollectionPointType() {
      this.ticketsCollectionPointSelected = []
      this.ticketsPassCollectionPointSelected = []
    },

    ticketsNurseType() {
      this.ticketToAdd = null
      this.ticketsPassNurseSelected = null
    }
  },

  async mounted() {

    this.declarationsText = this.getDeklPersonel()

    if (this.block && this.block.worktimeArea) {
      this.currentOffice = this.getOfficeObject(this.block.worktimeArea.officeId)
    }

    if (this.registrationNfzService && this.block && this.block.worktimeArea && this.block.worktimeArea.officeId && !this.isRISOffice) {
      this.getData(this.block.worktimeArea.officeId)
    }

    if (this.patient) {
      this.patient.age = moment().diff(moment(this.patient.dateOfBirth), "years");
    }

    if (this.typesImage && this.typesImage.length > 0) {
      //ok, we have image types
    } else {
      this.getImageTypes()
    }

    if (this.patient) {
      this.getIdentificationInfo(this.patient.id)
    }

    if (this.focusReservation && this.focusReservation.appointment) {
      this.appointment = JSON.parse(JSON.stringify(this.focusReservation.appointment))

      // WARNING !!!
      // BETTER CHECK NEEDED FOR SPECIALIST APPOINTMENT
      // SB
      if (this.appointment.ticketInfo) {
        if (this.isRISOffice) {
          this.risTickets = []
          this.risTickets.push(this.appointment.ticketInfo)
          this.ticketRiS = this.appointment.ticketInfo
        }
        // TICKET FOR OSTEO POPULATION PROGRAM
        else if (this.patientPopulationVisitTypeSelected) {
          this.ticketToAdd = this.appointment.ticketInfo
        }
        else if (this.isSpecialistOffice) {
          this.ticketToAdd = this.appointment.ticketInfo
        }
        else if (this.isCollectionPointOffice) {
          if (this.focusReservation.appointment.type === 94) {
            this.ticketToAdd = this.appointment.ticketInfo
          } else {
            this.ticketsCollectionPointSelected = [this.appointment.ticketInfo]
          }
        }
        else if (this.isNurseTreatmentsOffice) {
          let ticket = this.appointment.ticketInfo
          ticket.orderType = this.getOrderTypeId(ticket.ticketType, ticket.ticketSubType)
          ticket.orderTypeName = this.getOrderType(ticket.orderType)

          if (ticket.orderType === 0) {
            ticket.treatmentType = this.getTreatmentTypeId(ticket.ticketSubType)
            ticket.treatmentTypeName = this.getTreatmentType(ticket.treatmentType)
          } else {
            ticket.treatmentType = -1
            ticket.treatmentTypeName = "-"
          }

          this.ticketToAdd = ticket
        }
      }
      // ^ WARNING !!!

      this.recalculateExistingAppointmentLength(this.focusReservation.appointment)

      if (this.focusReservation && !this.checkFocusReservationPatient) {
        this.resetEwusReservation()
      }
    }
    else {
      this.appointment = this.reset()

      this.appointmentObject = this.configApps[0]
      this.appointmentObjectTmp = this.appointmentObject.id
    }

    this.defUnselectable()

    if (this.block.worktimeArea && this.block.worktimeArea.employee > -1) {
      await this.getEmployee(this.block.worktimeArea.employee)
      this.myAppointmentTypes()
    } else if (this.filters && this.filters.employee > -1) {
      await this.getEmployee(this.filters.employee)
      this.myAppointmentTypes()
    }

    let startMoment = moment(this.block.start)

    this.timeObject = {
      HH: startMoment.format("HH"),
      mm: startMoment.format("mm"),
    }

    this.updateTimesInControl()

    this.maxDuration = this.getMaxDuration()
    this.myAppointmentTypes()

    /**
     * Ok, future me 100% hates this, but present me is content
     * 
     * 
     * Problem: appointment type / subtype / payerType props didn't set properly for reservetion to appointment modal data
     * - earlier we set state props 'appointmentObject', 'appointmentObjectTmp' BEFORE myAppointmentTypes() function ran
     * - this resulted in these being overwritten
     * 
     * Solution: 
     * - set props after myAppointmentTypes() runs, while having copy of reservation object earlier
     */
    if (this.focusReservation && this.focusReservation.appointment) {
      this.appointmentObject = this.configApps.find(x => x.id == this.focusReservation.appointment.type)
      this.appointmentObjectTmp = this.appointmentObject.id
      this.appointment.type = this.appointmentObject.id
      if (this.appointmentObject.subcategories) {
        this.subcategories = this.appointmentObject.subcategories.sort(this.sortByOrder)
        if (this.focusReservation.appointment.subtype > -1) {
          this.appointment.subtype = this.focusReservation.appointment.subtype
          let subcategoryObject = this.subcategories.find(x => x.id === this.focusReservation.appointment.subtype)
          if (subcategoryObject) {
            this.appointmentObject = subcategoryObject
          }
        }
      }
      this.appointment.payerType = this.focusReservation.appointment.payerType
      this.appointment.isPaid = this.focusReservation.appointment.isPaid
      this.appointment.paymentId = this.focusReservation.appointment.paymentId
      this.appointment.service = this.focusReservation.appointment.service
      this.appointment.isApkolce = this.focusReservation.appointment.apkolce

      this.maxDuration = this.getMaxDuration()
      this.lengthCalculated = true
    }
    /**
     * *********************************************************************
     * *********************************************************************
     */

    if (this.rescheduleMode && this.focusAppointment) {
      this.appointmentObject = this.configApps.find(x => x.id == this.focusAppointment.type)
      if (this.appointmentObject.subcategories) {
        this.subcategories = this.appointmentObject.subcategories.sort(this.sortByOrder)
        if (this.focusAppointment.subtype > -1) {
          let subcategoryObject = this.subcategories.find(x => x.id === this.focusAppointment.subtype)
          if (subcategoryObject) {
            this.appointmentObject = subcategoryObject
          }
        }
      }
      this.service = this.focusAppointment.service
      this.recalculateExistingAppointmentLength(this.focusAppointment)
    }

    if (this.block.worktimeArea && this.block.worktimeArea.devices) {
      this.block.worktimeArea.devices.forEach((element) => {
        this.getDevice(element)
      })
    } else if (this.block.worktimeArea && this.block.worktimeArea.device) {
      this.getDevice(this.block.worktimeArea.device)
    }

    if (this.timelessOnlyMode) {
      this.appointment.isTimeless = true
    }

    if (this.isSpecialistOffice && this.block.worktimeArea && this.block.worktimeArea.clinic) {
      this.getSpecialistAppointmentsPatient(this.block.worktimeArea.clinic)
    }

    this.patientPopulationProgramsMethod()

    if (this.isCollectionPointOffice || this.isNurseTreatmentsOffice) {
      this.getPatientTicketPass()
    }

    // if(this.appointmentObject){
    // this.limitingMode = this.limitingRole && this.config && this.config.services && this.config.services.length > 0
    // this.allowLimiting = this.limitingRole && this.limitingMode
    // }
  },

  computed: {

    registrationPatient() {
      return this.$store.state.registration.patient
    },

    declarations() {
      return this.$store.state.patients.declarations
    },

    is40Plus() {
      if (this.appointment) {
        return this.appointment.type === 94
      }

      return false
    },

    //positions
      myPositions() {
        return this.$store.state.employeePosition.myPositions ?? []
      },

      isCheckApkolceNotRequired() {
        if (this.myPositions && this.myPositions.length > 0) {
          let found = this.myPositions.find(p => p.isActive && p.typeObject != null && p.typeObject.name != null
            && (p.typeObject.name.toLowerCase() == "technik pracowni diagnostycznej" ))
          if (found) {
            return true
          }
        }

        return false;
      },
    //

    //apkolce settings
    resultConfigStore() {
      return this.$store.state.apkolce.resultConfig;
    },
    isApkolceError() {
      return this.$store.state.apkolce.isApkolceError;
    },
    isApkolceWarning() {
      return this.$store.state.apkolce.isApkolceWarning;
    },
    apkolceLoginError() {
      return this.$store.state.apkolce.apkolceLoginError;
    },
    isCheckPassword() {
      return this.$store.state.apkolce.isCheckPassword;
    },

    canApkolceAddedVisit(){

      if(this.isCheckApkolceNotRequired){
        return true
      }

      if(this.resultConfigStore != null){
        if(this.resultConfigStore.isGood){
          
          if(!this.isCheckPassword && !this.isApkolceError)
          {
            return true
          }

          if(this.isCheckPassword){
            return false
          }

          if(this.isApkolceError && this.apkolceLoginError!= null){
            return false
          }
          
        }
      }

      return false
    },
    //end apkolce

    canShorterAppointment() {
      return this.perms.shorterAppointments
    },

    minDurationReschedule() {
      let minDuration = 5;
      if (this.appointmentObject) {
        if (!this.canShorterAppointment && this.appointmentObject.minTime !== null && this.appointmentObject.minTime !== undefined && this.appointmentObject.blockTime) {
          minDuration = this.appointmentObject.minTime
        }
      }
      return minDuration;
    },

    minDurationRescheduleInfo() {
      let minDuration = 5;
      if (this.appointmentObject) {
        if (this.appointmentObject.minTime !== null && this.appointmentObject.minTime !== undefined && (this.appointmentObject.blockTime === false || this.canShorterAppointment)) {
          minDuration = this.appointmentObject.minTime
        }
      }
      return minDuration;
    },

    hasPozAppToday() {
      if (this.patientVisits) {
        let existing = this.patientVisits.find(x => x.payerType === 1 && x.state != 6 && x.state != 8 && x.state != 100 && this.mDate(x.start) == this.mDate(this.appStartDate))

        if (existing) {
          return true
        }
      }

      return false
    },

    patientVisits() {
      return this.$store.state.registration.patientVisits
    },

    registrationNfzService() {
      return this.perms.registrationNfzService;
    },

    limitingRole() {
      return this.perms.serviceLimiting;
    },

    perms() { return this.$store.state.employee.permits },

    medicalServices() {
      return this.$store.state.poz.medicalServices
    },

    umxServices() {
      return this.$store.state.finance.nfzServices ?? []
    },

    translatedServices() {
      if (this.contract && this.contract.services) {
        let umxServicesTranslated = []

        this.contract.services.forEach((umxServ) => {

          if (this.umxServices) {
            umxServ.medicalService = this.medicalServices.find(medServ => medServ.bum === umxServ.service.code)
          }

          if (this.umxServices) {
            umxServ.config = this.umxServices.find(x => umxServ.service && umxServ.service.code == x.code)
          }

          if (this.config && this.config.services && this.config.services.length > 0) {
            if (this.limitingMode) {
              if (this.config.services.includes(umxServ.item.id)) {
                umxServ.display = `${umxServ.service.code} - ${umxServ.item.name}`
                umxServicesTranslated.push(umxServ)
              }
              else {
                // no pushin!
              }
            }
            else {
              umxServ.display = `${umxServ.service.code} - ${umxServ.item.name}`
              umxServicesTranslated.push(umxServ)
            }
          }
          else {
            umxServ.display = `${umxServ.service.code} - ${umxServ.item.name}`
            umxServicesTranslated.push(umxServ)
          }
        })

        return umxServicesTranslated
      }
      else return []
    },

    filteredServices() {
      if (this.translatedServices) {
        if (this.filter) {
          return this.translatedServices.filter((option) => {
            return (option.item.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) || (option.service.code.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0);
          })
        }
        else return this.translatedServices
      }
      else return []
    },


    ageFrom() {
      if (this.appointmentObject) {
        return this.appointmentObject.ageFrom
      }
      else {
        return 0
      }
    },

    ageTo() {
      if (this.appointmentObject) {
        return this.appointmentObject.ageTo
      }
      else {
        return 999
      }
    },

    ageRestriction() {
      if (this.patient && this.appointmentObject) {
        if (this.appointmentObject.hasAgeRestriction && (this.patient.age < this.ageFrom || this.patient.age > this.ageTo)) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    },

    reservationFinalizeDisabled() {

      var shouldBeDisabled = false

      // Appointment configuration object missing.
      if (this.appointmentObject === null) {
        shouldBeDisabled = true
      }

      // Appointment age restricton.
      else if (this.ageRestriction) {
        shouldBeDisabled = true
      }

      // Office not permitting more timeless appointments AND user has no permission to override it AND patient has no privilage to skip queue.
      else if (this.timelessLimitReached && !this.ignoreTimelessLimit && !this.noQueuePrivilage) {
        shouldBeDisabled = true
      }

      // Appointment time is out of range.
      else if (this.appointmentObject != null && !this.appointment.isTimeless && this.visitLength < this.appointmentObject.minTime && this.appointmentObject.blockTime) {
        shouldBeDisabled = true
      }

      return shouldBeDisabled
    },

    /**
     * Indicates whether appointment finalize button should be disabled.
     */
    newAppointmentFinalizeDisabled() {

      var shouldBeDisabled = false

      // Patient data missing.
      if (this.insufficientPatientData) {
        shouldBeDisabled = true
      }

      // Appointment configuration object missing.
      else if (this.appointmentObject === null) {
        shouldBeDisabled = true
      }

      // Appointment age restricton.
      else if (this.ageRestriction) {
        shouldBeDisabled = true
      }

      // Office not permitting more timeless appointments AND user has no permission to override it AND patient has no privilage to skip queue.
      else if (this.timelessLimitReached && !this.ignoreTimelessLimit && !this.noQueuePrivilage) {
        shouldBeDisabled = true
      }

      // Appointment time is out of range.
      else if (this.appointmentObject != null && !this.appointment.isTimeless && this.visitLength < this.appointmentObject.minTime && this.appointmentObject.blockTime) {
        shouldBeDisabled = true
      }

      // Osteoporosis appointment without ticket.
      else if (this.patientPopulationVisitTypeSelected && this.risTickets.length < 1 && !this.ticketToAdd) {
        shouldBeDisabled = true
      }

      // RIS appointment rules.
      else if (this.isRISOffice && (this.risTickets.length < 1 || !this.risTicketsReady)) {
        shouldBeDisabled = true
      }

      // Specialist appointment rules.
      else if (!this.isRISOffice && this.isSpecialistOffice && !this.patientPopulationVisitTypeSelected && !this.isOnlyPayerCommercial) {

        // No specialist selected.
        if (this.isReferralRequired && !this.visitTypeSpecialist && (!this.reservationMode || (this.reservationMode && this.ticketToAdd === null))) {
          shouldBeDisabled = true
        }

        // Paid by NFZ but no required ticket/referral added.
        else if (this.isReferralRequired && this.visitTypeSpecialist === 1 && this.appointment.payerType !== 0 && !this.ticketReferral) {
          shouldBeDisabled = true
        }

        // Specialist type 2 and no ticket/referral.
        else if (this.visitTypeSpecialist === 2 && this.appointment.payerType !== 0 && !this.ticketToAdd && !this.ticketReferral) {
          shouldBeDisabled = true
        }
      }
      //TODO: PAULINA TUTAJ
      //ap-kolce visit and worker empty configuration
      else if(this.appointmentObject != null && this.appointmentObject.apkolce && !this.canApkolceAddedVisit){
        shouldBeDisabled = true
      }

      return shouldBeDisabled

      // return this.insufficientPatientData ||
      //   this.appointmentObject === null ||
      //   (this.timelessLimitReached && !this.ignoreTimelessLimit && !this.noQueuePrivilage) ||
      //   (this.isRISOffice && (this.risTickets.length < 1 || !this.risTicketsReady)) ||
      //   (!this.isRISOffice && this.isSpecialistOffice && !this.patientPopulationVisitTypeSelected && !this.isOnlyPayerCommercial && !this.visitTypeSpecialist) ||
      //   (!this.isRISOffice && this.isSpecialistOffice && !this.patientPopulationVisitTypeSelected && !this.isOnlyPayerCommercial && this.isReferralRequired && this.visitTypeSpecialist === 1 && this.appointment.payerType !== 0 && !this.ticketReferral) ||
      //   (!this.isRISOffice && this.isSpecialistOffice && !this.patientPopulationVisitTypeSelected && !this.isOnlyPayerCommercial && this.visitTypeSpecialist === 2 && this.appointment.payerType !== 0 && !this.ticketToAdd && !this.ticketReferral) ||
      //   (this.appointmentObject != null && !this.appointment.isTimeless && this.visitLength < this.appointmentObject.minTime && this.appointmentObject.blockTime) ||
      //   (this.patientPopulationVisitTypeSelected && this.risTickets.length < 1 && !this.ticketToAdd)
    },

    /**
     * Patient privileges.
     */
    patientPrivileges() {
      return this.$store.state.registration.privileges
    },

    /**
     * Effects of patient privileges.
     */
    patientPrivilegeEffects() {
      let effects = []

      if (this.patientPrivileges) {
        this.patientPrivileges.forEach(x => {
          if (x.effects) {
            x.effects.forEach(r => {
              if (!effects.includes(x)) {
                effects.push(r)
              }
            })
          }
        })
      }

      return effects
    },

    /**
     * Indicates whether patient can skip queue.
     */
    noQueuePrivilage() {
      return this.patientPrivilegeEffects.includes(4)
    },


    docs() { return this.$store.state.employee.all },
    elmpoyeeFiltered() {
      return this.docs.filter(option => {
        let id = (option.lastName + " " + option.firstName).toLowerCase()
        if (this.employeeFilter != null) {
          return id.indexOf(this.employeeFilter.toLowerCase()) >= 0
        }
        else return false
      })
    },


    /*
      * Filtered tickets for new appointment's area
      * Tickets taken from store
      * Filtered by:
        * clinic - worktimeArea.clinic
        * ticket start / expire date - compared to present date
    */
    tickets() {
      if (this.block.worktimeArea) {
        let dateNow = this.mDateTime(this.mNow())
        let padded = this.block.worktimeArea.clinic.toString()

        if (this.isCollectionPointOffice) {
          padded = "7110"
        }

        let ticks = this.$store.state.patients.tickets ?? []
        let filtered = ticks.filter(t => t.ticketOffice == padded && t.state === 1)
        let filteredDate = filtered.filter(t => {
          let dateTicketStart = this.mDateTime(t.startDate)
          let dateTicketExpire = this.mDateTime(t.expiryDate)
          if (dateNow <= dateTicketExpire && dateNow >= dateTicketStart) {
            return true
          } else {
            return false
          }
        })

        return filteredDate
      }
      else return []
    },

    ticketHasEReferral() {
      if (this.isRISOffice) {
        let ticketEReferral = this.risTickets.find((ticket) => {
          if (
            ticket.referral
            && ticket.referral.canceledId == null
            && ticket.referral.acceptedId != null
            && ticket.referral.eReferral
            && ticket.referral.eReferral.id != null) {
            return true
          } else {
            return false
          }
        })

        if (ticketEReferral) {
          return ticketEReferral
        } else {
          return null
        }
      } else {
        if (this.ticketReferral) {
          if (this.ticketReferral.referral
            && this.ticketReferral.referral.canceledId == null
            && this.ticketReferral.referral.acceptedId != null
            && this.ticketReferral.referral.eReferral
            && this.ticketReferral.referral.eReferral.id != null) {
            return this.ticketReferral
          } else if (
            this.ticketReferral.referralResultsObject
            && this.ticketReferral.referralResultsObject.esk
            && this.ticketReferral.referralResultsObject.esk.cancelledId == null
            && this.ticketReferral.referralResultsObject.esk.acceptedId != null
          ) {
            return this.ticketReferral
          } else {
            return null
          }
        } else {
          return null
        }
      }
    },

    typesImage() {
      return this.$store.state.results.examImageType ?? []
    },

    me() {
      return this.$store.state.employee.me
    },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility)
        return found
      } else return null
    },

    registrationIkz() {
      return this.$store.state.registration.ikz
    },

    isAdmin() {
      return this.$store.state.employee.permits.admin
    },

    reservationMode() {
      if (this.focusReservation) {
        return true
      }
      else {
        return false
      }
    },

    ignoreTimelessLimit() {
      return this.$store.state.employee.permits.ignoreTimelessLimit
    },

    isRISOffice() {
      if (this.focusOffice) {
        return this.mTypesRiSClinics.includes(this.focusOffice.clinicId)
      } else if (this.block && this.block.worktimeArea && this.block.worktimeArea.clinic) {
        return this.mTypesRiSClinics.includes(this.block.worktimeArea.clinic)
      } else return false
    },

    isRISDens() {
      if (this.thisOfficeType) {
        return this.thisOfficeType.id == 1030
      } else return false
    },

    // isRisUSG() {
    //   if (this.thisOfficeType) {
    //     return this.thisOfficeType.id == 100400
    //   } else return false
    // },

    risTicketsReady() {

      if (this.risTickets.length < 1) {
        return true
      }
      else {
        var ready = true

        this.risTickets.forEach(r => {
          if (r.device != null && r.device.length > 0) {
            // cool
          }
          else if (r.device == null && this.devices && this.devices.length == 1) {
            // ok, we add devices with index 0
          }
          else {
            ready = false
          }
        })

        return ready
      }

    },

    isReferralRequired() {
      if (this.focusOffice) {
        let clinicObject = this.getConfigClinic(this.focusOffice.clinicId)
        if (clinicObject) {
          return clinicObject.isReferralRequired
        } else return false
      } else if (this.block && this.block.worktimeArea && this.block.worktimeArea.clinic) {
        let clinicObject = this.getConfigClinic(this.block.worktimeArea.clinic)
        if (clinicObject) {
          return clinicObject.isReferralRequired
        } else return false
      } else return false
    },

    isClinicApkolce() {
      if (this.focusOffice) {
        let clinicObject = this.getConfigClinic(this.focusOffice.clinicId)
        if (clinicObject) {
          return clinicObject.isUnitApkolceReported
        } else return false
      } else if (this.block && this.block.worktimeArea && this.block.worktimeArea.clinic) {
        let clinicObject = this.getConfigClinic(this.block.worktimeArea.clinic)
        if (clinicObject) {
          return clinicObject.isUnitApkolceReported
        } else return false
      } else return false
    },

    configOffices() {
      return this.$store.state.config.offices
    },



    timelessLimitReached() {
      if (this.appointment && this.appointment.isTimeless || this.timelessOnlyMode) {
        if (this.thisOfficeType && this.thisOfficeType.hasTimelessLimit) {
          if (this.day) {
            return this.day.timeless.length >= this.thisOfficeType.maxTimeless
          }
        }
      }

      return false
    },

    thisOfficeType() {
      if (this.allOffices && this.block.worktimeArea) {
        if (this.block.worktimeArea.officeId) {
          let off = this.allOffices.find(x => x.id == this.block.worktimeArea.officeId)

          if (off) {
            let offtype = this.configOffices.find(r => r.id == off.type)
            return offtype
          }
        }
      } else if (this.allOffices && this.block.appointment && this.block.appointment.officeId) {
        let off = this.allOffices.find(x => x.id == this.block.appointment.officeId)

        if (off) {
          let offtype = this.configOffices.find(r => r.id == off.type)
          return offtype
        }
      }

      return null
    },

    allOffices() {
      return this.$store.state.offices.offices
    },

    configApps() {
      return this.$store.state.config.appointments ? this.$store.state.config.appointments.filter(x => !x.isDisabled) : []
    },

    vehicleApps() {
      return this.configApps.filter(option => option.isVehicle === true)
    },

    configAreas() {
      return this.$store.state.config.areas
    },

    clinics() {
      return this.$store.state.config.facilities
    },

    focusAppointment() {
      return this.$store.state.poz.focusAppointment
    },

    // check if focus reservation patient is identical with reservation patient
    // used to determine source of ewus object - check 'ewus' store
    checkFocusReservationPatient() {
      if (this.focusReservation?.appointment?.patient && this.registrationPatient) {
        return this.focusReservation.appointment.patient.id === this.registrationPatient.id
      }

      return false
    },

    ewus() {
      if (this.focusReservation && !this.checkFocusReservationPatient) {
        return this.$store.state.ewus.ewusReservation 
      }

      return this.$store.state.ewus.ewus
    },

    ewusGuid() {
      if (this.focusReservation && !this.checkFocusReservationPatient) {
        return this.$store.state.ewus.ewusReservationGuid 
      }

      return this.$store.state.ewus.ewusGuid
    },

    ewusPending() {
      if (this.focusReservation && !this.checkFocusReservationPatient) {
        return this.$store.state.ewus.ewusReservationCheckPending 
      }

      return this.$store.state.ewus.ewusCheckPending
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? []
    },

    facilitiesFiltered() {
      return this.facilities.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.facilityFilter.toLowerCase()) >= 0
          )
        } else return false
      })
    },

    minTimeVehicle() {
      if (this.block.worktimeArea) {
        return new Date(this.block.worktimeArea.start)
      }
      else return moment().toDate()
    },

    maxTimeVehicle() {
      if (this.block.worktimeArea) {
        return new Date(this.block.worktimeArea.stop)
      }
      else return moment().toDate()
    },

    configClinics() {
      return this.$store.state.config.clinics ?? []
    },

    isSpecialistOffice() {
      if (this.thisOfficeType) {
        return this.thisOfficeType.id == 200001
      } else return false
    },

    isCollectionPointOffice() {
      if (this.thisOfficeType) {
        return this.thisOfficeType.id == 100100
      } else return false
    },

    isNurseTreatmentsOffice() {
      if (this.block && this.block.worktimeArea) {
        if (this.block.worktimeArea.clinic == 9450) {
          return true
        }
      }

      return false
    },

    /*
      * Check for selected appointmenty type
      * If there is only one payer for selected appointment type
      * And the payer is commercial
      *
      * Used for specialist offices
    */
    isOnlyPayerCommercial() {
      if (this.appointment.type) {
        let appointmentPayers = this.getAppPayers(this.appointment.type)

        if (appointmentPayers.length === 1) {
          let payerItem = appointmentPayers[0]
          if (payerItem.id === 0) {
            return true
          }
        }
      }

      return false
    },

    insufficientPatientData() {
      if (this.insufficientPatientDataText == null) {
        return false
      } else {
        return true
      }

    },

    insufficientPatientDataText() {
      let text = null

      if (this.isRISOffice) {
        if (this.focusOffice && this.focusOffice.clinicId != "7220") {
          return null
        } else if (this.block && this.block.worktimeArea && this.block.worktimeArea.clinic && this.block.worktimeArea.clinic != "7220") {
          return null
        }
      }

      if (this.isCollectionPointOffice) {
        let findNFZTicketPayer = this.collectionPointDisplay.find(x => x.payerType === 1)
        if (findNFZTicketPayer) {
          if (this.insufficientPatientDataNFZVisit) {
            return "Braki w danych personalnych"
          }

          if (this.insufficientPatientIdentificationNFZVisit) {
            return "Brak potwierdzenia z Ewuś lub dokumentu potwierdzającego ubezpieczenie"
          }
        }

        return null
      }

      if ((this.appointment != null && this.appointment.payerType == 1)) {
        // NFZ visit
        if (this.insufficientPatientDataNFZVisit) {
          return "Braki w danych personalnych"
        }

        if (this.insufficientPatientIdentificationNFZVisit) {
          return "Brak potwierdzenia z Ewuś lub dokumentu potwierdzającego ubezpieczenie"
        }
      }

      return text
    },

    insufficientPatientDataNFZVisit() {
      if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {
        return false
      }

      return true
    },

    insufficientPatientIdentificationNFZVisit() {
      if ((this.ewus && this.mEwusStatusIsGood(this.ewus.ewusStatus)) || this.identificationInfoReversed.length > 0 && this.identificationInfoReversed[0].entitlementDocument == 12 && moment().format('YYYY-MM-DD') == moment(this.identificationInfoReversed[0].created).format('YYYY-MM-DD') || (this.identificationInfoReversed.length > 0 && (this.identificationInfoReversed[0].entitlementDocument == 16 || this.identificationInfoReversed[0].entitlementDocument == 15))) {
        return false
      }

      return true
    },

    lastIdentificationInfo() {
      if (this.identificationInfoReversed && this.identificationInfoReversed.length > 0) {
        return this.identificationInfoReversed[0]
      }

      return null
    },

    showIdentificationInfo() {
      // Do not show - ewus check pending
      if (!this.ewus && this.ewusGuid) {
        return false
      }

      // Do not show - ewus checked and status allows for NFZ visit
      if (this.ewus && this.mEwusStatusIsGood(this.ewus.ewusStatus)) {
        return false
      }

      return true
    },

    patientPopulationProgramsOsteoporosisQualified() {
      return this.patientPopulationPrograms.filter(program => program.status === 1 && program.programData?.type === 2)
    },

    patientPopulationProgramsTomographyQualified() {
      return this.patientPopulationPrograms.filter(program => program.status === 1 && program.programData?.type === 6)
    },

    patientPopulationProgramsQualified() {
      if (this.patientPopulationOsteoporosisVisitTypeSelected) {
        return this.patientPopulationProgramsOsteoporosisQualified
      } else if (this.patientPopulationTomographyVisitTypeSelected) {
        return this.patientPopulationProgramsTomographyQualified
      }

      return []
    },

    patientPopulationOsteoporosisHasQualificationVisit() {
      return this.patientPopulationProgramsOsteoporosisQualified.filter((program) => {
        if (program.events) {
          let eventQualificataion = program.events.find(event => event.type === 1 && event.note)
          if (eventQualificataion) {
            return true
          }
        }
        return false
      })
    },

    patientPopulationTomographyHasQualificationVisit() {
      return this.patientPopulationProgramsTomographyQualified.filter((program) => {
        if (program.events) {
          let eventQualificataion = program.events.find(event => event.type === 1 && event.note)
          if (eventQualificataion) {
            return true
          }
        }
        return false
      })
    },

    patientPopulationOsteoporosisVisitTypeSelected() {
      if (this.appointment.type === 6 && this.appointment.subtype === 600) {
        return true
      } else if (this.appointment.type === 91 && this.appointment.subtype === 11304) {
        return true
      } else if (this.appointment.type === 113 && this.appointment.subtype === 11304) {
        return true
      } else if (this.appointment.type === 1032) {
        return true
      } else if (this.appointment.type === 187) {
        return true
      } else {
        return false
      }
    },

    patientPopulationOsteoporosisAppointmentTicket() {
      let ticket = null

      this.patientPopulationOsteoporosisHasQualificationVisit.some((program) => {
        if (program.tickets) {
          program.tickets.forEach((ticketSearch) => {
            if (ticketSearch.ticketType === this.appointment.type
              && ticketSearch.ticketSubType === this.appointment.subtype
              && ticketSearch.state === 1
            ) {
              ticket = ticketSearch
            }
          })
        }

        if (ticket) {
          return true
        } else {
          return false
        }
      })

      return ticket
    },

    patientPopulationTomographyAppointmentTicket() {
      let ticket = null

      this.patientPopulationTomographyHasQualificationVisit.some((program) => {
        if (program.tickets) {
          program.tickets.forEach((ticketSearch) => {
            if (ticketSearch.ticketType === this.appointment.type
              && ticketSearch.ticketSubType === this.appointment.subtype
              && ticketSearch.state === 1
            ) {
              ticket = ticketSearch
            }
          })
        }

        if (ticket) {
          return true
        } else {
          return false
        }
      })

      return ticket
    },

    patientPopulationAppointmentTicket() {
      if (this.patientPopulationOsteoporosisVisitTypeSelected) {
        return this.patientPopulationOsteoporosisAppointmentTicket
      } else if (this.patientPopulationTomographyVisitTypeSelected) {
        return this.patientPopulationTomographyAppointmentTicket
      }

      return null
    },

    patientPopulationTomographyVisitTypeSelected() {
      if (this.appointment.type === 6 && this.appointment.subtype === 602) {
        return true
      } else if (this.appointment.type === 91 && this.appointment.subtype === 11306) {
        return true
      } else if (this.appointment.type === 1009) {
        return true
      } else if (this.appointment.type === 652) {
        return true
      } else {
        return false
      }
    },

    patientPopulationVisitTypeSelected() {
      if (this.patientPopulationOsteoporosisVisitTypeSelected || this.patientPopulationTomographyVisitTypeSelected) {
        return true
      }

      return false
    },

    patientPopulationHasQualificationVisit() {
      if (this.patientPopulationOsteoporosisVisitTypeSelected) {
        return this.patientPopulationOsteoporosisHasQualificationVisit
      } else if (this.patientPopulationTomographyVisitTypeSelected) {
        return this.patientPopulationTomographyHasQualificationVisit
      }

      return []
    },

    collectionPointArray() {
      if (this.ticketsCollectionPointType === 1) {
        return this.ticketsCollectionPointSelected
      } else if (this.ticketsCollectionPointType === 2) {
        let tickets = []
        this.ticketsPassCollectionPointSelected.forEach(ticketPass => {
          ticketPass.tickets.forEach(ticket => tickets.push(ticket))
        })
        return tickets
      }

      return []
    },

    collectionPointDurationSum() {
      return this.collectionPointArray.length * this.visitLength
    },

    collectionPointDisplay() {
      let displayTickets = []

      this.collectionPointArray.forEach((ticket, index) => {
        let ticketDisplay = {
          appointmentStart: null,
          appointmentStop: null,
          payerType: ticket.payerType,
          payerTypeName: this.getPayerTypeName(ticket.payerType),
          payerTypeIcon: this.getPayerTypeIcon(ticket.payerType)
        }

        if (index === 0) {
          ticketDisplay.appointmentStart = moment(this.appStartDate).format("YYYY-MM-DDTHH:mm:00")
          ticketDisplay.appointmentStop = moment(this.appStartDate).add(this.visitLength, "minutes").format("YYYY-MM-DDTHH:mm:00")
        }

        if (index > 0) {
          const prev = displayTickets[index - 1]
          ticketDisplay.appointmentStart = prev.appointmentStop
          ticketDisplay.appointmentStop = moment(prev.appointmentStop).add(this.visitLength, "minutes").format("YYYY-MM-DDTHH:mm:00")
        }

        displayTickets.push(ticketDisplay)
      })

      return displayTickets
    },

    phoneNumberWarning() {
      if (this.patient && this.patient.teleAddressTelephoneNumber) {
        let fragment = this.patient.teleAddressTelephoneNumber.slice(0, 3)
        if (fragment == '800' || fragment == '801') {
          return true
        }

        if (this.patient.teleAddressTelephoneNumber.length !== 9) {
          return true
        }
      }

      return false
    },

    phoneNumberPrefixWarning() {

      if (this.patient && this.patient.teleAddressTelephoneNumber && this.patient.teleAddressTelephonePrefix) {
        if (this.patient.teleAddressTelephoneNumber.length > 9) {
          let fragment = this.patient.teleAddressTelephoneNumber.slice(0, 2)
          if (fragment == '48' && (this.patient.teleAddressTelephonePrefix == "+48" || this.patient.teleAddressTelephonePrefix == "48")) {
            return true
          }
        }
      }

      return false
    },
  },

  methods: {

    getDeklPersonel() {
      let html = ''

      if (this.patient && this.declarations) {
        let activeAll = this.declarations.filter(x => x.isActive && x.patientId == this.patient.id)

        let dL = null
        let dP = null
        let dO = null

        activeAll.forEach(x => {
          if (x.declarationType == 0) dL = x
          else if (x.declarationType == 1) dP = x
          else if (x.declarationType == 2) dO = x
        });

        let active = []

        if (dL) active.push(dL)
        if (dP) active.push(dP)
        if (dO) active.push(dO)

        active.forEach(a => {
          var line = ''

          if (a.workerId > 0) {
            let doc = this.docs.find(x => x.id == a.workerId)

            if (doc) {
              line = doc.firstName + " " + doc.lastName
            }
            else {
              line = `Brak danych (ID: ${a.workerId})`
            }
          }
          else {
            line = "brak"
          }

          switch (a.declarationType) {
            case 0: line += " (lekarz)"
              break
            case 1: line += " (pielęgniarka)"
              break
            case 2: line += " (położna)"
              break
          }

          html += line + '<br />'
        })
      }

      return html
    },

    resetNfzService() {
      if (this.$refs.nfzSelector) {
        this.$refs.nfzSelector.setSelected(null)
      }
    },

    onItemSelected(item) {
      this.nfzService = item
    },

    getData(officeId) {
      this.nfzLoading = true
      this.$store
        .dispatch(Action.OFFICE_GET_OFFICE, officeId)
        .then((response) => {
          this.focusOffice = response
          if (this.focusOffice) {
            this.getServices(response)
          }
        })
        .catch(error => {
          this.nfzLoading = false
          this.apiProblem(error);
        });
    },

    reselctNfzService() {
      if (this.appointmentObject && this.appointmentObject.suggestServices && this.appointmentObject.suggestServices.length > 0 && this.appointment) {
        // add default service
        let target = this.appointmentObject.suggestServices.find(x => x.way == this.appointment.service)

        if (this.translatedServices) {
          let selector = this.translatedServices.find(x => x.item && x.item.bum == target.serviceCode)

          if (selector) {
            if (this.$refs.nfzSelector) {
              this.$refs.nfzSelector.setSelected(selector)
            }
          }
        }
      }
      else {
        if (this.$refs.nfzSelector) {
          this.$refs.nfzSelector.setSelected(null)
          this.filter = ''
        }
      }
    },
    // reselctNfzService() {
    //   if (this.appointmentObject && this.appointmentObject.defaultService && this.appointmentObject.defaultService.length > 0) {
    //     // add default service
    //     let target = this.appointmentObject.defaultService[0]

    //     if (this.translatedServices) {
    //       let selector = this.translatedServices.find(x => x.item && x.item.id == target)

    //       if (selector) {
    //         if (this.$refs.nfzSelector) {
    //           this.$refs.nfzSelector.setSelected(selector)
    //         }
    //       }
    //     }
    //   }
    //   else {
    //     if (this.$refs.nfzSelector) {
    //       this.$refs.nfzSelector.setSelected(null)
    //       this.filter = ''
    //     }
    //   }
    // },

    getServices(item) {
      this.nfzLoading = true
      this.$store
        .dispatch(Action.UMX_GET_SERVICES_FOR_OFFICE, item)
        .then((response) => {
          this.nfzLoading = false

          this.contracts = response

          if (this.contracts.length > 0) {
            this.contract = this.contracts[0]
          }

          this.$nextTick(() => {
            this.reselctNfzService()
          })
        })
        .catch(error => {
          this.nfzLoading = false
          this.apiProblem(error)
        });
    },


    addEwusInfoToAppointment() {
      if (this.appointment && this.ewus && this.mEwusStatusIsSerializable(this.ewus.ewusStatus)) {
        let ewusSerialized = JSON.stringify(this.ewus)
        this.appointment.lastEwus = ewusSerialized

        if (moment(this.appointment.start).isSame(moment(), 'day')) {
          this.appointment.startEwus = ewusSerialized
        }
      }
    },

    sortByOrder(a, b) {
      return a.order > b.order ? -1 : 1;
    },

    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT OBJECT WAYS METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getAppWays(appType) {
      let ways = []
      var apNone = { id: -1, name: 'Brak konfiguracji' }
      var ap0 = { id: 0, name: 'Wizyta w przychodni', icon: 'home-city-outline' }
      var ap1 = { id: 1, name: 'Teleporada', icon: 'phone-in-talk-outline' }
      var ap2 = { id: 2, name: 'Wizyta domowa', icon: 'car-outline' }

      if (appType || appType === 0) {
        //let configured = this.configApps.find(x => x.id == appType)

        if (this.appointmentObject && this.appointmentObject.ways) {
          this.appointmentObject.ways.forEach(w => {
            if (w === 0) ways.push(ap0)
            if (w === 1) ways.push(ap1)
            if (w === 2) ways.push(ap2)
          })

          if (ways.length < 1) {
            ways.push(apNone)
          }
        }
        else {
          ways.push(apNone)
        }
      }
      else {
        ways.push(apNone)
      }

      return ways
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT OBJECT PAYERS METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getAppPayers(appType) {
      let payers = []
      var apNone = { id: -1, name: 'Brak płatników' }
      var ap0 = { id: 0, name: 'Komercja', icon: 'account-cash' }
      var ap1 = { id: 1, name: 'NFZ', icon: 'heart-half-full' }
      var ap2 = { id: 2, name: 'MZ', icon: 'clipboard-pulse' }

      if (appType || appType === 0) {
        let configured = this.configApps.find(x => x.id == appType)

        if (this.appointmentObject) {
          configured = this.appointmentObject
        }

        if (configured && configured.payers) {
          configured.payers.forEach(p => {
            if (p === 0) payers.push(ap0)
            if (p === 1) payers.push(ap1)
            if (p === 2) payers.push(ap2)
          })

          if (payers.length < 1) {
            payers.push(apNone)
          }
        }
        else {
          payers.push(apNone)
        }
      }
      else {
        payers.push(apNone)
      }

      return payers.sort((a, b) => b.id - a.id)
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      !!! OBSOLETE !!!!!
      IMAGE TYPES GET
    */
    getImageTypes() {
      //this.loading = true
      this.$store
        .dispatch(Action.RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL)
        .then(() => {
          //this.loading = false
        })
        .catch((error) => {
          this.apiProblem(error)
          //this.loading = false
        })
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* RIS WORKSHOP TICKET TIME CHANGE METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    emitTime() {
      this.$emit("time-selected", this.appointment.start)
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* RIS TICKET METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    risPendingTickets(items) {
      if (this.risTickets.length < 1) {
        //ADD IKZ - DODAWANIE SKIEROWANIA
        this.addIkzReferralAdd()
      } else {
        //ADD IKZ - EDYCJA SKIEROWANIA
        this.addIkzReferralEdit()
      }

      this.risTickets.splice(0, this.risTickets.length)
      let risTickets = JSON.parse(JSON.stringify(items))

      risTickets.forEach(r => {
        if (r.payerType === 0) {
          r.isPrivate = true
          r.isPaid = r.isPaid ?? false,
            r.paymentId = ""
        } else {
          r.isPrivate = false
          r.isPaid = false
          r.paymentId = null
        }
        this.risTickets.push(r)
      })

      let payersTickets = risTickets.map((t) => t.payerType)
      let deviceType = risTickets.map((t) => t.deviceType)

      this.myAppointmentTypes()

      let newFilter = this.filteredAppTypes.filter((tick) => {
        let deviceExist = true
        if (deviceType && deviceType.length > 0 && deviceType[0] > -1) {
          deviceExist = tick.isDeviceRequired == true && tick.defaultDeviceType == deviceType[0] ? true : false
        }
        return tick.payers.some(r => payersTickets.includes(r)) && deviceExist
      })

      this.filteredAppTypes = newFilter.sort(this.sortByOrder)
    },

    risGeneratedTicket(ticket) {
      this.ticketRiS = ticket[0]
    },

    getTicketPayer(ticket) {
      let payers = []
      var apNone = { id: -1, name: 'Brak płatników' }
      var ap0 = { id: 0, name: 'Komercja', icon: 'account-cash' }
      var ap1 = { id: 1, name: 'NFZ', icon: 'heart-half-full' }
      var ap2 = { id: 2, name: 'MZ', icon: 'clipboard-pulse' }

      if (ticket.payerType === 0) payers.push(ap0)
      if (ticket.payerType === 1) payers.push(ap1)
      if (ticket.payerType === 2) payers.push(ap2)

      if (payers.length < 1) {
        payers.push(apNone)
      }

      if (ticket && ticket.referral && ticket.referral.eReferral && ticket.referral.eReferral.id != null) {
        if (ticket.referral.eReferral.isNFZ === true) {

          let findCommerceIndex = payers.findIndex((payer) => payer.id == 0)

          if (findCommerceIndex > -1) {
            payers.splice(findCommerceIndex, 1)
          }

          let findMZIndex = payers.findIndex((payer) => payer.id == 2)

          if (findMZIndex > -1) {
            payers.splice(findMZIndex, 1)
          }

        } else if (ticket.referral.eReferral.isNFZ === false) {

          let findNFZIndex = payers.findIndex((payer) => payer.id == 1)

          if (findNFZIndex > -1) {
            payers.splice(findNFZIndex, 1)
          }

        }
      }

      return payers
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* SPECIALIST REFERRAL TICKET METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    referralGeneratedTicket(ticket) {
      this.ticketReferral = ticket[0]
    },

    referralPendingTickets(items) {
      if (this.ticketReferral) {
        //ADD IKZ - EDYCJA SKIEROWANIA
        this.addIkzReferralEdit()
      } else {
        //ADD IKZ - DODAWANIE SKIEROWANIA
        this.addIkzReferralAdd()

      }

      this.ticketReferral = items[0]
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* SELECTED TICKETS - SPECIALIST / COLLECTION POINT METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    cancelTicket() {
      this.ticketToAdd = null
    },

    addTicket() {
      if (this.tickets.length) {
        this.ticketToAdd = this.tickets[0]
      }
    },

    addTicketClinics(ticket) {
      this.visitTypeSpecialistHistoryModalActive = false
      this.ticketReferral = ticket
      this.snackbar("Przypisano bilet do wizyty")
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT DETAILS HELPER METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    async getEmployee(id) {
      await this.$store
        .dispatch(Action.EMPLOYEE_GET, id)
        .then((x) => {
          this.employee = x.data
          this.subjectDownloaded = x.data
        })
        .catch((error) => {
          this.apiProblem(error)
        })
    },

    getDevice(id) {
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET, id)
        .then((x) => {
          if (x) {
            this.devices.push(x)
            this.areaDevice = x
          }
        })
        .catch((error) => {
          this.apiProblem(error)
        })
    },

    getFacility(id) {
      let pos = this.facilities.find((s) => s.id === id)
      if (pos) return pos.name
      else return `??? [${id}]`
    },

    getOffice(id) {
      let category = this.allOffices.find((c) => c.id == id)
      if (category) return category.name
      else return `Brak informacji`
    },
    getOfficeObject(id) {
      return this.allOffices.find((c) => c.id == id)
    },

    getOfficeTypeApps(id) {
      let category = this.configOffices.find((c) => c.id == id)
      if (category && category.appointments) return category.appointments
      else return []
    },

    getArea(id) {
      let category = this.configAreas.find((c) => c.id == id)
      if (category) return category.name
      else return `Brak informacji`
    },
    getAreaObject(id) {
      return this.configAreas.find((c) => c.id == id)
    },

    getClinic(id) {
      let clin = this.clinics.find(c => c.internalId == id)
      if (clin) return clin.name
      else return 'Nieznana placówka'
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT MAX LENGTH METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    showMaxTimeAlert() {
      this.snackbar("Maksymalna długość wizyty wynika z długości strefy lub kolejnej, najbliższej wizyty.")
    },

    getMaxDuration() {
      return moment(this.block.end).diff(moment(this.appStartDate), "minutes")
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */



    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT TIME METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    updateTimesInControl() {
      let minH = this.minMoment.hours()
      let minM = this.minMoment.minutes()
      let maxH = this.maxMoment.hours()
      let maxM = this.maxMoment.minutes()

      let hour = parseInt(this.timeObject.HH)

      let mS = 0
      let xS = 59

      if (hour === minH) {
        mS = minM
        //this.timeObject.mm = this.minMoment.format('mm')
      }

      if (hour === maxH) {
        xS = maxM
      }

      this.enabledMinutes.length = 0 // = []
      for (let g = mS; g < xS; g++) {
        this.enabledMinutes.push(g)
      }

      if (this.enabledMinutes.length > 0) {
        this.timeObject = {
          HH: this.timeObject.HH,
          mm: this.enabledMinutes[0].toString().padStart(2, "0"),
        }
      }

      this.setupStartDate()
    },

    setupStartDate() {
      let pH = parseInt(this.timeObject.HH)
      let pM = parseInt(this.timeObject.mm)

      this.appStartDate.setHours(pH)
      this.appStartDate.setMinutes(pM)

      this.maxDuration = this.getMaxDuration()
      this.appointment.start = moment(this.appStartDate).format("YYYY-MM-DDTHH:mm:00")

      if (this.focusReservation) {
        // leave as it is
      }
      else {

        if (this.visitLength > this.maxDuration) {
          this.visitLength = this.maxDuration
        }

        this.appointment.stop = moment(this.appStartDate)
          .add(this.visitLength, "minutes")
          .format("YYYY-MM-DDTHH:mm:00")
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */



    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT TYPES SETUP METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    myAppointmentTypes() {
      let types = []

      if (this.block.worktimeArea && this.block.worktimeArea.device) {
        if (this.isRISOffice) {
          let office = this.getOfficeObject(this.block.worktimeArea.officeId)
          if (office.type > -1) {
            let possibles = this.getOfficeTypeApps(office.type)
            let filtered = this.configApps.filter((a) => {
              return possibles.includes(a.id)
            })

            filtered.forEach((el) => {
              if (types.includes(el)) {
                // ok
              } else {
                types.push(el)
              }
            })
          }
        } else {
          types.push({ id: 0, name: "Ogólna" })
        }
      } else if ((this.block.worktimeArea === null || this.block.worktimeArea === undefined) && this.filters && this.filters.device) {
        types.push({ id: 0, name: "Ogólna" })
      } else if (this.block.worktimeArea && (this.block.worktimeArea.officeId === null || (this.block.worktimeArea.employee > -1 && this.showAllAppTypes))) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos)
            if (posObject) {
              let possibles = posObject.appointments
              let filtered = this.configApps.filter((a) => {
                return possibles.includes(a.id)
              })
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el)
                }
              })
            }
          })
        }
      } else if (this.block.worktimeArea && this.block.worktimeArea.employee > -1 && this.showAllAppTypes) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos)
            if (posObject) {
              let possibles = posObject.appointments
              let filtered = this.configApps.filter((a) => {
                return possibles.includes(a.id)
              })
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el)
                }
              })
            }
          })
        }
      } else if ((this.block.worktimeArea === null || this.block.worktimeArea === undefined) && (this.filters.office === null || (this.filters.employee > -1 && this.showAllAppTypes))) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos)
            if (posObject) {
              let possibles = posObject.appointments
              let filtered = this.configApps.filter((a) => {
                return possibles.includes(a.id)
              })
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el)
                }
              })
            }
          })
        }
      } else if (this.block.worktimeArea) {
        //let area = this.getOfficeAreaObject(this.block.worktimeArea.office)
        let office = this.getOfficeObject(this.block.worktimeArea.officeId)
        if (office.type > -1) {
          let possibles = this.getOfficeTypeApps(office.type)
          let filtered = this.configApps.filter((a) => {
            return possibles.includes(a.id)
          })

          filtered.forEach((el) => {
            if (types.includes(el)) {
              // ok
            } else {
              types.push(el)
            }
          })
        }
      } else if (this.filters.office) {
        //let area = this.getOfficeAreaObject(this.block.worktimeArea.office)
        let office = this.getOfficeObject(this.filters.office)
        if (office && office.type > -1) {
          this.focusOffice = office
          let possibles = this.getOfficeTypeApps(office.type)


          let filtered = this.configApps.filter((a) => {
            return possibles.includes(a.id)
          })

          filtered.forEach((el) => {
            if (types.includes(el)) {
              // ok
            } else {
              types.push(el)
            }
          })
        }
      }

      this.filteredAppTypes = JSON.parse(JSON.stringify(types.sort(this.sortByOrder)))

      /**
       * IMPORTANT!!!!
       * AP-KOLCE PROP CHECK FOR SPECIALIST OFFICES WITH CLINIC TYPES SET TO AP-KOLCE REPORT
       * CHECK FOR FILTERED APP TYPES
       * - IF APP TYPE HAS ONLY ONE PAYER - NFZ PAYER - SET APKOLCE TO TRUE
       */
      if (this.isSpecialistOffice && this.isClinicApkolce) {
        this.filteredAppTypes.forEach((appObject) => {
          if (appObject.payers.includes(1) && appObject.payers.length === 1) {
            appObject.apkolce = true
          }
        })
      }

      let defaultType = null
      let defaultSubtype = null

      if (this.block.worktimeArea && this.filteredAppTypes.length > 0 && this.risTickets.length < 1 && !this.focusReservation) {
        var workConfig = this.configAreas.find(x => x.id === this.block.worktimeArea.area)

        if (workConfig) {
          var defApp = this.filteredAppTypes.find(a => a.id === workConfig.defaultAppointment)

          if (defApp) {
            this.appointmentObject = defApp
          }
          else {

            // try find default subtype

            this.filteredAppTypes.forEach(a => {
              if (a.subcategories) {
                let defsub = a.subcategories.find(a => a.id === workConfig.defaultAppointment)

                if (defsub) {
                  defaultType = a
                  defaultSubtype = defsub
                }
              }
            })

            if (defaultType && defaultSubtype) {
              this.appointmentObject = defaultType
            }
            else {
              this.appointmentObject = this.filteredAppTypes[0]
            }
          }
        }
        else {
          this.appointmentObject = this.filteredAppTypes[0]
        }

        this.appointmentObjectTmp = this.appointmentObject.id
        this.onTypeChanged(this.appointmentObject.id)


        if (defaultType && defaultSubtype) {
          this.$nextTick(() => {
            this.switchSubWithType(defaultSubtype.id)
          })
        }
      }

    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* OBSOLETE ******************* -->
      <!--------------------------------------------------------->
    */

    getConfigOffice(id) {
      if (this.configOffices) {
        let off = this.configOffices.find((o) => o.id === id)

        if (off) {
          return off
        }
      }

      return { appointments: [] }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* SCHEDULE COLLECTION POINT METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    sendScheduleCollectionPointReservationDialog() {
      this.$buefy.dialog.confirm({
        message: 'Zostanie utworzona wstępna rezerwacja w terminarzu. Przed wizytą trzeba będzie uzupełnić brakujące dane. Kontynuować?',
        cancelText: 'Anuluj',
        confirmText: 'Tak, rezerwuj termin',
        scroll: 'keep',
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => {
          this.scheduleCollectionPointDispatch(true)
        }
      })
    },

    async prepareTicketsToSend() {
      let ticketsToCreateFromPass = []
      let ticketsToSend = []

      this.ticketsPassCollectionPointSelected.forEach(ticketPass => {
        if (ticketPass.tickets) {
          ticketPass.tickets.forEach(ticket => {
            let ticketToCreate = this.createNewTicketFromTicketPass(ticket)
            ticketsToCreateFromPass.push(ticketToCreate)
          })
        }
      })

      for (let index = 0; index < ticketsToCreateFromPass.length; index++) {
        let ticketToCreate = ticketsToCreateFromPass[index]
        let ticket = await this.createTicket(ticketToCreate)
        if (ticket) {
          ticketsToSend.push(ticket)
        }
      }

      this.ticketsCollectionPointSelected.forEach(ticket => {
        ticketsToSend.push(ticket)
      })

      ticketsToSend.forEach((ticket, index) => {
        if (index === 0) {
          ticket.appointmentStart = this.appointment.start
          ticket.appointmentStop = this.appointment.stop
        }

        if (index > 0) {
          const prev = ticketsToSend[index - 1]
          ticket.appointmentStart = prev.appointmentStop
          ticket.appointmentStop = moment(prev.appointmentStop)
            .add(this.visitLength, "minutes")
            .format("YYYY-MM-DDTHH:mm:00")
        }
      })

      return ticketsToSend
    },

    async schedule40PlusDispatch(isReservation) {
      if (!isReservation) {
        if (this.appointment.payerType == 0 || this.appointment.payerType == 2) {
          // commercial and MZ visit

          if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {
            // ok
          } else {
            this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
            return;
          }
        }

        if (this.appointment.payerType == 1) {
          // NFZ visit
          if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {
            if ((this.ewus && this.mEwusStatusIsGood(this.ewus.ewusStatus)) || this.identificationInfoReversed.length > 0 && this.identificationInfoReversed[0].entitlementDocument == 12 && moment().format('YYYY-MM-DD') == moment(this.identificationInfoReversed[0].created).format('YYYY-MM-DD') || (this.identificationInfoReversed.length > 0 && (this.identificationInfoReversed[0].entitlementDocument == 16 || this.identificationInfoReversed[0].entitlementDocument == 15))) {
              // ok
            } else {
              this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
              return;
            }
          } else {
            this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
            return;
          }
        }
      }

      this.requestInProgress = true
      let createdTicket = null

      // TUTAJ

      if (this.ticketReferral) {
        this.ticketReferral.referral = null
        let ticketReferralCopy = JSON.parse(JSON.stringify(this.ticketReferral))
        delete ticketReferralCopy.referralResultsObject

        createdTicket = await this.createTicket(ticketReferralCopy)
        if (createdTicket) {
          this.appointment.ticket = createdTicket.id
        }
      } else if (this.ticketToAdd) {
        createdTicket = this.ticketToAdd
      }

      this.prepareSendScheduleCollectionPoint(createdTicket, false, isReservation)
      let responseVisit = await this.sendScheduleCollectionPoint(createdTicket, isReservation)
      this.$emit("created", responseVisit)
      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)

      this.requestInProgress = false
    },

    async scheduleCollectionPointDispatch(isReservation) {
      let findNFZTicketPayer = this.collectionPointDisplay.find(x => x.payerType === 1)
      let findOtherTicketPayer = this.collectionPointDisplay.find(x => x.payerType === 0 || x.payerType === 2)

      if (!isReservation) {
        if (findOtherTicketPayer) {
          // commercial and MZ visit

          if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {
            // ok
          } else {
            this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
            return;
          }
        }

        if (findNFZTicketPayer) {
          // NFZ visit
          if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {
            if ((this.ewus && this.mEwusStatusIsGood(this.ewus.ewusStatus)) || this.identificationInfoReversed.length > 0 && this.identificationInfoReversed[0].entitlementDocument == 12 && moment().format('YYYY-MM-DD') == moment(this.identificationInfoReversed[0].created).format('YYYY-MM-DD') || (this.identificationInfoReversed.length > 0 && (this.identificationInfoReversed[0].entitlementDocument == 16 || this.identificationInfoReversed[0].entitlementDocument == 15))) {
              // ok
            } else {
              this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
              return;
            }
          } else {
            this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
            return;
          }
        }
      }

      this.requestInProgress = true

      let ticketsToSend = await this.prepareTicketsToSend()
      let visitResponses = []

      for (let index = 0; index < ticketsToSend.length; index++) {
        let ticket = ticketsToSend[index]
        this.prepareSendScheduleCollectionPoint(ticket, true, isReservation)
        let responseVisit = await this.sendScheduleCollectionPoint(ticket, isReservation)
        if (responseVisit) {
          visitResponses.push(responseVisit)
        }
      }

      this.$emit("created-many", visitResponses)
      this.requestInProgress = false
    },

    prepareSendScheduleCollectionPoint(ticketToAdd, setPropsFromTicket, isReservation) {
      this.addEwusInfoToAppointment()

      if (isReservation) {
        this.appointment.state = 100
        this.appointment.isReservation = true
      }

      if (this.employee) {
        this.appointment.employee = this.employee.id
      }

      if (this.patient) {
        this.appointment.patientId = this.patient.id
      }

      if (this.block.worktimeArea) {
        this.appointment.spec = this.block.worktimeArea.spec
        this.appointment.clinic = this.block.worktimeArea.clinic
        this.appointment.area = this.block.worktimeArea.area
        this.appointment.areaId = this.block.worktimeArea.id
        this.appointment.officeId = this.block.worktimeArea.officeId
        this.appointment.officeRoomId = this.block.worktimeArea.officeRoomId
        this.appointment.facility = this.block.worktimeArea.facility
      } else if (this.focusOffice) {
        this.appointment.spec = -1
        this.appointment.clinic = this.focusOffice.clinicId
        this.appointment.area = -1
        this.appointment.officeId = this.filters.office
        this.appointment.officeRoomId = this.filters.room
      }


      let procedures = []

      if (ticketToAdd && ticketToAdd.procedures) {
        ticketToAdd.procedures.forEach(x => {
          procedures.push(x.procedureId)
        })
      }

      this.appointment.plannedProcedures = procedures

      let proceduresIcd9 = []

      if (ticketToAdd && ticketToAdd.procedures) {
        ticketToAdd.procedures.forEach((proc) => {
          proceduresIcd9.push(proc.icd9)
        })
      }

      this.appointment.procedures = proceduresIcd9

      if (ticketToAdd) {
        this.appointment.ticket = ticketToAdd.id
      }

      if (ticketToAdd && setPropsFromTicket) {
        this.appointment.payerType = ticketToAdd.payerType
      }


      if (this.appointment.payerType === 0) {
        this.appointment.isPrivate = true
      } else {
        this.appointment.isPrivate = false
        this.appointment.paymentId = null
      }


      // No subtype for collection point, at least for now
      this.appointment.subtype = -1

      if (ticketToAdd && setPropsFromTicket) {
        this.appointment.start = ticketToAdd.appointmentStart
        this.appointment.stop = ticketToAdd.appointmentStop
      }

    },

    async sendScheduleCollectionPoint(ticketToAdd, isReservation) {
      let responseSchedule = null

      let action = Action.S2_SEND_APPOINTMENT
      if (this.reservationMode) {
        action = Action.S2_EDIT_APPOINTMENT
        this.appointment.state = isReservation ? 100 : 0
      }

      if (isReservation) {
        await this.$store
          .dispatch(action, this.appointment)
          .then((response) => {
            this.successSnackbar("Rezerwacja została zapisana")
            responseSchedule = response

            //ADD IKZ - DODANIE WIZYTY
            this.addIKZVisitAdd()

            if (ticketToAdd) {
              this.setTicketReservedCollectionPoint(ticketToAdd)
            }
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      } else {
        await this.$store
          .dispatch(action, this.appointment)
          .then((response) => {
            this.successSnackbar("Zapisano na serwerze")
            responseSchedule = response

            //ADD IKZ - DODANIE WIZYTY
            this.addIKZVisitAdd()

            if (ticketToAdd) {
              this.setTicketReservedCollectionPoint(ticketToAdd)
            }

          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }

      return responseSchedule
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* SCHEDULE METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    async sendSchedule(isReservation) {

      this.addEwusInfoToAppointment()

      if (this.ticketsPassNurseSelected) {
        if (this.ticketsPassNurseSelected.tickets && this.ticketsPassNurseSelected.tickets.length > 0) {
          let ticket = this.ticketsPassNurseSelected.tickets[0]
          let ticketToCreate = this.createNewTicketFromTicketPass(ticket)
          let ticketCreated = await this.createTicket(ticketToCreate)
          if (ticketCreated) {
            this.ticketToAdd = ticketCreated
          }
        }
      }

      if (this.reservationMode === false && isReservation) {

        this.$buefy.dialog.confirm({
          message: 'Zostanie utworzona wstępna rezerwacja w terminarzu. Przed wizytą trzeba będzie uzupełnić brakujące dane. Kontynuować?',
          cancelText: 'Anuluj',
          confirmText: 'Tak, rezerwuj termin',
          scroll: 'keep',
          type: 'is-info',
          hasIcon: true,
          onConfirm: () => {
            if (this.isRISOffice) {
              this.sendRisSchedule(isReservation)
            } else {
              this.sendReservation()
            }
          }
        })

      } else {
        if (this.isRISOffice) {
          this.sendRisSchedule(isReservation)
        } else {

          let action = Action.S2_SEND_APPOINTMENT

          if (this.reservationMode) {
            action = Action.S2_EDIT_APPOINTMENT
            this.appointment.state = isReservation ? 100 : 0
          }

          this.requestInProgress = true

          if (this.employee) {
            this.appointment.employee = this.employee.id
          }

          if (this.patient) {
            this.appointment.patientId = this.patient.id
          }

          if (this.appointment.isGroup) {
            this.appointment.patientId = -1
          }

          if (this.nfzService && this.nfzService.item) {
            this.appointment.medServices = []
            this.appointment.medServices.push(this.nfzService.item.id)
          }

          if (this.block.worktimeArea) {
            this.appointment.spec = this.block.worktimeArea.spec
            this.appointment.clinic = this.block.worktimeArea.clinic
            this.appointment.area = this.block.worktimeArea.area
            this.appointment.areaId = this.block.worktimeArea.id;
            this.appointment.officeId = this.block.worktimeArea.officeId
            this.appointment.officeRoomId = this.block.worktimeArea.officeRoomId
            this.appointment.facility = this.block.worktimeArea.facility
            this.appointment.isEr = this.block.worktimeArea.isEr
            this.appointment.erHarmId = this.block.worktimeArea.erId
          } else if (this.focusOffice) {
            this.appointment.spec = -1
            this.appointment.clinic = this.focusOffice.clinicId
            this.appointment.area = -1
            this.appointment.officeId = this.filters.office
            this.appointment.officeRoomId = this.filters.room
          }

          this.appointment.device = this.device

          if (this.appointmentObject) {
            this.appointment.isApkolce = this.appointmentObject.apkolce
          }

          // if (this.appointment.type > 99999) {
          //   this.appointment.subject = 1
          //   this.appointment.subjectId = -1
          // }

          if (this.ticketToAdd) {
            this.appointment.ticket = this.ticketToAdd.id
          }

          /**
           * Set category prop for appointment
           * At the very moment - only for specialists
           * 1 - AOS First Time, 2 - AOS Continuation
           */
          if (this.visitTypeSpecialist) {
            this.appointment.category = this.visitTypeSpecialist
          }

          if (this.appointmentObject.id === 111) {
            let ticket = this.ticketObject
            ticket.procedures = []
            ticket.patientId = this.patient.id
            ticket.releaseDate = this.mNow()
            ticket.startDate = this.mNow()
            ticket.created = this.mNow()
            ticket.expiryDate = "2021-12-31T00:00:00.000Z"
            ticket.ticketType = 111
            ticket.ticketSubType = 11100
            ticket.isTimeless = false
            ticket.state = 3

            this.cytologyExaminations.forEach((element) => {
              let newProcedure = this.ticketProcedure
              if (element.type != null) {
                newProcedure.procedureId = element.type
              } else {
                newProcedure.procedureId = -1
              }
              if (element.subtype != null) {
                newProcedure.localization = element.subtype
              } else {
                newProcedure.localization = -1
              }
              ticket.procedures.push(JSON.parse(JSON.stringify(newProcedure)))
            })

            this.$store
              .dispatch(Action.TICKET_CREATE, ticket)
              .then((responseT) => {
                this.successSnackbar("Wygenerowano bilet")
                this.appointment.ticket = responseT.id

                //ADD IKZ - UTWORZENIE BILETU
                this.addIkzTicketCreate()

                this.setTicketReservedRis(responseT)

                this.$store
                  .dispatch(action, this.appointment)
                  .then((response) => {

                    //ADD IKZ - DODANIE WIZYTY
                    this.addIKZVisitAdd()

                    this.successSnackbar("Zapisano na serwerze")
                    this.$emit("created", response)
                    this.requestInProgress = false
                  })
                  .catch((error) => {
                    this.apiProblem(error)
                    this.requestInProgress = false
                  })
              })
              .catch((error) => {
                this.apiProblem(error)
              })
          } else {

            //RIS - add ticket to appointment and other props
            if (this.isRISOffice) {
              this.appointment.ticket = this.ticketRiS.id
              this.appointment.employee = this.block.worktimeArea ? this.block.worktimeArea.employee : -1
              this.ticketRiS.procedures.forEach((proc) => {
                this.appointment.procedures.push(proc.icd9)
              })
              this.appointment.plannedProcedures.push(0)
            }

            if (this.appointment.payerType == 0 || this.appointment.payerType == 2) {
              // commercial and MZ visit

              if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {
                //Referral - add ticket to appointment
                if (this.ticketReferral) {
                  this.requestInProgress = true
                  this.$store.dispatch(Action.TICKET_CREATE, this.ticketReferral)
                    .then((response) => {
                      // this.successSnackbar("Dodano bilet")
                      this.appointment.ticket = response.id

                      //ADD IKZ - UTWORZENIE BILETU
                      this.addIkzTicketCreate()
                      this.setTicketReservedRis(response)
                      this.$store
                        .dispatch(action, this.appointment)
                        .then((responseApp) => {

                          //ADD IKZ - DODANIE WIZYTY
                          this.addIKZVisitAdd()


                          this.$emit("created", responseApp)
                          this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                          this.successSnackbar("Dodano wizytę")
                          this.requestInProgress = false
                        })
                        .catch((error) => {
                          this.apiProblem(error)
                          this.requestInProgress = false
                        })
                    })
                    .catch((error) => {
                      this.requestInProgress = false
                      this.apiProblem(error)
                    })
                } else {
                  this.$store
                    .dispatch(action, this.appointment)
                    .then((response) => {
                      this.successSnackbar("Zapisano na serwerze")

                      //ADD IKZ - DODANIE WIZYTY
                      this.addIKZVisitAdd()

                      if (this.ticketToAdd && !this.ticketToAdd.programId) {
                        this.setTicketReserved(response)
                      } else if (this.ticketToAdd && this.ticketToAdd.programId) {
                        this.setTicketReservedProgram(this.ticketToAdd)
                        this.$emit("created", response)
                        this.requestInProgress = false
                      } else {
                        this.$emit("created", response)
                        this.requestInProgress = false
                      }
                    })
                    .catch((error) => {
                      this.apiProblem(error)
                      this.requestInProgress = false
                    })
                }
              } else {
                this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
                this.requestInProgress = false
              }
            } else if (this.appointment.payerType == 1) {
              // NFZ visit
              if (this.patient && (this.patient.pesel || this.patient.identityDocumentNumber) && this.patient.addressTeryt && this.patient.dateOfBirth && this.patient.addressStreet && this.patient.addressHouseNumber && this.patient.addressCity && this.patient.addressZipCode) {

                if ((this.ewus && this.mEwusStatusIsGood(this.ewus.ewusStatus)) || this.identificationInfoReversed.length > 0 && this.identificationInfoReversed[0].entitlementDocument == 12 && moment().format('YYYY-MM-DD') == moment(this.identificationInfoReversed[0].created).format('YYYY-MM-DD') || (this.identificationInfoReversed.length > 0 && (this.identificationInfoReversed[0].entitlementDocument == 16 || this.identificationInfoReversed[0].entitlementDocument == 15))) {
                  //Referral - add ticket to appointment
                  if (this.ticketReferral) {
                    this.requestInProgress = true
                    this.$store.dispatch(Action.TICKET_CREATE, this.ticketReferral)
                      .then((response) => {
                        // this.successSnackbar("Dodano bilet")

                        //ADD IKZ - UTWORZENIE BILETU
                        this.addIkzTicketCreate()


                        this.appointment.ticket = response.id
                        this.setTicketReservedRis(response)
                        this.$store
                          .dispatch(action, this.appointment)
                          .then((responseApp) => {

                            //ADD IKZ - DODANIE WIZYTY
                            this.addIKZVisitAdd()


                            this.$emit("created", responseApp)
                            this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                            this.successSnackbar("Dodano wizytę")
                            this.requestInProgress = false
                          })
                          .catch((error) => {
                            this.apiProblem(error)
                            this.requestInProgress = false
                          })
                      })
                      .catch((error) => {
                        this.requestInProgress = false
                        this.apiProblem(error)
                      })
                  } else {
                    this.$store
                      .dispatch(action, this.appointment)
                      .then((response) => {
                        this.successSnackbar("Zapisano na serwerze")

                        //ADD IKZ - DODANIE WIZYTY
                        this.addIKZVisitAdd()

                        if (this.ticketToAdd && !this.ticketToAdd.programId) {
                          this.setTicketReserved(response)
                        } else if (this.ticketToAdd && this.ticketToAdd.programId) {
                          this.setTicketReservedProgram(this.ticketToAdd)
                          this.$emit("created", response)
                          this.requestInProgress = false
                        } else {
                          this.$emit("created", response)
                          this.requestInProgress = false
                        }
                      })
                      .catch((error) => {
                        this.apiProblem(error)
                        this.requestInProgress = false
                      })
                  }
                } else {
                  this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
                  this.requestInProgress = false
                }
              } else {
                this.snackbar('Niewystarczające dane pacjenta do umówienia wizyty')
                this.requestInProgress = false
              }
            }





            // this.$store
            //   .dispatch(Action.S2_SEND_APPOINTMENT, this.appointment)
            //   .then((response) => {
            //     this.successSnackbar("Zapisano na serwerze")

            //     if (this.ticketToAdd) {
            //       this.setTicketReserved(response)
            //     }
            //     else {
            //       this.$emit("created", response)
            //       this.requestInProgress = false
            //     }
            //   })
            //   .catch((error) => {
            //     this.apiProblem(error)
            //     this.requestInProgress = false
            //   })
          }
        }
      }
    },

    sendReservation() {

      this.requestInProgress = true

      this.appointment.state = 100
      this.appointment.isReservation = true

      if (this.employee) {
        this.appointment.employee = this.employee.id
      }

      if (this.patient) {
        this.appointment.patientId = this.patient.id
      }

      if (this.appointment.isGroup) {
        this.appointment.patientId = -1
      }

      if (this.nfzService && this.nfzService.item) {
        this.appointment.medServices = []
        this.appointment.medServices.push(this.nfzService.item.id)
      }

      if (this.block.worktimeArea) {
        this.appointment.spec = this.block.worktimeArea.spec
        this.appointment.clinic = this.block.worktimeArea.clinic
        this.appointment.area = this.block.worktimeArea.area
        this.appointment.areaId = this.block.worktimeArea.id;
        this.appointment.officeId = this.block.worktimeArea.officeId
        this.appointment.officeRoomId = this.block.worktimeArea.officeRoomId
        this.appointment.facility = this.block.worktimeArea.facility
      } else if (this.focusOffice) {
        this.appointment.spec = -1
        this.appointment.clinic = this.focusOffice.clinicId
        this.appointment.area = -1
        this.appointment.officeId = this.filters.office
        this.appointment.officeRoomId = this.filters.room
      }

      this.appointment.device = this.device

      if (this.appointmentObject) {
        this.appointment.isApkolce = this.appointmentObject.apkolce
      }

      // if (this.appointment.type > 99999) {
      //   this.appointment.subject = 1
      //   this.appointment.subjectId = -1
      // }

      if (this.ticketToAdd) {
        this.appointment.ticket = this.ticketToAdd.id
      }

      /**
      * Set category prop for appointment
      * At the very moment - only for specialists
      * 1 - AOS First Time, 2 - AOS Continuation
      */
      if (this.visitTypeSpecialist) {
        this.appointment.category = this.visitTypeSpecialist
      }

      if (this.appointmentObject.id === 111) {
        let ticket = this.ticketObject
        ticket.procedures = []
        ticket.patientId = this.patient.id
        ticket.releaseDate = this.mNow()
        ticket.startDate = this.mNow()
        ticket.created = this.mNow()
        ticket.expiryDate = "2021-12-31T00:00:00.000Z"
        ticket.ticketType = 111
        ticket.ticketSubType = 11100
        ticket.isTimeless = false
        ticket.state = 3

        this.cytologyExaminations.forEach((element) => {
          let newProcedure = this.ticketProcedure
          if (element.type != null) {
            newProcedure.procedureId = element.type
          } else {
            newProcedure.procedureId = -1
          }
          if (element.subtype != null) {
            newProcedure.localization = element.subtype
          } else {
            newProcedure.localization = -1
          }
          ticket.procedures.push(JSON.parse(JSON.stringify(newProcedure)))
        })

        this.$store
          .dispatch(Action.TICKET_CREATE, ticket)
          .then((responseT) => {
            //this.successSnackbar("Wygenerowano bilet")
            this.appointment.ticket = responseT.id

            //ADD IKZ - UTWORZENIE BILETU
            this.addIkzTicketCreate()

            this.setTicketReservedRis(responseT)

            this.$store
              .dispatch(Action.S2_SEND_APPOINTMENT, this.appointment)
              .then((response) => {

                //ADD IKZ - DODANIE WIZYTY
                this.addIKZVisitAdd()

                this.successSnackbar("Rezerwacja została zapisana")
                this.$emit("created", response)
                this.requestInProgress = false
              })
              .catch((error) => {
                this.apiProblem(error)
                this.requestInProgress = false
              })
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      } else {

        //RIS - add ticket to appointment and other props
        if (this.isRISOffice) {
          this.appointment.ticket = this.ticketRiS.id
          this.appointment.employee = this.block.worktimeArea ? this.block.worktimeArea.employee : -1
          this.ticketRiS.procedures.forEach((proc) => {
            this.appointment.procedures.push(proc.icd9)
          })
          this.appointment.plannedProcedures.push(0)
        }

        if (this.appointment.payerType == 0 || this.appointment.payerType == 2) {
          // commercial and MZ visit

          if (this.patient) {
            //Referral - add ticket to appointment
            if (this.ticketReferral) {
              this.requestInProgress = true
              this.$store.dispatch(Action.TICKET_CREATE, this.ticketReferral)
                .then((response) => {
                  //this.successSnackbar("Dodano bilet")
                  this.appointment.ticket = response.id

                  //ADD IKZ - UTWORZENIE BILETU
                  this.addIkzTicketCreate()


                  this.setTicketReservedRis(response)
                  this.$store
                    .dispatch(Action.S2_SEND_APPOINTMENT, this.appointment)
                    .then((responseApp) => {

                      //ADD IKZ - DODANIE WIZYTY
                      this.addIKZVisitAdd()


                      this.$emit("created", responseApp)
                      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                      this.successSnackbar("Rezerwacja została zapisana.")
                      this.requestInProgress = false
                    })
                    .catch((error) => {
                      this.apiProblem(error)
                      this.requestInProgress = false
                    })
                })
                .catch((error) => {
                  this.requestInProgress = false
                  this.apiProblem(error)
                })
            } else {
              this.$store
                .dispatch(Action.S2_SEND_APPOINTMENT, this.appointment)
                .then((response) => {
                  this.successSnackbar("Rezerwacja została zapisana.")

                  //ADD IKZ - DODANIE WIZYTY
                  this.addIKZVisitAdd()

                  if (this.ticketToAdd && !this.ticketToAdd.programId) {
                    this.setTicketReserved(response)
                  } else if (this.ticketToAdd && this.ticketToAdd.programId) {
                    this.setTicketReservedProgram(this.ticketToAdd)
                    this.$emit("created", response)
                    this.requestInProgress = false
                  } else {
                    this.$emit("created", response)
                    this.requestInProgress = false
                  }
                })
                .catch((error) => {
                  this.apiProblem(error)
                  this.requestInProgress = false
                })
            }
          } else {
            this.snackbar('Brak pacjenta')
            this.requestInProgress = false
          }
        } else if (this.appointment.payerType == 1) {
          // NFZ visit
          if (this.patient) {

            //Referral - add ticket to appointment
            if (this.ticketReferral) {
              this.requestInProgress = true
              this.$store.dispatch(Action.TICKET_CREATE, this.ticketReferral)
                .then((response) => {
                  //this.successSnackbar("Dodano bilet")

                  //ADD IKZ - UTWORZENIE BILETU
                  this.addIkzTicketCreate()


                  this.appointment.ticket = response.id
                  this.setTicketReservedRis(response)
                  this.$store
                    .dispatch(Action.S2_SEND_APPOINTMENT, this.appointment)
                    .then((responseApp) => {

                      //ADD IKZ - DODANIE WIZYTY
                      this.addIKZVisitAdd()


                      this.$emit("created", responseApp)
                      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                      this.successSnackbar("Rezerwacja została zapisana.")
                      this.requestInProgress = false
                    })
                    .catch((error) => {
                      this.apiProblem(error)
                      this.requestInProgress = false
                    })
                })
                .catch((error) => {
                  this.requestInProgress = false
                  this.apiProblem(error)
                })
            } else {
              this.$store
                .dispatch(Action.S2_SEND_APPOINTMENT, this.appointment)
                .then((response) => {
                  this.successSnackbar("Rezerwacja została zapisana.")

                  //ADD IKZ - DODANIE WIZYTY
                  this.addIKZVisitAdd()

                  if (this.ticketToAdd && !this.ticketToAdd.programId) {
                    this.setTicketReserved(response)
                  } else if (this.ticketToAdd && this.ticketToAdd.programId) {
                    this.setTicketReservedProgram(this.ticketToAdd)
                    this.$emit("created", response)
                    this.requestInProgress = false
                  } else {
                    this.$emit("created", response)
                    this.requestInProgress = false
                  }
                })
                .catch((error) => {
                  this.apiProblem(error)
                  this.requestInProgress = false
                })
            }
          } else {
            this.snackbar('Brak pacjenta')
            this.requestInProgress = false
          }
        }
      }
    },
    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* RIS SCHEDULE METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    sendRisSchedule(isReservation) {
      this.requestInProgress = true

      if (isReservation) {
        this.appointment.state = 100
        this.appointment.isReservation = true
      }

      if (this.employee) {
        this.appointment.employee = this.employee.id
      }

      if (this.patient) {
        this.appointment.patientId = this.patient.id
      }

      if (this.appointmentObject) {
        this.appointment.isApkolce = this.appointmentObject.apkolce
      }

      if (this.block.worktimeArea) {
        this.appointment.spec = this.block.worktimeArea.spec
        this.appointment.clinic = this.block.worktimeArea.clinic
        this.appointment.area = this.block.worktimeArea.area
        this.appointment.areaId = this.block.worktimeArea.id;
        this.appointment.officeId = this.block.worktimeArea.officeId
        this.appointment.officeRoomId = this.block.worktimeArea.officeRoomId
        this.appointment.isEr = this.block.worktimeArea.isEr
        this.appointment.erHarmId = this.block.worktimeArea.erId
      } else if (this.focusOffice) {
        this.appointment.spec = -1
        this.appointment.clinic = this.focusOffice.clinicId
        this.appointment.area = -1
        this.appointment.officeId = this.filters.office
        this.appointment.officeRoomId = this.filters.room
      }

      //RIS - add ticket to appointment and other props
      this.appointment.employee = this.block.worktimeArea ? this.block.worktimeArea.employee : -1
      this.appointment.plannedProcedures.push(0)

      let appointments = []

      if (!this.device && this.devices && this.devices.length == 1) {
        this.device = this.devices[0].id
      }

      if (isReservation && this.risTickets.length < 1) {
        var appClone = JSON.parse(JSON.stringify(this.appointment))
        appointments.push(appClone)
      }
      else {
        this.risTickets.forEach(ticket => {
          var appClone = JSON.parse(JSON.stringify(this.appointment))
          if (!ticket.device) {
            ticket.device = this.devices[0].id
          }
          appClone.tmpTicket = ticket
          appClone.device = ticket.device
          appClone.payerType = ticket.payerType

          appClone.isPrivate = ticket.isPrivate
          appClone.isPaid = ticket.isPaid
          appClone.paymentId = ticket.paymentId
          ticket.procedures.forEach((proc) => {
            appClone.procedures.push(proc.icd9)
          })

          appointments.push(appClone)
        })
      }

      if (this.visitLength < 1) {
        this.visitLength = 5
      }

      for (let index = 0; index < appointments.length; index++) {
        if (index > 0) {
          const app = appointments[index]
          const prev = appointments[index - 1]

          app.start = prev.stop
          app.stop = moment(prev.stop)
            .add(this.visitLength, "minutes")
            .format("YYYY-MM-DDTHH:mm:00")
        }
      }

      this.chainSend(appointments, isReservation)
    },

    chainSend(arr, reservationCompletion) {
      let action = Action.S2_SEND_APPOINTMENT

      if (arr.length > 0) {
        this.requestInProgress = true

        if (this.reservationMode) {
          action = Action.S2_EDIT_APPOINTMENT
          arr[0].state = reservationCompletion ? 100 : 0
        }

        if (arr[0].tmpTicket && arr[0].tmpTicket.id && reservationCompletion) {
          // leave ticket alone!
          this.successSnackbar("Wykorzystano bilet z rezerwacji")

          if (arr[0].tmpTicket.state === 1) {
            if (arr[0].tmpTicket.programId) {
              // TO CHECK, osteoporosis ticket should have extension details?
              this.setTicketReservedProgram(arr[0].tmpTicket)
            } else {
              this.setTicketReservedRis(arr[0].tmpTicket)
            }
          }

          this.$store
            .dispatch(action, arr[0])
            .then((responseApp) => {
              arr.splice(0, 1)
              this.$emit("created", responseApp)

              //ADD IKZ - DODANIE WIZYTY
              this.addIKZVisitAdd()

              this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
              this.successSnackbar("Dodano wizytę")
              this.requestInProgress = false
              this.chainSend(arr, reservationCompletion)
            })
            .catch((error) => {
              this.apiProblem(error)
              this.requestInProgress = false
            })
        }
        else {
          if (arr[0].tmpTicket && !arr[0].tmpTicket?.id && !arr[0].tmpTicket?.programId) {
            this.$store.dispatch(Action.TICKET_CREATE, arr[0].tmpTicket)
              .then((response) => {
                arr[0].ticket = response.id
                this.successSnackbar("Dodano bilet")

                //ADD IKZ - UTWORZENIE BILETU
                this.addIkzTicketCreate()

                this.setTicketReservedRis(response)
                this.$store
                  .dispatch(action, arr[0])
                  .then((responseApp) => {
                    arr.splice(0, 1)
                    this.$emit("created", responseApp)

                    //ADD IKZ - DODANIE WIZYTY
                    this.addIKZVisitAdd()

                    this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                    this.successSnackbar("Dodano wizytę")
                    this.requestInProgress = false
                    this.chainSend(arr, reservationCompletion)
                  })
                  .catch((error) => {
                    this.apiProblem(error)
                    this.requestInProgress = false
                  })
              })
              .catch((error) => {
                this.requestInProgress = false
                this.apiProblem(error)
              })
          }
          else if (arr[0].tmpTicket?.id && arr[0].tmpTicket?.programId) {
            arr[0].ticket = arr[0].tmpTicket.id
            this.$store
              .dispatch(action, arr[0])
              .then((responseApp) => {
                this.setTicketReservedProgram(arr[0].tmpTicket)
                arr.splice(0, 1)
                this.$emit("created", responseApp)

                //ADD IKZ - DODANIE WIZYTY
                this.addIKZVisitAdd()


                this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                this.successSnackbar("Dodano wizytę dla programu populacyjnego")
                this.requestInProgress = false
                this.chainSend(arr, reservationCompletion)
              })
              .catch((error) => {
                this.apiProblem(error)
                this.requestInProgress = false
              })
          }
          else {
            this.$store
              .dispatch(action, arr[0])
              .then((responseApp) => {
                arr.splice(0, 1)
                this.$emit("created", responseApp)

                //ADD IKZ - DODANIE WIZYTY
                this.addIKZVisitAdd()

                this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
                this.successSnackbar("Dodano wizytę")
                this.requestInProgress = false
                this.chainSend(arr, reservationCompletion)
              })
              .catch((error) => {
                this.apiProblem(error)
                this.requestInProgress = false
              })
          }
        }
      }
      else {
        this.$emit("created", null)
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* TICKET STATUS RESERVED METHODS ******************* -->
      <!--------------------------------------------------------->
    */


    setTicketReserved(response) {
      this.$store.dispatch(Action.TICKET_SET_STATUS, { id: this.ticketToAdd.id, status: 2 })
        .then(() => {
          this.ticketToAdd.state = 2
          this.$emit("created", response)
          this.requestInProgress = false

          //ADD IKZ - OZNACZENIE BILETU JAKO WYKORZYSTANEGO
          this.addIkzTicketUsed()
        })
        .catch((error) => {
          this.requestInProgress = false
          this.apiProblem(error)
        })
    },

    setTicketReservedRis(ticket) {
      this.$store.dispatch(Action.TICKET_SET_STATUS, { id: ticket.id, status: 2 })
        .then(() => {
          ticket.state = 2
          // this.successSnackbar("Bilet oznaczony jako wykorzystany")

          //ADD IKZ - OZNACZENIE BILETU JAKO WYKORZYSTANEGO
          this.addIkzTicketUsed()
        })
        .catch((error) => {
          this.apiProblem(error)
        })
    },

    setTicketReservedProgram(ticket) {
      this.$store.dispatch(Action.TICKET_SET_STATUS, { id: ticket.id, status: 2 })
        .then(() => {
          ticket.state = 2
          let payload = ticket.extension

          if (payload) {
            payload.status = 1

            this.$store
              .dispatch(Action.TICKET_EXTENSION_PUT, payload)
              .then(() => {
                this.successSnackbar("Oznaczono bilet jako wykonany")
                this.addIkzTicketUsed()
              })
              .catch((error) => {
                this.apiProblem(error)
              })
          }
        })
        .catch((error) => {
          this.apiProblem(error)
        })
    },

    setTicketReservedCollectionPoint(ticketToAdd) {
      this.$store.dispatch(Action.TICKET_SET_STATUS, { id: ticketToAdd.id, status: 2 })
        .then(() => {
          ticketToAdd.state = 2
          this.addIkzTicketUsed()
        })
        .catch((error) => {
          this.apiProblem(error)
        })
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT TYPE / SUBTYPE SELECT METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    onTypeChanged(val) {
      let appObject = this.filteredAppTypes.find(x => x.id == val)
      this.updateAppConfig(appObject, false)
    },

    updateAppConfig(appObject, isSubtype) {
      if (appObject) {

        this.appointmentObject = appObject

        if (isSubtype) {
          this.appointment.subtype = appObject.id
          this.maxDuration = this.getMaxDuration()
        }
        else {
          this.appointment.type = this.appointmentObject.id
          this.appointment.subtype = -1// this.subcategories && this.subcategories.length > 0 ? this.subcategories[0].id : -1
          this.maxDuration = this.getMaxDuration()
        }

        if (this.rescheduleMode || this.focusReservation) {
          // skip time check
        }
        else {
          if (appObject.time) {
            let tmm = appObject.time
            this.visitLength = tmm
          } else this.visitLength = 5
        }

        if (this.visitLength > this.maxDuration) {
          this.visitLength = this.maxDuration
          this.lengthCalculated = true
        }

        if (appObject.subcategories) {
          this.subcategories = appObject.subcategories.sort(this.sortByOrder)

          if (this.subcategories && this.subcategories.length > 0) {
            this.appointment.subtype = this.subcategories[0].id
            this.switchSubWithType(this.subcategories[0].id)
          }
          else {
            this.reselctNfzService()
          }
        } else {
          if (isSubtype) {
            // its cool, don't clear
          }
          else {
            // clear subcategories
            this.appointment.subtype = -1
            this.subcategories = null
          }

          this.reselctNfzService()
        }


        this.appointment.service = this.appointmentObject.defaultWay
        this.appointment.payerType = this.appointmentObject.defaultPayer

        /**
         * IMPORTANT!!!!
         * AP-KOLCE PROP CHECK FOR SPECIALIST OFFICES WITH CLINIC TYPES SET TO AP-KOLCE REPORT
         * CHECK AFTER VISIT TYPE / SUBTYPE IS CHANGED
         * - IF APP TYPE HAS ONLY ONE PAYER - NFZ PAYER - SET APKOLCE TO TRUE
         * - PREVENTS MISTAKES WITH MULTIPLE PAYER TYPES AND AP-KOLCE PROP CHANGES
         */
        if (this.isSpecialistOffice && this.isClinicApkolce) {
          if (this.appointment.payerType === 1) {
            this.appointmentObject.apkolce = true
          } else {
            this.appointmentObject.apkolce = false
          }
        }

      }
    },

    onSubTypeChanged(val) {
      this.switchSubWithType(val)
      // if (val.time) this.visitLength = val.time
      // else this.visitLength = 5
    },

    switchSubWithType(val) {
      if (this.subcategories && this.subcategories.length > 0) {
        let candidate = this.subcategories.find(x => x.id == val)

        if (candidate) {
          this.updateAppConfig(candidate, true)
        }

        /**
         * IMPORTANT!!!!
         * AP-KOLCE PROP CHECK FOR SPECIALIST OFFICES WITH CLINIC TYPES SET TO AP-KOLCE REPORT
         * CHECK AFTER VISIT SUBTYPE IS CHANGED
         * - IF APP TYPE HAS ONLY ONE PAYER - NFZ PAYER - SET APKOLCE TO TRUE
         * - PREVENTS MISTAKES WITH MULTIPLE PAYER TYPES AND AP-KOLCE PROP CHANGES
         */
        if (this.isSpecialistOffice && this.isClinicApkolce && this.subcategories) {
          this.subcategories.forEach((appObject) => {
            if (appObject.payers.includes(1) && appObject.payers.length === 1) {
              appObject.apkolce = true
            } else {
              appObject.apkolce = false
            }
          })
        }
      }

      this.reselctNfzService()
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      defUnselectable
      * called on component mount
      * sets enabledHours values based on block start
      * unselectable - appears to be OBSOLETE - unused anywhere else
    */

    defUnselectable() {
      this.unselectable.length = 0 // = []
      this.enabledHours.length = 0 // = []

      let tmp = moment(this.block.start).add(-1, 'minutes')

      while (tmp.isBefore(this.maxMomentOption)) {
        tmp.add(1, "minutes")

        if (tmp.minutes() % 5 > 0) {
          this.unselectable.push(tmp.toDate())
        } else {
          this.enabledHours.push(tmp.hours())
        }
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* RESET APPOINTMENT OBJECT METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    reset() {
      return {
        state: 0,
        category: 0,
        subjectId: -1,
        subject: -1,
        spec: -1,
        assistants: [],
        patientId: -1,
        start: this.block.start,
        stop: moment(this.block.start)
          .add(5, "minutes")
          .format("YYYY-MM-DDTHH:mm:00"),
        area: 0,
        plannedProcedures: [],
        type: 0,
        subtype: 0,
        service: 0,
        description: "",
        reason: "",
        isUrgent: false,
        isPrivate: false,
        isTimeless: false,
        destination: 0,
        externalId: 0,
        created: this.mNow(),
        createdBy: this.me ? this.me.id : -1,
        modified: this.mNow(),
        modifiedBy: this.me ? this.me.id : -1,
        procedures: [],
        ticket: null,
        payerType: -1,
        isPaid: false,
        paymentId: null,
        clinic: -1,
        isGroup: false,
        patients: []
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* CLOSE MODAL METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    closeAddAppointment() {
      if (this.ticketHasEReferral && !this.reservationMode) {
        this.successSnackbar('Anulowanie skierowania')
        this.triggerCancelReferral += 1
      } else {
        this.$emit('close')
        if (!this.reservationMode) {
          this.addIkzAddAppointmentCancel()
        }
      }
    },

    closeModal(responseReferral) {
      this.$emit('close')
      this.addIkzReferralCancel()
      this.addIkzAddAppointmentCancel()
      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
      this.$store.commit(Mutation.REGISTRATION_ADD_EREFERRAL, responseReferral)
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */



    /*
      <!--------------------------------------------------------->
      <!--******************* VEHICLE METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    reserveVehicle() {

      let reservationDetails = {
        type: this.vehicleReservation.type,
        destinationClinic: this.vehicleReservation.destinationClinic,
        internalClinic: this.vehicleReservation.internalClinic,
        destinationText: this.vehicleReservation.destinationText,
        peopleNumber: this.vehicleReservation.peopleNumber,
        isAdditionalSpace: this.vehicleReservation.isAdditionalSpace,
        additionalSpace: this.vehicleReservation.additionalSpace,
        notes: this.vehicleReservation.notes
      }

      let start = this.mDate(this.vehicleReservation.departure) + `T${this.vehicleDepartureTime.HH}:${this.vehicleDepartureTime.mm}: 00`
      let stop = this.mDate(this.vehicleReservation.departure) + `T${this.vehicleArrivalTime.HH}:${this.vehicleArrivalTime.mm}: 00`


      this.requestInProgress = true
      this.appointment.start = start
      this.appointment.stop = stop
      this.appointment.area = this.block.worktimeArea.area
      this.appointment.areaId = this.block.worktimeArea.id;
      this.appointment.vehicle = this.block.worktimeArea.vehicle
      this.appointment.employee = this.vehicleReservation.driver
      this.appointment.description = JSON.stringify({ reservationDetails: reservationDetails })

      this.$store
        .dispatch(Action.S2_SEND_APPOINTMENT_VEHICLE, this.appointment)
        .then((response) => {
          this.successSnackbar("Zapisano na serwerze")

          if (this.ticketToAdd) {
            this.setTicketReserved(response)
          }
          else {
            this.$emit("created", response)
            this.requestInProgress = false
          }

          this.vehicleReset()
        })
        .catch((error) => {
          this.apiProblem(error)
          this.requestInProgress = false
        })
    },

    vehicleReset() {
      this.vehicleReservation = {
        area: -1,
        type: -1,
        destinationClinic: -1,
        internalClinic: true,
        destinationText: "",
        departure: new Date(this.block.worktimeArea.start),
        arrival: new Date(this.block.worktimeArea.start),
        peopleNumber: 0,
        driver: -1,
        isAdditionalSpace: false,
        additionalSpace: "",
        notes: ""
      }

      this.facilityFilter = ""

    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* RESCHEDULE METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    reschedule() {
      if (this.block.worktimeArea && this.block.worktimeArea.employee === this.focusAppointment.employee) {
        this.rescheduleProceed()
      } else if (this.filters && this.filters.employee === this.focusAppointment.employee) {
        this.rescheduleProceed()
      } else {
        this.$buefy.dialog.confirm({
          message: `Nowy termin jest u innego pracownika. Kontynuować?`,
          confirmText: "Tak, zmień termin i pracownika",
          cancelText: "Anuluj",
          type: "is-danger",
          hasIcon: true,
          scroll: "keep",
          onConfirm: () => this.rescheduleProceed(),
        })
      }
    },

    rescheduleProceed() {
      this.requestInProgress = true
      let start = moment(this.appStartDate).format("YYYY-MM-DDTHH:mm:00")
      let stop = moment(this.appStartDate)
        .add(this.visitLength, "minutes")
        .format("YYYY-MM-DDTHH:mm:00")

      let obj = JSON.parse(JSON.stringify(this.focusAppointment))

      if (moment(this.appStartDate).isSame(moment(this.focusAppointment.start), 'day')) {
        // Same day, we don't change 'startEwus' prop 
      } else {
        obj.startEwus = null
      }

      // OBSOLETE CHECK FOR TIMELESS ONLY MODE ?
      if (this.timelessOnlyMode) {
        obj.isTimeless = true
      } else {
        // Set visit as timeless, regardless of whether it was set as one before
        obj.isTimeless = false
      }

      obj.start = start
      obj.stop = stop
      obj.service = this.service
      obj.isForReschedule = false

      if (this.block.worktimeArea) {
        obj.employee = this.block.worktimeArea.employee
        obj.area = this.block.worktimeArea.area
        obj.areaId = this.block.worktimeArea.id
        obj.spec = this.block.worktimeArea.spec
        obj.clinic = this.block.worktimeArea.clinic
        obj.officeId = this.block.worktimeArea.officeId
        obj.officeRoomId = this.block.worktimeArea.officeRoomId
        obj.device = this.block.worktimeArea.device
      } else if (this.focusOffice) {
        this.appointment.spec = -1
        this.appointment.clinic = this.focusOffice.clinicId
        this.appointment.area = -1
        this.appointment.officeId = this.filters.office
        this.appointment.officeRoomId = this.filters.room
        this.appointment.device = this.filters.device
      }

      if (obj.state == 8) {
        obj.state = 0
      }


      this.$store
        .dispatch(Action.S2_EDIT_APPOINTMENT, obj)
        .then((response) => {
          if (this.focusAppointment.patient) {
            let obj = {
              version: this.focusAppointment.patient.version,
              stats: {
                patientId: this.focusAppointment.patient.id,
                appointmentsAttended:
                  this.focusAppointment.patient.appointmentsAttended,
                appointmentsMissed:
                  this.focusAppointment.patient.appointmentsMissed,
                appointmentsCancelled:
                  this.focusAppointment.patient.appointmentsCancelled,
                appointmentsLate:
                  this.focusAppointment.patient.appointmentsLate,
                appointmentsRescheduled:
                  this.focusAppointment.patient.appointmentsRescheduled + 1,
              },
            }

            this.$store
              .dispatch(Action.PATIENT_UPDATE_STATS, obj)
              .then(() => {
                this.requestInProgress = false
                this.snackbar("Zapisano")

                //ADD IKZ - PRZEŁOŻENIE WIZYTY
                this.addIKZVisitReschedule()

                this.$store.commit(Mutation.POZ_RESET)
              })
              .catch((error) => {
                this.requestInProgress = false
                this.apiProblem(error)
              })
          }

          this.requestInProgress = false
          this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, null)
          this.$emit("created", response)
        })
        .catch((error) => {
          this.requestInProgress = false
          this.apiProblem(error)
        })
    },

    recalculateExistingAppointmentLength(app) {
      if (app) {
        this.visitLength = moment(app.stop).diff(
          moment(app.start),
          "minutes"
        )
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */


    /*
      <!--------------------------------------------------------->
      <!--******************* CYTOLOGY METHODS ******************* -->
      <!--------------------------------------------------------->
    */
    addCytologyExamination() {
      this.cytologyExaminations.push(
        JSON.parse(JSON.stringify(this.newCytologyExamination))
      )
    },

    removeCytologyExamination(exam) {
      let index = this.cytologyExaminations.indexOf(exam)
      if (index > -1) {
        this.cytologyExaminations.splice(index, 1)
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* PATIENT IDENTIFICATION METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getIdentificationInfo(patientId) {
      this.loadingPatientIdentification = true
      this.$store.dispatch(Action.PATIENT_GET_IDS_INFO, patientId).then((response) => {
        if (response) {
          this.identificationInfo = response.identifications
          this.identificationInfoReversed = response.identifications.reverse()

        }
        this.loadingPatientIdentification = false
      }).catch((error) => {
        this.apiProblem(error)
        this.loadingPatientIdentification = false
      })
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* CONFIG CLINIC OBJECT METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getConfigClinic(id) {
      let clinic = this.configClinics.find(option => option.id === id)
      if (clinic) {
        return clinic
      } else {
        return null
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* TICKET DETAILS HELPER METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getWorker(id) {
      if (this.docs && id) {
        let emp = this.docs.find((e) => e.id === id)

        if (emp) {
          return emp.lastName + " " + emp.firstName
        } else return "Brak informacji"
      } else return "Brak informacji"
    },

    getTicketType(id) {
      let category = this.configApps.find((c) => c.id == id);
      if (category) return category.name;
      else return `Brak informacji`;
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* SPECIALIST CLINIC APPOINTMENTS FOR PATIENT METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getSpecialistAppointmentsPatient(clinic) {
      this.visitTypeSpecialistPatientHistory = []
      this.visitTypeSpecialistPatientTickets = []

      if (this.patient && clinic) {
        let payload = {
          patientId: this.patient.id,
          statuses: [],
          types: [],
          clinics: [clinic]
        }

        this.$store
          .dispatch(Action.S3_GET_APPOINTMENTS_PATIENT, payload)
          .then((response) => {
            let visits = response.data
            visits = visits.filter(appointment => appointment.ticket)
            this.visitTypeSpecialistPatientHistory = visits

            let ticketsToGet = []

            visits.forEach((appo) => {
              if (appo.ticket && !ticketsToGet.includes(appo.ticket)) {
                ticketsToGet.push(appo.ticket)
              }
            })

            this.$store
              .dispatch(Action.STATS_GET_TICKETS_MULTIPLE, ticketsToGet)
              .then((respTickets) => {
                this.visitTypeSpecialistPatientTickets = respTickets
              })
              .catch((error) => {
                this.apiProblem(error)
              })
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* PATIENT POPULATION PROGRAMS METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    patientPopulationProgramsMethod() {
      this.$store
        .dispatch(Action.PATIENT_PROGRAM_GET_FOR_PATIENT, this.patient.id)
        .then((response) => {
          let patientProgram = response.data ?? []
          patientProgram.forEach(async (program) => {
            program.tickets = []
            program.programData = null
            let payload = {
              patientId: this.patient.id,
              programId: program.programId
            }
            program.programData = await this.$store.dispatch(Action.PROGRAMS_POPULATION_GET, program.programId)
            program.tickets = await this.$store.dispatch(Action.STATS_TICKET_GET_BY_PROGRAM_FOR_PATIENT, payload)
          })

          this.patientPopulationPrograms = patientProgram
        })
        .catch((error) => {
          this.apiProblem(error)
        })
    },

    patientPopulationAddTicket() {
      if (this.isRISOffice) {
        this.risTickets = [this.patientPopulationAppointmentTicket]
      } else {
        this.ticketToAdd = this.patientPopulationAppointmentTicket
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* IKZ METHODS ******************* -->
      <!--------------------------------------------------------->
    */
    addIKZVisitAdd() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 524)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIKZVisitReschedule() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 526)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralCancel() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 599)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzAddAppointmentCancel() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 616)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzTicketCreate() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 594)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzTicketUsed() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 593)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralAdd() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 608)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralEdit() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 609)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ")
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },
    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    resetEwus() {
      if (this.focusReservation && !this.checkFocusReservationPatient) {
        this.resetEwusReservation()
        return
      }

      this.$store.commit(Mutation.EWUS_SET_EWUS, null)
      this.$store.commit(Mutation.EWUS_SET_EWUS_GUID, null);
      this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING, true);

      let pay = {
        pesel: this.patient.pesel,
        workerId: this.me.id + "",
        domainId: this.me.domainId + "",
      };

      this.$store
        .dispatch(Action.EWUS_CHECK_PESEL, pay)
        .then((response) => {
          this.$store.commit(Mutation.EWUS_SET_EWUS_GUID, response);
        })
        .catch((error) => {
          this.apiProblem(error);
        })
    },

    resetEwusReservation() {
      this.$store.commit(Mutation.EWUS_SET_EWUS_RESERVATION, null)
      this.$store.commit(Mutation.EWUS_SET_EWUS_GUID_RESERVATION, null);
      this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING_RESERVATION, true);

      let pay = {
        pesel: this.patient.pesel,
        workerId: this.me.id + "",
        domainId: this.me.domainId + "",
      };

      this.$store
        .dispatch(Action.EWUS_CHECK_PESEL, pay)
        .then((response) => {
          this.$store.commit(Mutation.EWUS_SET_EWUS_GUID_RESERVATION, response)
        })
        .catch((error) => {
          this.apiProblem(error);
        })
    },

    getPayerTypeName(payerType) {
      if (payerType == 0) {
        return "Komercja"
      } else if (payerType == 1) {
        return "NFZ"
      } else if (payerType == 2) {
        return "MZ"
      }

      return "Brak informacji"
    },

    getPayerTypeIcon(payerType) {
      if (payerType == 0) {
        return "account-cash"
      } else if (payerType == 1) {
        return "heart-half-full"
      } else if (payerType == 2) {
        return "clipboard-pulse"
      }

      return ""
    },

    getPatientTicketPass() {
      this.$store
        .dispatch(Action.TICKET_PASS_GET_FULL_BY_PATIENT, this.patient.id)
        .then((response) => {
          let dateNow = this.mDate(this.mNow())
          let responseFiltered = response.filter(ticketPass => ticketPass.type === 1)
          let responseTicketPassNurse = response.filter(ticketPass => ticketPass.type === 2)

          responseFiltered = responseFiltered.filter(ticketPass => {
            let startDate = this.mDate(ticketPass.startDate)
            let endDate = this.mDate(ticketPass.endDate)
            let dateCorrect = true

            if (dateNow >= startDate && dateNow <= endDate) {
              dateCorrect = true
            } else {
              dateCorrect = false
            }

            let findTicketCancelled = ticketPass.tickets.find(ticket => ticket.state === 5)

            if (findTicketCancelled || dateCorrect === false) {
              return false
            }

            return true
          })

          responseTicketPassNurse = responseTicketPassNurse.filter(ticketPass => {
            let startDate = this.mDate(ticketPass.startDate)
            let endDate = this.mDate(ticketPass.endDate)
            let dateCorrect = true

            if (dateNow >= startDate && dateNow <= endDate) {
              dateCorrect = true
            } else {
              dateCorrect = false
            }

            let findTicketCancelled = ticketPass.tickets.find(ticket => ticket.state === 5)

            if (findTicketCancelled || dateCorrect === false) {
              return false
            }

            return true
          })

          responseTicketPassNurse.forEach(ticketPass => {
            if (ticketPass.tickets) {
              ticketPass.tickets.forEach(ticket => {
                ticket.orderType = this.getOrderTypeId(ticket.ticketType, ticket.ticketSubType)
                ticket.orderTypeName = this.getOrderType(ticket.orderType)

                if (ticket.orderType === 0) {
                  ticket.treatmentType = this.getTreatmentTypeId(ticket.ticketSubType)
                  ticket.treatmentTypeName = this.getTreatmentType(ticket.treatmentType)
                } else {
                  ticket.treatmentType = -1
                  ticket.treatmentTypeName = "-"
                }
              })
            }
          })

          this.ticketsPassCollectionPoint = responseFiltered
          this.ticketsPassNurse = responseTicketPassNurse
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    getRowClassCollectionPoint(row) {
      if (this.ticketsCollectionPointSelected.includes(row)) {
        return "has-background-analyzed"
      } else return ""
    },

    getRowClassCollectionPointPass(row) {
      if (this.ticketsPassCollectionPointSelected.includes(row)) {
        return "has-background-analyzed"
      } else return ""
    },

    getRowClassNurseTicket(row) {
      if (this.ticketToAdd === row) {
        return "has-background-analyzed"
      } else return ""
    },

    getRowClassTicketPassNurse(row) {
      if (this.ticketsPassNurseSelected === row) {
        return "has-background-analyzed"
      } else return ""
    },

    selectCollectionPointTicket(ticket) {
      this.ticketsCollectionPointSelected.push(ticket)
    },

    deleteCollectionPointTicket(ticket) {
      let indexTicket = this.ticketsCollectionPointSelected.indexOf(ticket)

      if (indexTicket > -1) {
        this.ticketsCollectionPointSelected.splice(indexTicket, 1)
      }
    },

    selectCollectionPointTicketPass(ticketPass) {
      this.ticketsPassCollectionPointSelected.push(ticketPass)
    },

    deleteCollectionPointTicketPass(ticketPass) {
      let indexTicketPass = this.ticketsPassCollectionPointSelected.indexOf(ticketPass)

      if (indexTicketPass > -1) {
        this.ticketsPassCollectionPointSelected.splice(indexTicketPass, 1)
      }
    },

    checkCollectionPointTicketPayer() {
      if (this.ticketToAdd && this.ticketToAdd.payerType !== this.appointment.payerType) {
        if (!this.reservationMode) {
          this.ticketToAdd = null
        }
        this.snackbar("Płatnik wybranego biletu różni się od płatnika wizyty")
      }
    },

    createNewTicketFromTicketPass(ticketPass) {
      let ticket = JSON.parse(JSON.stringify(this.ticketObject))

      ticket.workerId = ticketPass.workerId
      ticket.appointmentId = ticketPass.appointmentId
      ticket.createdBy = this.me.id
      ticket.domainId = this.me.domainId
      ticket.patientId = ticketPass.patientId

      ticket.releaseDate = moment()
      ticket.startDate = moment()
      ticket.created = moment()
      ticket.expiryDate = moment().add(2, "year")

      ticket.payerType = ticketPass.payerType
      ticket.isPaid = ticketPass.isPaid

      ticket.ticketType = ticketPass.ticketType
      ticket.ticketSubType = ticketPass.ticketSubType
      ticket.ticketOffice = ticketPass.ticketOffice
      ticket.minMinutes = 5
      ticket.isTimeless = false

      ticket.procedures = ticketPass.procedures
      ticket.referral = ticketPass.referral
      ticket.facilityParams = ticketPass.facilityParams

      ticket.vaccinations = ticketPass.vaccinations
      ticket.educations = ticketPass.educations
      ticket.medicalDevices = ticketPass.medicalDevices
      ticket.transport = ticketPass.transport
      ticket.diagTests = ticketPass.diagTests
      ticket.adds = ticketPass.adds
      ticket.measurements = ticketPass.measurements
      ticket.ekg = ticketPass.ekg

      ticket.description = ticketPass.description ?? ""

      return ticket
    },

    async createTicket(ticket) {
      let response = null
      await this.$store
        .dispatch(Action.TICKET_CREATE, ticket)
        .then((responseTicket) => {
          response = responseTicket
        })
        .catch((error) => {
          this.apiProblem(error)
        })

      return response
    },

    selectNurseTicket(ticket) {
      this.appointmentObject = this.configApps.find(x => x.id == ticket.ticketType)
      this.appointmentObjectTmp = this.appointmentObject.id
      this.appointment.type = this.appointmentObject.id
      if (this.appointmentObject.subcategories) {
        this.subcategories = this.appointmentObject.subcategories.sort(this.sortByOrder)
        if (ticket.ticketSubType > -1) {
          this.appointment.subtype = ticket.ticketSubType
          let subcategoryObject = this.subcategories.find(x => x.id === ticket.ticketSubType)
          if (subcategoryObject) {
            this.appointmentObject = subcategoryObject
          }
        }
      }

      this.ticketToAdd = ticket
    },

    selectNurseTicketPass(ticketPass) {
      if (ticketPass) {
        if (ticketPass.tickets && ticketPass.tickets.length > 0) {
          let ticket = ticketPass.tickets[0]
          this.appointmentObject = this.configApps.find(x => x.id == ticket.ticketType)
          this.appointmentObjectTmp = this.appointmentObject.id
          this.appointment.type = this.appointmentObject.id
          if (this.appointmentObject.subcategories) {
            this.subcategories = this.appointmentObject.subcategories.sort(this.sortByOrder)
            if (ticket.ticketSubType > -1) {
              this.appointment.subtype = ticket.ticketSubType
              let subcategoryObject = this.subcategories.find(x => x.id === ticket.ticketSubType)
              if (subcategoryObject) {
                this.appointmentObject = subcategoryObject
              }
            }
          }
        }
      }

      this.ticketsPassNurseSelected = ticketPass
    }
  },
}
</script>

<style scoped lang="scss">
.vam {
  vertical-align: middle;
}
</style>
